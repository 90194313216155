import { ArrowDownward } from '@mui/icons-material';
import React from 'react';
import styled from 'styled-components';

const DropdownSelect = ({ options, setValueFunction, selectedValue, style, icon }) => {

    const [value, setValue] = React.useState('');
    const [showOptions, setShowOptions] = React.useState(false);

    React.useLayoutEffect(() => {
        selectedValue ? setValue(selectedValue) : setValue(options.object[0][options.key]);
    }, [selectedValue, options]);

    React.useEffect(() => {
        setValueFunction(value);
    }, [value]);

    const rendering = () => {
        return (
            <DropdownSelectSC
                style={style}
            >
                <div 
                    className='input-field'
                    onClick={() => setShowOptions(!showOptions)}
                >
                    <div className='icon-and-value'>
                        {icon}
                        <p className='option-text'>{value}</p>
                    </div>
                    <ArrowDownward />
                </div>

                {showOptions &&
                    <div className='options'>
                        {options.object.map((option, index) => {
                            return (
                                <p 
                                    key={index}
                                    onClick={() => {
                                        setValue(option[options.key]);
                                        setShowOptions(false);
                                        setValueFunction(option[options.key]);
                                    }}
                                >
                                    {option[options.key]}
                                </p>
                            );
                        })}
                    </div>
                }
            </DropdownSelectSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DropdownSelectSC = styled.div`
    .input-field {
        display : flex; 
        align-items : center;
        padding : 10px;
        padding-left : 15px;
        border : 1px solid #ccc;
        border-radius : 5px;
        position : relative; 
        min-height : 35px;

        &:hover {
            cursor : pointer;
            background-color : #fcfcfc;
        }

        .icon-and-value {
            display : flex;
            align-items : center;
            width : 100%;

            >svg {
                color : grey;
                padding-right : 10px;
            }
        }

        &>svg {
            color : grey;
        }
    }


    .options {
        display: flex;
        flex-direction: column;
        position: absolute;
        z-index: 1;
        background-color: #fff;
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 5px;

        p {
            padding: 15px;
            cursor: pointer;

        }

        p:hover {
            background-color: #f0f0f0;
        }
    }
`

export default DropdownSelect