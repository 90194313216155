import React from 'react';
import styled from 'styled-components';
import Axios from 'axios' ;
import { backEndAdress } from '../../../../variables/back-endAdress';
import SendIcon from '@mui/icons-material/Send';
import { IndividualSessionContext } from '../../IndividualSession';

const MailJobs = ({ individualSession }) => {

    const [mailJobs, setMailJobs] = React.useState([]);
    const { setJobPreview } = React.useContext(IndividualSessionContext);

    React.useEffect(() => {
        const fetchMailJobs = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-jobs/for-instance/${individualSession._id}`)
                setMailJobs(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        fetchMailJobs();
    }, []);

    const rendering = () => {
        return (
            <MailJobsSC>
                <p className='title'>Scheduled mails</p>
                {mailJobs.map((mailJob) => {
                    return (
                        <div className='mail-job' onClick={() => setJobPreview(mailJob)}>
                            <div style={{display : 'flex', alignItems : 'center'}}>
                                <SendIcon />
                                <div className='mail-job-info'>
                                    <h1 className='mail-job-title'>{mailJob.subject}</h1>
                                    <h4 className='sendto'>{mailJob.sendTo}</h4>
                                </div>
                            </div>
                            <div className='status'>
                                <p>sent</p>
                            </div>

                        </div>
                    )
                })}
            </MailJobsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailJobsSC = styled.div`
    background-color : white;
    margin-top : 20px;
    padding : 15px;
    border-radius : 5px;
    min-height : 400px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);

    .title {
        margin-bottom : 10px;
    }

    .mail-job {
        display : flex;
        align-items : center;
        padding : 20px;
        border-bottom : 1px solid lightgrey;

        &:hover {
            background-color : #F5F5F5;
            cursor : pointer;
        }

        svg {
            font-size : 18px;
            margin-right : 15px; 
            color : darkgrey;
        }

        .mail-job-info {
            display : flex;     
            flex-direction : column;
            justify-content : center;
            max-width : 70%;
            
            h1 {
                display: -webkit-box;        /* Use a flexible box layout */
                -webkit-box-orient: vertical; /* Make the box behave vertically */
                -webkit-line-clamp: 1;        /* Limit to 2 lines */
                overflow: hidden;            /* Hide overflowed text */
                text-overflow: ellipsis;     /* Add ellipsis at the end */
            }

            h4 {
                display: -webkit-box;        /* Use a flexible box layout */
                overflow: hidden;            /* Hide overflowed text */
                text-overflow: ellipsis;     /* Add ellipsis at the end */
            }

        }

        .status {
            margin-left : auto;
            p {
                font-size : 14px;
                color : #A97C19;
                background-color : #FEFFA7;
                font-weight : 200;
                padding : 5px;
                padding-left : 15px;
                padding-right : 15px;
                border-radius : 5px;
            }
        }
    }



`

export default MailJobs