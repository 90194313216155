import React from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Modal from '../GeneralComponents/Modal';
import InstancesList from '../TrainingsOverview/InstancesList';
import { IndividualTrainingContext } from './IndividualTraining';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import { Button, IconButton, TextField } from '@mui/material';
import { colors } from '../../variables/Colors';
import WorkshopsList from '../GeneralComponents/instancesList/WorkshopsList';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

const InstancePicker = ({ trainingIdFromURL }) => {

    const navigate = useNavigate(); 

    const [pickerOpened, setPickerOpened] = React.useState(false);
    const { state, training } = React.useContext(IndividualTrainingContext);

    const selectInstancefunction = (data) => {
        navigate(`/training/${data._id}/${trainingIdFromURL}`);
        setPickerOpened(false);
    }

    const rendering = () => {
        return (
            <InstancePickerSC style={{ border : state.editMode && '1px solid grey' }}  >
                <div className='current-instance' onClick={() => setPickerOpened(true)}>
                    <p className='instance-name'>{moment(state.currentInstance.startDate).format('DD/MM/YYYY')}</p>
                    <KeyboardArrowDownIcon />
                </div>

                {pickerOpened &&
                    <Modal>
                        <div className='container-modal'>
                            <WorkshopsList
                                preOpenTraing={training}
                                onClickFunction={selectInstancefunction}
                            />
                            <IconButton 
                                onClick={() => setPickerOpened(false)}
                                style={{ position : 'absolute', right : '10px', top : '10px' }}   
                            > 
                                <CloseIcon style={{ color : state.editMode && colors.primary }}/>
                            </IconButton>    
                        </div>

                    </Modal>
                }        

            </InstancePickerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstancePickerSC = styled.div`
    border-radius : 5px;
    background-color : white;
    display : flex;
    justify-content : space-between;
    height : 42px;
    align-items : center;
    padding : 10px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);

    .button-row {
        display : flex;
        align-items : center;
        justify-content : space-between;
        width : 100%;

    }

    .current-instance {
        display : flex;
        align-items : center;
        justify-content : space-between;
        flex-grow : 1;

        &:hover {
            cursor : pointer;
        }
        .instance-name {
            padding-left : 10px;
            font-size : 18px;
        }
    }

    .container {
        width : 80%;
        height : 80%;
        border-radius : 5px;
        display : flex;
        flex-direction : column;
        padding : 15px;
        box-sizing : border-box;
        background-color : white;
        z-index : 100;
    }

    .button-row {
        display : flex;
        justify-content : flex-end;
    }

    .title-edit-input {
        min-height : 100%;
        border : none;
        font-size : 16px;
        outline : none;
        font-size : inherit;
        font-family : inherit;
        padding : 5px;
        box-sizing : border-box;
        flex-grow : 1;
        padding-left : 10px;
        background-color : #f5f5f5;

    }

    .container-modal {
        background-color : white;
        width : 80%;
        height : 80%;
        border-radius : 5px;
        display : flex; 
        flex-direction : column;
        box-sizing : border-box;
        position : relative;
    }
`

export default InstancePicker