import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';
import MenuIcon from './MenuIcon';
import HeaderBar from './HeaderBar';
import CreateNewButton from './CreateNewButton';
import { HeigtsPaddingBorderRadius } from '../../variables/HeigtsPaddingBorderRadius'
import { useNavigate } from 'react-router-dom';
import Profile from './Profile';
import Options from './Options';

const { headerHeight } = HeigtsPaddingBorderRadius;

const Header = ({ appState, setAppState}) => {

    const rendering = () => {
        return (
            <HeaderSC>
                <img src='/holli-logo.png' alt='logo' className='holli-logo'/>
                <div className='end'>
                    <Options />
                    <Profile />
                </div>
            </HeaderSC>
            
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    height : 45px;
    display : flex;
    box-sizing : border-box;
    background-color : ${colors.white};
    justify-content : space-between;
    align-items : center;

    .holli-logo {
        height : 25px;
        margin-left : 20px;
    }

    .end {
        display : flex;

    }
`

export default Header