import React from 'react';
import styled from 'styled-components';
import BasicInput from '../GeneralComponents/BasicInput';
import BasicTextArea from '../GeneralComponents/BasicTextArea';
import AddImage from './AddImage';

const TrainingInfo = ({ setFile, setImageURL, file, imageURL, trainingNameRef, trainingDescriptionRef, visible}) => {

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    const rendering = () => {

        return (
            <TrainingInfoSC style={{ display : visible === 'visible' ? 'flex' : 'none'}}>
                <div className='input-parameters'>
                    <BasicInput
                        label='training name'
                        defaultValue=''
                        reference={trainingNameRef}
                        style={{marginBottom : '25px'}}
                    />

                    <BasicTextArea
                        label='training description'
                        defaultValue=''
                        reference={trainingDescriptionRef}
                    />
                </div>

                <AddImage
                    file={file}
                    setFile={setFile}
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    handleImageUpload={handleImageUpload}
                />

                {/* <div className='image-input'>
                    <div className='upload' style={{ display : imageURL && 'hidden' }}>
                        <label for='image' >
                            <input type='file' onChange={handleImageUpload} />
                        </label>
                    </div>
                    <div className='preview'>
                        {imageURL && <img src={imageURL} alt='Uploaded' />}
                    </div>
                </div> */}
            </TrainingInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingInfoSC = styled.div`
    display : flex;
    flex-grow : 1;
    padding : 25px;
    box-sizing : border-box;

    .input-parameters {
        min-width : 40%;
        display : flex;
        flex-direction : column;
    }

    .image-input {
        flex-grow : 1;
        display : flex;
        padding : 15px;
        box-sizing : border-box;
        flex-direction : column;


        .preview {
            display : flex;
            flex-direction : column;
            width : 300px;
            max-width : 300px;
            aspect-ratio : 16/9;
            padding : 15px;
            box-sizing : border-box;

            img {
                width : 100%;
                height : 100%;
                object-fit : cover;
            }
        }
    }
`

export default TrainingInfo