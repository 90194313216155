import React from 'react';
import styled from 'styled-components';
import JobPreviewHeader from './JobPreviewHeader';
import Modal from '../../GeneralComponents/Modal';
import Preview from '../../emailSchedule/Content/Preview';

const JobPreviewComponent = ({ jobPreview, setJobPreview}) => {

    console.log('jobPreview.handleBarsVariable', jobPreview)

    const rendering = () => {
        return (
            <Modal>
                <JobPreviewComponentSC>
                    <JobPreviewHeader jobPreview={jobPreview} setJobPreview={setJobPreview}/>
                    <Preview
                        mailTemplate={jobPreview.touchPoint.mailTemplate}
                        handleBarsObject={jobPreview.handlebarsVariables}
                    />
                </JobPreviewComponentSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const JobPreviewComponentSC = styled.div`
    background-color : white;
    max-height : 95vh;
    min-height : 95vh;
    overflow-y : auto;
    max-width : 50vw;
    min-width : 600px;
    border-radius : 10px;
    box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.1);
    position : relative;
    display : flex;
    flex-direction : column;

    &>div {
        flex-grow : 1;
        display : flex; 
        flex-direction : column; 
    
        >.loading-template {
            min-height : 100%;
        }

        >.mail-preview {
            padding-bottom : 35px;
            background-color : #fafafa;
            
            &>.subject-of-template {
                visibility : hidden;
            }


            
        }
    }

`

export default JobPreviewComponent