import React from 'react';
import styled from 'styled-components';
import Search from '@mui/icons-material/Search';

const SearchBar = ({ setSearchQuery }) => {

    const rendering = () => {
        return (
            <SearchBarSC>
                <Search />
                <input type="text" placeholder="Search..." onChange={(e) => setSearchQuery(e.target.value)}/>
            </SearchBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SearchBarSC = styled.div`
    border : 1px solid lightgrey;
    border-radius : 5px;
    padding : 10px;
    display : flex;
    align-items : center;
    margin-bottom : 10px;
    margin : 15px;
    box-sizing : border-box;

    input {
        border : none;
        outline : none;
        margin-left : 5px;
        font-family : inherit;
        font-size : inherit;
        font-weight : 300;
    }

    svg {
        color : grey;
    }

`

export default SearchBar