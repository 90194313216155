import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import { TextField } from '@mui/material';
import { set } from 'react-hook-form';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import { AppContext } from '../../App';
import SidebarFolders from './SidebarFolder';
import TemplatesInFolder from './TemplatesInFolder';
import SaveBar from './SaveBar';
import AddFolderModal from './AddFolderModal';

const SaveTemplateModal = ({ saveDesignFunction, titleRef, state, setState, selectedFolder, setSelectedFolder, titleTemplateRef}) => {

    const [templates, setTemplates] = React.useState([]);
    const [folders, setFolders] = React.useState([]);
    const [addFolderToggled, setAddFolderToggled] = React.useState(false);

    const { appState } = React.useContext(AppContext);

    //Fetching the templates
    React.useEffect(() => {
        const getTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-templates/find-all/${appState.company}`);
                setTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }
        
        getTemplates();
    }, []);

    //Creating the unique folders 
    React.useEffect(() => {
        if(templates.length > 0) {
            const templatesWithFolder = templates.filter((template) => template.folder);
            const generalTemplates = templates.filter((template) => !template.folder).map((template) => ({...template, folder : 'general'})); 
            const allTemplates = [...templatesWithFolder, ...generalTemplates];
            const uniqueFolders = [...new Set(allTemplates.map(template => template.folder))];
            setFolders(uniqueFolders);

        }}
    , [templates]);

    //Select a design
    const updateTitle = (e) => {
        setState({
            ...state,
            currentDesignName : e.target.value
        });
    }
    
    // Click outside logic
    const divRef = React.useRef(null);
    const handleClickOutside = (event) => {
        if (divRef.current && !divRef.current.contains(event.target)) {
        setState({...state, saveDesignPopup : false});
        }
    };
    
    React.useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    // End click outside logic

    //Add a folder function
    const addFolder = (folder) => {
        setFolders([...folders, folder]);
        setAddFolderToggled(false);
    }

    const rendering = () => {
        return (
            <Modal ref={divRef}>
                <SaveTemplateModalSC ref={divRef}>

                    <>
                        {addFolderToggled && <AddFolderModal addFolder={addFolder} />}
                    </>

                    <SidebarFolders 
                        templates={templates} 
                        selectedFolder={selectedFolder} 
                        setSelectedFolder={setSelectedFolder}
                        folders={folders}
                        setAddFolderToggled={setAddFolderToggled}
                    />

                    <div className='column-two'>
                        <TemplatesInFolder 
                            templates={templates}
                            selectedFolder={selectedFolder}    
                        />
                        <SaveBar 
                            updateTitle={updateTitle} 
                            saveDesignFunction={saveDesignFunction} 
                            titleRef={titleRef} 
                            titleTemplateRef={titleTemplateRef}
                            state={state}
                        />
                    </div>
                </SaveTemplateModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SaveTemplateModalSC = styled.div`
    min-width : 70vw;
    min-height : 70vh;
    background-color : white;   
    display : flex;
    border-radius : 5px;
    overflow : hidden;

    .column-two {
        flex-grow : 1;
        display : flex;
        flex-direction : column;
        justify-content : space-between;
    }
`

export default SaveTemplateModal