import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import { Button } from '@mui/material';
import { variablesTemp } from './variablesTemp';
import InstanceSelector from './instanceSelector';

const VariablesPopUp = ({ state , setState}) => {

    const rendering = () => {
        return (
            <Modal>
                <VariablesPopUpSC>
                    <div className='top-bar-header'>
                        <InstanceSelector />

                        <Button
                            variant='contained'
                            color='primary'
                            size={'small'}
                            style={{paddingLeft : '20px', paddingRight : '20px'}}
                            onClick={() => setState({...state, variablesPopUp : false})}
                        >
                            Close
                        </Button>    
                    </div>        
                    <div className='variables-list'>
                        {variablesTemp.map((variableItem) => {
                            return (
                                <div>
                                    {variableItem.name}
                                </div>
                            )
                        })}    
                    </div>        
                </VariablesPopUpSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const VariablesPopUpSC = styled.div`
    min-width : 600px;
    min-height : 400px;
    background-color : white;
    display : flex;
    flex-direction : column;
    padding : 10px;
`

export default VariablesPopUp