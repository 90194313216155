import React from 'react';
import styled from 'styled-components';

const TrainingCertificates = ({ state , setState, visible }) => {

    const rendering = () => {
        return (
            <TrainingCertificatesSC style={{ display : `${visible ? 'flex' : 'none'}`}}>
                <p className='title-select-certificate'>Select a certificate to link to this training</p>
                <div className='certificate-templates-grid'>

                    {state.certificateTemplates.map((certificateTemplate, index) => {

                        const isSelected = state.selectedCertificateTemplate && state.selectedCertificateTemplate._id === certificateTemplate._id;

                        return (
                            <div 
                                key={certificateTemplate._id} 
                                className={`template-item ${isSelected && 'selected'}`}
                                onClick={() => setState({...state, selectedCertificateTemplate : certificateTemplate})}    
                            >
                                <div className='background-image' style={{backgroundImage : `url(${certificateTemplate.imageUrl})`}}></div>
                                <div className='template-info'>
                                    <p className='template-name'>{certificateTemplate.certificateName}</p>
                                    <p className='template-text'>{certificateTemplate.certificateText}</p>
                                </div>
                            </div>
                        );
                    })}

                </div>
            </TrainingCertificatesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingCertificatesSC = styled.div`
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    max-height : 100%;
    padding : 10px;
    box-sizing : border-box;
    background-color : #fafafa;

    .title-select-certificate {
        font-size : 1rem;
        padding : 10px;
    }

    .certificate-templates-grid {
        display : grid;
        grid-template-columns : repeat(auto-fill, minmax(170px, 1fr));
        grid-gap : 10px;
        padding : 10px;
        padding-left : 0;
        flex-grow : 1;
        overflow-y : scroll;
        overflow-x : hidden;
        height : 100px;

        .template-item {
            display : flex;
            flex-direction : column;
            align-items : center;
            padding : 10px;
            border-radius : 10px;
            background-color : white;
            min-height : 280px;
            max-height : 280px;

            &:hover {
                cursor : pointer;
                box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.1);
            }

            .background-image {
                min-width : 100%;
                aspect-ratio : 1/1;
                background-size : cover;
                background-position : center;
                border-radius : 10px;
            }

            .template-info {
                display : flex;
                flex-direction : column;
                padding : 25px;
                width : 100%;

                .template-name {
                    font-size : 1.2em;
                    font-weight : 600;
                    padding-bottom : 0;

                }

                .template-text {
                    font-size : 0.8em;
                }
            }
        }   

        .selected {
            border : 3px solid green;
        }
    }
`

export default TrainingCertificates