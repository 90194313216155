import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import AddImage from './AddImage';

const BasicInfo = ({ register, errors, trigger, imageURL, setImageURL, file, setFile, visible }) => {

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    const rendering = () => {

        return (
            <BasicInfoSC style={{ display : `${visible ? 'flex' : 'none'}`}}>
                <div className='form-group'>
                    <TextField  
                        label='Title'
                        type='text' 
                        {...register('title', {
                            required : {
                                value : true,
                                message : 'title is requried'
                            },
                            minLength : {
                                value : 5,
                                message : 'This field must be at least 5 characters'
                            }
                        })}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        sx={{
                            marginBottom: '20px'
                          }}
                    />
                    <div className='filler'>
                    <TextField  
                        label='Description'
                        multiline
                        type='text' 
                        {...register('description', {
                            required : {
                                value : true,
                                message : 'description is requried'
                            },
                            minLength : {
                                value : 5,
                                message : 'This field must be at least 5 characters'
                            }
                        })}
                        sx={{
                            width: '100%',
                            height: '100%', // Ensure the text field takes the full height of the parent div
                            '& .MuiInputBase-root': {
                              height: '100%', // Ensures input area fills the div,
                              alignItems: 'flex-start',
                            },
                          }}
                        error={!!errors.description}
                        helperText={errors.description?.message}
                    />
                    </div>
                </div>                   
                <AddImage
                    file={file}
                    setFile={setFile}
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    handleImageUpload={handleImageUpload}
                />
            </BasicInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BasicInfoSC = styled.div`
    flex : 1;
    display : flex;


    .form-group {
        padding : 20px;
        flex : 1;
        display : flex;
        flex-direction : column;
        max-width : 50%;

        .filler {
            flex : 1;
        }
    }
`

export default BasicInfo