import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../../IndividualTraining';
import Axios from 'axios';
import { backEndAdress } from '../../../../variables/back-endAdress';
import { useNavigate } from 'react-router-dom';

const InstanceSurvey = () => {

    const { training } = React.useContext(IndividualTrainingContext);
    const [survey, setSurvey] = React.useState(null);

    const navigate = useNavigate(); 

    React.useEffect(() => {
        const getSurvey =  async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/surveys/${training.surveyId}`);
                setSurvey(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        training && getSurvey();

    }, [training]);


    const rendering = () => {
        const surveyLink = training && `/fill-survey/${training.surveyId}/66a9537a50b54157819379f6/no-attendee`;

        return survey && (
            <InstanceSurveySC onClick={() => navigate(`${surveyLink}`)}>
                <div 
                    className='avatar' 
                    style={{backgroundImage : `url(https://www.eventbrite.co.uk/blog/wp-content/uploads/2022/06/Post-Event-Survey-scaled.jpg)`}}>
                </div>
                <div className='location-information'>
                    <p className='title'>Survey</p>
                </div>
            </InstanceSurveySC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstanceSurveySC = styled.div`
    width : 100%;
    padding : 15px;
    box-sizing : border-box;
    background-color : white;
    border-radius : 5px;
    margin-top : 10px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    display : flex;

    &:hover {
        box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.3);
        cursor : pointer;
    }

    .avatar {
        min-height : 100px; 
        max-height : 100px;
        min-width : 100px;
        max-width : 100px;
        background-size : cover;
        background-repeat : no-repeat;
        background-position : center;
        border-radius : 5px;
    }

    .location-information {
        display : flex; 
        flex-direction : column;
        padding : 15px;
        padding-top : 0;
        padding-left : 20px;
    }

    .title {
        font-size : 1.2em;
    }

    .location-adress {
        color : grey;
    }
`

export default InstanceSurvey