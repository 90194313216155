import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';

const DeleteTemplatePopUp = ({  
    handleClose,
    deleteTemplateFunction
}) => {

    const [deleting, setDeleting] = React.useState(false);

    const handleDelete = async () => {
        setDeleting(true);
        await deleteTemplateFunction();
        setDeleting(false);
    }


    const rendering = () => {
        return (
                <Modal>
                    <DeleteTemplatePopUpSC>
                        {
                            deleting ?
                            
                            <div className='deleting-progress'>
                                <CircularProgress />
                            </div>                            
                            
                            :
                        
                            <>
                            <p>Are you sure you want to permanently delete the template?</p>
                            <div className='buttons-from-delete'>
                                <Button
                                    variant='outlined'
                                    onClick={handleDelete}
                                >
                                    Yes
                                </Button>
                                <Button
                                    variant='outlined'
                                    onClick={handleClose}
                                >
                                    No
                                </Button>
                            </div>
                        </>
                        }

                </DeleteTemplatePopUpSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DeleteTemplatePopUpSC = styled.div`
    display : flex;
    flex-direction : column; 
    z-index : 500;
    background-color : white; 
    position : absolute;
    padding : 25px;
    border-radius : 10px;
    box-shadow : 0px 0px 10px 0px grey;
    min-width : 500px; 
    min-height : 80px;

    .buttons-from-delete {
        display : flex;
        margin-top : 20px;
        justify-content : space-around;

        button {
            min-width : 40%; 
        }
    }

    .deleting-progress {
        display : flex;
        justify-content : center;
        align-items : center;
        flex-direction : column;
        height : 80px;
}
`

export default DeleteTemplatePopUp