import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../../IndividualTraining';
import EditIcon from '@mui/icons-material/Edit';
import ChangeInformationConfirmation from '../ChangeInstanceConfirmation';
import ChangeLocationPopUp from './ChangeLocationPopUp';
import Axios from 'axios';
import { backEndAdress } from '../../../../variables/back-endAdress';

const InstanceLocation = () => {

    const [confirmationModal, toggleConfirmationModal] = React.useState(false);
    const [locationList, toggleLocationList] = React.useState(false);
    
    const { trainingLocation, state, setState } = React.useContext(IndividualTrainingContext);

    const updateLocation = (selectedLocation) => {
            
        const updateInstance = async () => {

            const newInstance =  {
                ...state.currentInstance,
                location : selectedLocation
            }

            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/update-instance/${state.currentInstance._id}`, newInstance);
                toggleLocationList(false);
                setState({
                    ...state,
                    currentInstance : response.data
                });

            } catch (error) {
                console.log(error);
            }
        }

        updateInstance();

    }

    const rendering = () => {
        return trainingLocation &&
             (
                <InstanceLocationSC>
                    <>
                        {confirmationModal && <ChangeInformationConfirmation 
                            property={'location'}
                            changeFunction={() => {
                                toggleLocationList(true)
                                toggleConfirmationModal(false)
                            }}    
                            closeFunction={() => toggleConfirmationModal(false)}
                        />}

                        {locationList && <ChangeLocationPopUp 
                            closeFunction={() => toggleLocationList(false)}    
                            clickFunction={updateLocation}
                        />}
                    </>
                    <div className='avatar' style={{ backgroundImage : `url(${trainingLocation.thumbnailImageUrl})`}}>
                    </div>
                    <div className='location-information'>
                        <p className='location-title title'>{trainingLocation.title}</p>
                        <p className='location-adress'>{trainingLocation.adress}</p>
                    </div>
                    <EditIcon  onClick={() => toggleConfirmationModal(true)}/>
                </InstanceLocationSC>
            );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstanceLocationSC = styled.div`
    width : 100%;
    padding : 15px;
    box-sizing : border-box;
    background-color : white;
    border-radius : 5px;
    margin-top : 10px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    display : flex;
    position : relative; 

    .avatar {
        min-height : 100px; 
        max-height : 100px;
        min-width : 100px;
        max-width : 100px;
        background-size : cover;
        background-position : center;
        border-radius : 5px;
    }

    .location-information {
        display : flex; 
        flex-direction : column;
        padding : 15px;
        padding-top : 0;
        padding-left : 20px;
    }

    .title {
        font-size : 1.2em;
    }

    .location-adress {
        color : grey;
    }

    svg {
        position : absolute;
        right : 15px;
        top : 15px;
        cursor : pointer;
        color : grey;
    }
`

export default InstanceLocation