import React, { useContext } from 'react';
import styled from 'styled-components';
import { UserManagementContext } from '../UserManagement';
import DropdownSelect from '../../GeneralComponents/InputFields/DropDownSelect';

const UserRoleComponent = ({ data }) => {

    const [showDropdown, setShowDropdown] = React.useState(true);
    const { setRole, role } = useContext(UserManagementContext);

    const rendering = () => {
        return (
            <UserRoleComponentSC
                onClick={() => setShowDropdown(!showDropdown)}
            >
            </UserRoleComponentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UserRoleComponentSC = styled.div`
    
`

export default UserRoleComponent