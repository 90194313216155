import React from 'react';
import styled from 'styled-components';
import FigmaInput from '../../GeneralComponents/FigmaInput';
import { TrainersOverviewContext } from '../TrainersOverview';
import TextInputField from '../../GeneralComponents/InputFields/TextInputField';
import AreaInputField from '../../GeneralComponents/InputFields/AreaInputField';

const TrainerInfo = ({trainer}) => {

    const { inputProperties, setInputProperties } = React.useContext(TrainersOverviewContext);

    const rendering = () => {
        return (
            <TrainerInfoSC>
                <div className='custom-row-item'>
                    <TextInputField 
                        typedValue={trainer.firstName}
                        setValueFunction={(value) => setInputProperties({...inputProperties, firstName : value}) }
                        label='First Name'
                        suggestedValue={trainer.firstName}
                        style={{ maxWidth : '49%', minWidth : '49%'}}
                    />

                    <TextInputField 
                        typedValue={trainer.lastName}
                        setValueFunction={(value) => setInputProperties({...inputProperties, lastName : value}) }
                        label='Last Name'
                        suggestedValue={trainer.lastName}
                        style={{ maxWidth : '49%', minWidth : '49%'}}
                    />
                </div>
                <div className='custom-row-item'>
                    <TextInputField 
                        typedValue={trainer.email}
                        setValueFunction={(value) => setInputProperties({...inputProperties, email : value}) }
                        label='Email adress'
                        suggestedValue={trainer.email}
                        style={{ maxWidth : '100%', minWidth : '100%'}}
                    />
                </div>
                <div className='custom-row-item'>
                    <TextInputField 
                        typedValue={trainer.phone}
                        setValueFunction={(value) => setInputProperties({...inputProperties, phone : value}) }
                        label='Phone'
                        suggestedValue={trainer.phone}
                        style={{ maxWidth : '49%', minWidth : '49%'}}
                    />
                    <TextInputField 
                        typedValue={trainer.linkedIn}
                        setValueFunction={(value) => setInputProperties({...inputProperties, linkedIn : value}) }
                        label='LinkedIn'
                        suggestedValue={trainer.linkedIn}
                        style={{ maxWidth : '49%', minWidth : '49%'}}
                    />
                </div>
                <div className='custom-row-item' style={{flexGrow : 1}}>
                    <AreaInputField 
                        typedValue={trainer.shortDescription}
                        setValueFunction={(value) => setInputProperties({...inputProperties, shortDescription : value}) }
                        label='Short description'
                        suggestedValue={trainer.shortDescription}
                        style={{ maxWidth : '100%', minWidth : '100%'}}
                    />
                </div>
            </TrainerInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainerInfoSC = styled.div`
    display : flex;
    flex-direction : column;
    padding : 15px;
    flex-grow : 1;

    .custom-row-item {
        display : flex; 
        margin-bottom : 15px;
        justify-content : space-between;

        &>div {
            box-sizing : border-box;
        }
    }
`

export default TrainerInfo