import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import { useNavigate } from 'react-router-dom';
import { TrainingsOverViewContext } from '../TrainingsOverview/TrainingsOverview';

const SurveyMenu = ({ instance, training, state, setState, divRef}) => {

    const navigate = useNavigate();


    const navigateToSurvey = () => {  
        navigate(`/surveys/${training.surveyId}/${instance._id}/${instance.trainingId}`);
    }

    const rendering = () => {
        return (
            <SurveyMenuSC ref={divRef}>
                <button onClick={() => navigateToSurvey()}>Check responses</button>
                <button onClick={() => setState({...state, sendOutSurveyModalToggled : true, surveyMenu : false})}>Send out survey</button>
                <button onClick={() => navigateToSurvey()}>Edit survey</button>
            </SurveyMenuSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyMenuSC = styled.div`
    position : absolute;
    background-color : white;
    display : flex;
    flex-direction : column;
    border-radius : 5px;
    border-radius : 5px;
    overflow : hidden;
    margin-top : 65px;
    min-width : 220px;
    border : 1px solid rgba(0,0,0,0.1);
    z-index : 200;

    & button {
        padding : 20px;
        border : none;
        background-color : white;
        cursor : pointer;
        font-size : 1rem;
        text-align : left;
        border-bottom : 1px solid rgba(0,0,0,0.1);

        &:hover {
            background-color : #f5f5f5;
            cursor: pointer;    
        }
    }

`

export default SurveyMenu