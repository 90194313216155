import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import { AppContext } from '../../App';
import Card from './dashboardObjects/Card';
import PieChart from './dashboardObjects/PieChart';
import PieChartComponent from './dashboardObjects/PieChart';
import BarCharComponent from './dashboardObjects/BarChartComponent';

export const MetricDashboardContext = React.createContext(null); 

const MetricDashboard = () => {

    const [registrations, setRegistrations] = React.useState(0);    
    const [revenue, setRevenue] = React.useState(0);
    const [revenueByTraining, setRevenueByTraining] = React.useState([]);

    React.useEffect(() => {
        const getRevenue = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/get-total-revenue`);
                const revenue = result.data[0].totalRevenue;
                const formattedRevenue = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(revenue);
                setRevenue(formattedRevenue);
            }
            catch(err) {
                console.log(err);
            }
        }

        const getAttendees = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/total-attendees`);
                setRegistrations(result.data.totalAttendees);
            }
            catch(err) {
                console.log(err);
            }
        }

        const getRevenueByTraining = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/revenue-by-training`);
                setRevenueByTraining(result.data);
            } catch(err) {
                console.log(err);
            }
            }

        getRevenue();
        getAttendees();
        getRevenueByTraining();

    }, []);

    const rendering = () => {
        return (
            <MetricDashboardSC>
                <h1>Reporting</h1>
                <div className='grid-container'>
                    <div className='column'>
                        <div className='cards-row admin-row'>
                            <Card 
                                title='# Registrations'
                                value={registrations}
                                period='last 30 days'
                                growthChart={32}
                            />
                            <Card 
                                title='Total revenue'
                                value={revenue}
                                period='last 30 days'
                                growthChart={32}
                            />
                        </div>
                    </div>
                    <div className='column'>
                    </div>
                </div>
                <div className='row bar-chart-component' >
                    <h2>Revenue per training</h2>
                    <BarCharComponent data={revenueByTraining} />
                </div>
            </MetricDashboardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MetricDashboardSC = styled.div`
    display : flex;
    background-color : white;
    flex : 1;
    display : flex;
    flex-direction : column;
    margin : 10px;
    border-radius : 10px;

    &>h1 {
        margin : 0;
        font-size : 1.5rem;
        font-weight : 400;
        padding : 20px;
    }

    .grid-container {
        display : grid;
        grid-template-columns : 1fr 1fr;
        grid-gap : 20px;
        padding : 20px;

        .column {
            display : flex;
        }
    }

    .cards-row {
        display : flex;
        gap : 20px;
    }

    .bar-chart-component {
        display : flex;
        flex-direction : column;
        gap : 20px;
        padding : 20px;
        height : 400px;
        border : 1px solid lightgrey;
        border-radius : 10px;
        margin : 20px;
        margin-top : 0;
        display : flex;
        padding : 20px;

        h2 {
            margin : 10px; 
            line-height : 0; 
            font-size : 1.1rem;
            color : grey;
            font-weight : 400;
        }
    }
`

export default MetricDashboard