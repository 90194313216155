import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { set } from 'react-hook-form';
import { CheckCircleOutline } from '@mui/icons-material';

const FigmaInput = ({ label, id, itemsInRow, reference, isArea, defaultValue, options, style, placeholder, onChange }) => {

    const [optionsToggled, toglleOptions] = React.useState(false);
    const [selectedOption, setSelectedOption] = React.useState(defaultValue);

    const selectOption = (option) => {
        setSelectedOption(option);
        toglleOptions(false);
    }

    const toggleOptions = () => {
        toglleOptions(true);
    };

    React.useEffect(() => {
        setSelectedOption(defaultValue);
    }, []);

    React.useEffect(() => {
        if(reference) {
            reference.current = selectedOption;
        }
        
    }, [selectedOption]);

    const onChangeFunction = (target) => {
        console.log('target : ', target);
        onchange && onChange(target);
    }

    const rendering = () => {

        return (
            <FigmaInputSC
                style={{
                    width : `${100 / itemsInRow}%`,
                }}
            >
                {isArea ? 
                    <div className='text-area-item'>
                        <textarea
                            style={{
                                flexGrow : 1
                            }}
                            onChange={(e) => onChangeFunction(e.target.value)}
                            defaultValue={defaultValue}
                        />
                        <p className='label'>{label}</p>
                    </div>
                    :

                    <div className='input-item' style={{...style}}>
                        <p className='label'>{label}</p>

                        {!options ? 
                                <input 
                                    type='text'
                                    style={{
                                        flexGrow : 1
                                    }}
                                    onChange={(e) => onChangeFunction(e.target.value)}
                                    defaultValue={defaultValue}
                                    onFocus={() => toggleOptions()}
                                    id={id}
                                    autoComplete='off'
                                />

                            :

                            <> 

                                <input 
                                    type='text'
                                    style={{
                                        flexGrow : 1
                                    }}
                                    value={selectedOption[options.key]}
                                    onFocus={() => toggleOptions()}
                                    id={id}
                                    autoComplete='off'
                                    className='select-options-input'
                                    ref={reference}
                                    readOnly
                                />


                                {optionsToggled &&
                                <div className='select-options'>
                                    {options.object.map((option, index) => {
                                        return (
                                            <div key={index} className='select-option' onClick={() => selectOption(option)}>
                                                {option[options.key]}
                                            </div>
                                        );
                                    })}
                                </div>}

                                <ArrowDropDownIcon style={{position : 'absolute', right : '15px', top : '15px', color : 'grey'}} />
                            </>

                    }                        
                    </div>
                }

            </FigmaInputSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FigmaInputSC = styled.div`
    display : flex; 
    flex-direction : column;
    box-sizing : border-box;
    padding : 10px;

    .select-options-input {
        &:hover {
            cursor : pointer;
        }
    }
    
    .input-item {
        position : relative;
        display : flex;

        .label {
            margin-top : -10px;
            color : grey;
            position :absolute; 
            background-color : white;
            left : 10px;
            padding : 0 10px;
            font-size : 0.8rem;
        }

        input {
            padding : 15px;
            font-family : inherit;
            font-size : inherit; 
            border-radius : 5px;
            border : 1px solid lightgrey;
            outline : none;
            color : inherit;
        }

        .select-options {
            position : absolute;
            top : 100%;
            left : 0;
            width : 100%;
            background-color : white;
            border : 1px solid lightgrey;
            border-top : none;
            border-radius : 0 0 5px 5px;
            display : flex;
            flex-direction : column;
            z-index : 1000;

            .select-option {
                padding : 10px;
                border-bottom : 1px solid lightgrey;
                cursor : pointer;

                &:hover {
                    background-color : #f0f0f0;
                }
            }
        }
    }

    .text-area-item {
        position : relative;
        display : flex;
        flex-grow : 1;

        .label {
            margin-top : -10px;
            color : grey;
            position :absolute; 
            background-color : white;
            left : 10px;
            padding : 0 10px;
            font-size : 0.8rem;
        }

        textarea {
            padding : 25px;
            font-family : inherit;
            font-size : inherit; 
            border-radius : 5px;
            border : 1px solid lightgrey;
            outline : none;
            color : grey;
            resize : none;
        }
    }

`

export default FigmaInput