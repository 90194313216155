import { CircularProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const LoadingSpinner = () => {

    const rendering = () => {
        return (
            <LoadingSpinnerSC>
                <CircularProgress />
            </LoadingSpinnerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LoadingSpinnerSC = styled.div`
    display : flex;
    justify-content : center;
    align-items : center;
    flex-grow : 1;

    p {
    }
`

export default LoadingSpinner