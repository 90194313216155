import React from 'react';
import styled from 'styled-components';
import AttendeeRecord from './AttendeeRecord';
import { IndividualTrainingContext } from './IndividualTraining';
import OpenspotRecord from './OpenSpotRecord';

const Attendees = () => {

    const { state, setState } = React.useContext(IndividualTrainingContext);
    const { attendees, capacity } = state.currentInstance;

    const placeHolderSlots = [];
    for(let i = 0; i < capacity - attendees.length; i++) {
        placeHolderSlots.push(i);
    }

    const rendering = () => {
        return (
            <AttendeesSC>
                {attendees.map((attendee, index) => {
                    return (
                        <AttendeeRecord key={attendee._id} attendee={attendee}/>
                    )
                })}

                {placeHolderSlots.map((slot, index) => <OpenspotRecord key={index}/>)}
            </AttendeesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AttendeesSC = styled.div`
    border-radius : 5px;
    background-color : white;
    display : flex;
    flex-direction : column;
    padding : 10px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);

`

export default Attendees