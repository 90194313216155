import { Button, IconButton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import { IndividualTrainingContext } from './IndividualTraining';
import AreYouSure from '../GeneralComponents/AreYouSure';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
const InstanceOptions = () => {

    const { state, setState, divRef2 } = React.useContext(IndividualTrainingContext);
    const { instanceOptionsToggled } = state;
    const [confirmObject, setConfirmObject] = React.useState(null);

    const closeMenu = () => {
        setConfirmObject(null);
        setState({...state, instanceOptionsToggled : false});
    }

    const deleteInstance = () => {

        const deleteInstance = async () => {
            try {
                const response = await Axios.delete(`${backEndAdress}/api/instances/${state.currentInstance._id}`);
                setState({
                    ...state,
                    currentInstance : null,
                    instanceOptionsToggled : false
                });
            } catch (error) {
                console.log('error', error);
            }
        };

        deleteInstance();
    }

    const rendering = () => {
        return (
            <InstanceOptionsSC ref={divRef2}>
                <>
                    {confirmObject && 
                        <AreYouSure 
                            title={confirmObject.title}
                            textOption1={confirmObject.textOption1}
                            textOption2={confirmObject.textOption2}
                            function1={confirmObject.function1}
                            function2={confirmObject.function2}
                        />
                    }
                </>
                
                <IconButton 
                    onClick={() => setState({...state, instanceOptionsToggled : !instanceOptionsToggled})}
                    sx={{
                        backgroundColor : 'white',
                        minHeight : '50px',
                        minWidth : '50px',
                        margin : '15px',
                    }}
                >
                    <MenuIcon />
                </IconButton>
                {instanceOptionsToggled && (
                    <div className='options'>
                        <p 
                            onClick={() => {
                                setConfirmObject({
                                    title : 'Are you sure you want to delete this instance?',
                                    textOption1 : 'Yes',
                                    textOption2 : 'No',
                                    function1 :  deleteInstance,
                                    function2 : closeMenu
                                });
                            }}
                        >delete scheduled workshop</p>
                    </div>
                )}
            </InstanceOptionsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstanceOptionsSC = styled.div`
    position : relative; 

    .options {
        position : absolute; 
        top : 30px; 
        right : 0; 
        background-color : white; 
        z-index : 1000; 
        border : 1px solid grey;
        min-width : 250px;
        margin-top : 35px;

        & > p {
            padding : 10px; 
            margin : 0; 
            cursor : pointer; 
            transition : background-color 0.3s; 

            &:hover {
                background-color : lightgray; 
            }
        }

    }
`

export default InstanceOptions