import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../IndividualTraining/IndividualTraining';
import { EmailScheduleContext } from './EmailSchedule';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios';
import moment from 'moment'; 

const EmailScheduleHeader = () => {

    const { state, setState, training } = React.useContext(IndividualTrainingContext);
    const { state : emailState, setState : setEmailState,  } = React.useContext(EmailScheduleContext);

    const { currentInstance } = state;

    const updateTouchPoints =  () => {

        const handleBarsVariables = {
            userName : currentInstance.attendees.length>0 ? currentInstance.attendees[0].firstName : '[userName]',
            trainingName : currentInstance.trainingName ? currentInstance.trainingName : '',
            trainingDescription : training ? training.description : '',
            date1 : currentInstance.dates ? currentInstance.dates[0] ? moment(currentInstance.dates[0]).format('DD/MM/YYYY') : '[date]' : '[date]',
            date2 : currentInstance.dates ? currentInstance.dates[0] ? moment(currentInstance.dates[1]).format('DD/MM/YYYY') : '[date]' : '[date]',
            date3 : currentInstance.dates ? currentInstance.dates[0] ? moment(currentInstance.dates[2]).format('DD/MM/YYYY') : '[date]' : '[date]',
            date4 : currentInstance.dates ? currentInstance.dates[0] ? moment(currentInstance.dates[3]).format('DD/MM/YYYY') : '[date]' : '[date]',
            date5 : currentInstance.dates ? currentInstance.dates[0] ? moment(currentInstance.dates[5]).format('DD/MM/YYYY') : '[date]' : '[date]',
            adress : currentInstance.location ? currentInstance.location.adress : '',
            locationInstructions : currentInstance.location ? currentInstance.location.instructions : '',
            currentInstanceLanguage : currentInstance.language ? currentInstance.language : '',
            trainerName : currentInstance.trainer ? currentInstance.trainer.firstName : '', 
            trainerDescription : currentInstance.trainer ? currentInstance.trainer.shortDescription : '', 
        }

        const updateInstance = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/touch-points/update-touch-points/${state.currentInstance._id}`, {
                    touchPoints : emailState.touchPoints,
                    handleBarsVariables : handleBarsVariables
                })        
                setState({
                    ...state,
                    currentInstance : {
                        ...state.currentInstance, 
                        touchPoints : response.data.touchPoints
                    },
                    emailScheduleToggled : false
                });
            } catch (error) {
                console.log(error);
            }
        };

        updateInstance();


    }



    const rendering = () => {
        return (
            <EmailScheduleHeaderSC>
                <h1>Mail schedule</h1>
                <div className='buttons'>
                    <button 
                        className='button'
                        onClick={() => setState({
                            ...state, 
                            emailScheduleToggled : false
                        })}    
                    >
                        Cancel
                    </button>
                    <button 
                        className='button'
                        onClick={() => updateTouchPoints()}    
                    >
                        Save
                    </button>
                </div>
            </EmailScheduleHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const EmailScheduleHeaderSC = styled.div`
    display : flex;
    justify-content : space-between;

    h1 {
        font-size : 1.6rem;
        font-weight : 400;
        padding-left : 10px;
    }
`

export default EmailScheduleHeader