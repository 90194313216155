import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import SurveyMenu from './SurveyMenu';

const TrainingOptions = ({ state, setState, training }) => {

    const navigate = useNavigate(); 

    const navToSignUp = () => {
        navigate(`/sign-up/${state.currentInstance.trainingId}/${state.currentInstance._id}` , { replace : true });
    }

    const navToFeedback = () => {
        navigate(`/sign-up/${state.currentInstance.trainingId}/${state.currentInstance._id}` , { replace : true });
        }   

    const rendering = () => {
        return (
            <TrainingOptionsSC>
                <button className='button'>mail all users</button>
                <button className='button' onClick={() => setState({...state, emailScheduleToggled : true})}>Set e-mail schedule</button>
                <button className='button'>Option</button>
                <button className='button' onClick={() => navToSignUp()}>Sign-up form</button>

            </TrainingOptionsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingOptionsSC = styled.div`
    padding : 10px;
    display : flex;
    padding-left : 0;
    position : relative;

    .button {
        min-height : 42px;
        font-size : 12px;
        font-family : inherit;
        display : flex;
        justify-content : space-between;
        padding : 20px;   
        background-color : white;
        box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
        border-radius : 5px;
        box-sizing : border-box;
        border : 0;
        margin-right : 10px;

        &:hover {
            background-color : #e5e5e5;
            cursor : pointer; 
        }
    }
`

export default TrainingOptions