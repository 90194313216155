import React from 'react';
import styled from 'styled-components';
import Switch from '@mui/material/Switch';

const Webinar = ({setTrainingToEditLocal, trainingToEditLocal}) => {

    const handleChange = (event) => {
        setTrainingToEditLocal({
            ...trainingToEditLocal,
            customProperties : {
                ...trainingToEditLocal.customProperties,
                webinar : event.target.checked
            }
        });
    }
    const rendering = () => {
        return (
            <WebinarSC>
                <p>Webinar</p>
                <Switch onChange={(e) => handleChange(e)} size="small" defaultChecked={trainingToEditLocal.customProperties.webinar} />
            </WebinarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const WebinarSC = styled.div`

`

export default Webinar