import React from 'react';
import styled from 'styled-components';

const Dashboard = () => {

    const rendering = () => {
        return (
            <DashboardSC>
                <p>dashboard</p>
            </DashboardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardSC = styled.div`
    
`

export default Dashboard