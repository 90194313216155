import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const IndividiualTemplateInfo = ({ template, register, errors }) => {

    console.log('errors: ', errors);
    const rendering = () => {
        return (
            <IndividiualTemplateInfoSC>
                <TextField  
                    label='Certificate name'
                    type='text' 
                    defaultValue={template.certificateName}
                    sx={{
                        width : '100%',
                        marginBottom : '15px'
                    }}
                    {...register('certificateName', {
                        required : {
                            value : true,
                            message : 'name is requried'
                        },
                        minLength : {
                            value : 3,
                            message : 'This field must be at least 3 characters'
                        }
                        }
                    )}
                    error={!!errors.certificateName}
                    helperText={errors.certificateName?.message}
                />
                <div className='filler'>
                    <TextField  
                        label='Text on certificate'
                        type='text' 
                        defaultValue={template.certificateText}
                        multiline
                        sx={{
                            width: '100%',
                            height: '100%', // Ensure the text field takes the full height of the parent div
                            '& .MuiInputBase-root': {
                            height: '100%', // Ensures input area fills the div,
                            alignItems: 'flex-start',
                            },
                        }}
                        {...register('certificateText', {
                            required : {
                                value : true,
                                message : 'text is requried'
                            },
                            minLength : {
                                value : 3,
                                message : 'This field must be at least 3 characters'
                            }
                        })}
                        error={!!errors.certificateText}
                        helperText={errors.certificateText?.message}
                    />
                </div>
            </IndividiualTemplateInfoSC>
        );

    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividiualTemplateInfoSC = styled.div`
    min-width : 450px;
    flex-grow : 1;
    padding : 25px;
    padding-right : 0;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;

    .filler {
        display : flex;
        flex : 1;
        flex-direction : column;
    }
`

export default IndividiualTemplateInfo