import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers';
import React from 'react';
import styled from 'styled-components';
import { MailScheduleBothLocationsContext } from '../MailScheduleBothLocations';
import AccessAlarmsIcon from '@mui/icons-material/AccessAlarms';
const TimingOptions = ({ register, errors, setBeforeOrAfter, beforeOrAfter, time, setTime, numberOfDays, setNumberOfDays }) => {

    const handleChange = (event) => {
        setBeforeOrAfter(event.target.value);
    };

    console.log('time : ', time);

    const rendering = () => {
        return (
            <TimingOptionsSC>
                <div className='container-title'>
                    <AccessAlarmsIcon />
                    <p>Timing</p>
                </div>
                <div className='row'>
                    <>
                        <TextField  
                            label='Number of days'
                            type='number' 
                            defaultValue={numberOfDays}
                            onChange={(e) => setNumberOfDays(e.target.value)}
                            // error={!!errors.numberOfDays}
                            // helperText={errors.numberOfDays?.message}
                            sx={{
                                width : '49%'
                            }}
                        />
                    </>
                    <FormControl
                        sx={{
                            width : '49%',
                        }}
                        fullWidth
                    >
                        <InputLabel 
                            id="demo-simple-select-label"
                            sx={{
                                backgroundColor : 'white',
                                paddingRight : '5px',

                            }}    
                        >
                            Before / after
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={beforeOrAfter}
                            value={beforeOrAfter}
                            label="Age"
                            onChange={handleChange}
                            sx={{ 
                                fontFamily : 'inherit', 
                                fontSize : 'inherit', 
                                color : 'black'
                            }}
                        >
                        <MenuItem 
                            value={'before'}
                            sx={{
                                padding : '10px'
                            }}    
                        >Before</MenuItem>
                        <MenuItem 
                            value={'after'}
                            sx={{
                                padding : '10px'
                            }}    
                        >After</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='row'>
                    <TimePicker
                        onChange={(time) => setTime(time)}
                        value={time}
                        className='time-picker-custom'
                        size={'small'}
                        fullWidth
                        sx={{
                            width : '100%',
                            marginTop : '10px',
                        }}
                    />
                </div>
            </TimingOptionsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TimingOptionsSC = styled.div`
    background-color :white;
    padding : 25px;
    border-radius : 5px;
    margin : 15px;
    margin-left : 25px;
    border : 1px solid #C9C9C9;
    
    .container-title {
        display : flex; 
        align-items : center;
        margin-bottom : 20px;

        &>svg {
            margin-right : 10px;
            color : grey;
            font-size : 30px;
        }

        &>p {
            font-size : 1.2rem;
            font-weight : 350;
        }
    }

    .number-of-days {
        display : flex;
        align-items : center;
    }

    .row {
        display : flex;
        justify-content : space-between;
        box-sizing : border-box;
    }
`

export default TimingOptions