import React from 'react';
import styled from 'styled-components';
import { Chart } from "react-google-charts";

const BarChart2 = ({ data }) => {

    const rendering = () => {
        return (
            <BarChart2SC>
                <Chart
                    chartType="PieChart"
                    data={data}
                    // options={options}
                    width={"100%"}
                    height={"400px"}
                />
            </BarChart2SC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BarChart2SC = styled.div`
    
`

export default BarChart2