import React from 'react';
import styled from 'styled-components';
import { TrainingCalendarContext } from './TrainingCalendar';
import TrainingCard from './TrainingCard';
import Modal from '../../GeneralComponents/Modal';

const TrainingBar = ({ instance }) => {

    const [modalOpened, setModalOpened] = React.useState(false);


    const { currentMonth } = React.useContext(TrainingCalendarContext);
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const currentMonthIndex = monthNames.indexOf(currentMonth);

    const dates = instance.dates.filter(dateString => {
        const date = new Date(dateString);
        return date.getMonth() === currentMonthIndex;
    });

    const firstDayOfMonth = dates.length > 0 ? new Date(dates[0]).getDate() : null;
    const lastDayOfMonth = dates.length > 0 ? new Date(dates[dates.length - 1]).getDate() : null;

    const marginLeft = ( firstDayOfMonth * 50 ) - 50;
    const width = ( ( lastDayOfMonth - firstDayOfMonth + 1) * 50) -10;

    const rendering = () => {
        return (
            <TrainingBarSC
                style={{ 
                    left : `${marginLeft}px`, 
                    width : `${width}px` 
                }}
            >

                {modalOpened && 
                    <Modal>
                        <TrainingCard instance={instance} setModalOpened={setModalOpened} />
                    </Modal>
                }

                <img 
                    src={instance.trainer.imageUrl} 
                    alt='trainer'
                    onClick={() => setModalOpened(true)}
                />
            </TrainingBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingBarSC = styled.div`
    background-color : #f0f0f0;
    border-radius : 50px;
    display : flex;
    background-color : #f0f0f0;
    position : absolute;
    top :0;
    bottom : 0;

    
    img {
        width : 40px;
        height : 40px;
        border-radius : 100%;
    }
`

export default TrainingBar