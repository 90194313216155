import React from 'react';
import styled from 'styled-components';

const Calendar = ({ dates }) => {

    const rendering = () => {
        return (
            <CalendarSC>
                {dates.map((date, index) => {

                    const isWeekend = date.getDay() === 0 || date.getDay() === 6;

                    return (
                        <div key={index} className='date'>
                            <p 
                                className='date-circle'
                            >{date.getDate()}</p>
                        </div>
                    );
                })}
            </CalendarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CalendarSC = styled.div`
    display : flex;
    max-width : 100%;
    margin-bottom : 10px;

    .date-circle {
        border-radius : 50%;
        width : 40px;
        height : 40px;
        display : flex;
        justify-content : center;
        align-items : center;
        margin : 0;
        padding : 0;
        background-color : #f0f0f0;
        margin-right : 10px;
        color : white;
    }
`

export default Calendar