import React from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { MailsContext } from '../Mails';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import moment from 'moment';
import { set } from 'react-hook-form';

const TestForInstanceOption = () => {

    const { state , setState } = React.useContext(MailsContext);

    const [training, setTraining] = React.useState('');
    const [allTrainings, setAllTrainings] = React.useState([]);
    const [instance, setInstance] = React.useState('');
    const [allInstances, setAllInstances] = React.useState([]);
    const [trainerImageURL, setTrainerImageURL] = React.useState('');

    const selectTraining = (event) => {
        const trainingName = event.target.value;
        const training = allTrainings.find((training) => training.name === trainingName);
        setTraining(training);
    }

    React.useEffect(() => {

        const getTrainerImage = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers/${instance.trainer._id}`);
                setTrainerImageURL(response.data.imageUrl);

            } catch (error) {
                console.log(error);
            }
        }

        if(instance) {
            getTrainerImage();
        }
    }, [instance]);

    const selectInstance = async (event) => {

        const instanceName = event.target.value;
        const instance = allInstances.find((instance) => instance.instanceName === instanceName);

        try {
            const response = await Axios.get(`${backEndAdress}/api/trainers/${instance.trainer._id}`);
            const imageUrl = response.data.imageUrl

            const adress = instance.location ? instance.location.adress : '';
            console.log('adress', adress);
    
            const variablesObject = {
                userName : instance.attendees.length>0 ? instance.attendees[0].firstName : '[userName]',
                trainingName : instance.trainingName ? instance.trainingName : '',
                trainingDescription : training ? training.description : '',
                date1 : instance.dates ? instance.dates[0] ? moment(instance.dates[0]).format('DD/MM/YYYY') : '[date]' : '[date]',
                date2 : instance.dates ? instance.dates[0] ? moment(instance.dates[1]).format('DD/MM/YYYY') : '[date]' : '[date]',
                date3 : instance.dates ? instance.dates[0] ? moment(instance.dates[2]).format('DD/MM/YYYY') : '[date]' : '[date]',
                date4 : instance.dates ? instance.dates[0] ? moment(instance.dates[3]).format('DD/MM/YYYY') : '[date]' : '[date]',
                date5 : instance.dates ? instance.dates[0] ? moment(instance.dates[5]).format('DD/MM/YYYY') : '[date]' : '[date]',
                adress : instance.location ? instance.location.adress : '',
                locationInstructions : instance.location ? instance.location.instructions : '',
                instanceLanguage : instance.language ? instance.language : '',
                trainerName : instance.trainer ? instance.trainer.firstName : '', 
                trainerDescription : instance.trainer ? instance.trainer.shortDescription : '', 
                trainerImageURL : imageUrl.length>0 ? imageUrl : 'https://t3.ftcdn.net/jpg/05/16/27/58/360_F_516275801_f3Fsp17x6HQK0xQgDQEELoTuERO4SsWV.jpg', 
            }
          
            setState({
                ...state,
                handleBarVariables : variablesObject
            });
            
            setInstance(instance);   

        } catch (error) {
            console.log(error);
        } 
    }

    React.useEffect(() => {
        const getTrainings = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings`);
                setAllTrainings(response.data)

            } catch (error) {
                console.log(error);
            }
        }

        getTrainings();
        
    }, []);

    React.useEffect(() => {
        const getInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/training/${training._id}`);
                setAllInstances(response.data)

            } catch (error) {
                console.log(error);
            }
        }

        if(training) {
            getInstances();
        }


    }, [training]);


    const rendering = () => {

        console.log('instance' ,instance)
        console.log('training', training)

        return (
            <TestForInstanceOptionSC>
                <h1>Select training and instance to fill in the variables</h1>
                <div className='form-for-instance-input'>
                    <FormControl fullWidth style={{marginBottom : '15px'}}>
                        <InputLabel id="demo-simple-select-label">Training</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={training.name ? training.name : ''}
                            label="Training"
                            onChange={selectTraining}
                        >
                            {allTrainings.map((training) => {
                                return (
                                    <MenuItem 
                                        value={training.name}
                                        key={training._id}    
                                    >{training.name}</MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>

                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Instance</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={instance.instanceName ? instance.instanceName : ''}
                            label="Instance"
                            onChange={selectInstance}
                        >
                            {allInstances.map((instance) => {
                                return (
                                    <MenuItem   
                                        value={instance.instanceName}
                                        key={instance._id}    
                                    >{instance.instanceName}</MenuItem>
                                );
                            })}

                        </Select>
                    </FormControl>
                </div>
            </TestForInstanceOptionSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TestForInstanceOptionSC = styled.div`
    min-width : 220px;
    max-width : 220px;
    overflow : hidden;
    display : flex;
    padding-left : 20px;
    flex-direction : column;

    h1 {
        padding : 10px;
        border : 1px solid grey;


        border-radius : 5px;
        font-size : 1rem;
        margin-bottom : 15px;
        font-weight : 400;
    }
`

export default TestForInstanceOption