import { Button, ButtonBase } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const RegisteredConfirmation = ({ state , setState }) => {

    const rendering = () => {
        return (
            <RegisteredConfirmationSC>
                <div className='info'>
                <h1>Registration complete!</h1>
                <p>Thanks for signing up, you will receive a confirmation e-mail shortly.</p>
                </div>
                <div className='buttons'>
                    <ButtonBase onClick={() => setState({...state, registeredConfirmation : false})}>
                        <Button variant='contained'>Close</Button>
                    </ButtonBase>
                </div> 
            </RegisteredConfirmationSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RegisteredConfirmationSC = styled.div`
    position : fixed;
    min-height : 100vh;
    min-width : 100vw;
    background-color : white;
    z-index : 100;
    top : 0;
    left : 0;
    bottom : 0;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;
    justify-content : center;
    padding-left : 80px;
`

export default RegisteredConfirmation