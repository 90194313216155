import React from 'react';
import styled from 'styled-components';
import MonthSelection from './MonthSelection';
import Calendar from './Calendar';
import TrainingsPerTrainer from './TrainingsPerTrainer';

export const TrainingCalendarContext = React.createContext();

const TrainingCalendar = () => {

    const [currentMonth, setCurrentMonth] = React.useState('January');

    React.useLayoutEffect(() => {
        const monthNames = [
            'January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'
        ];
        const currentMonthName = monthNames[new Date().getMonth()];
        setCurrentMonth(currentMonthName);
    }
    , []);

    const getDaysInMonth = (month, year) => {
        return new Date(year, new Date(`${month} 1, ${year}`).getMonth() + 1, 0).getDate();
    };

    const months = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const rendering = () => {
        const monthIndex = months.indexOf(currentMonth) + 1;
        const dates = [];

        for (let day = 1; day <= getDaysInMonth(currentMonth, 2024); day++) {
            dates.push(new Date(2024, monthIndex - 1, day));
        }

        return (
            <TrainingCalendarContext.Provider value={{ 
                months, 
                currentMonth, 
                setCurrentMonth 
            }}>
                <TrainingCalendarSC>
                    <h1 className='title'>Training Calendar</h1>    
                    <MonthSelection  />
                    <Calendar dates={dates} />
                    <TrainingsPerTrainer />
                </TrainingCalendarSC>
            </TrainingCalendarContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingCalendarSC = styled.div`
    background-color : white;
    border-radius : 10px;
    padding : 20px;
    margin-left : 20px;
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    overflow : scroll;
    max-height : 300px;
    min-height : 300px;
    margin-bottom : 20px;
`

export default TrainingCalendar