import React from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import { type } from '@testing-library/user-event/dist/type';
import SurveyFilterSideBar from './SurveyFilterSideBar/SurveyFilterSideBar';
import SurveyResponses from './SurveyResponses.js/SurveyResponses';
import PreSendMenu from './FillSurveyLink/PreSendMenu';
import { AppContext } from '../../App';

export const SurveyContext = React.createContext(null);

const Survey = () => {

    const { appState } = React.useContext(AppContext);  
    const { tenant } = appState;
    const instanceIdFromURL = window.location.hash.split('/')[3];
    const surveyIdFromUrl = window.location.hash.split('/')[2];

    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const emailRef = React.useRef(null);

    const [state, setState] = React.useState({
        survey : null,
        training : null,
        instance : null,
        preSendToggled : false
    });

    const [training, setTraining] = React.useState(null); 
    const [instance, setInstance] = React.useState(null);
    const [trainer, setTrainer] = React.useState(null);

    //All use effects
    {
    React.useEffect(() => {
        const getSurvey = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/surveys/${surveyIdFromUrl}`)
                console.log('response from survey', response.data)
                setState({
                    ...state,
                    survey : response.data,
                })
            } catch (error) {
                console.log(error)
            }
        }

        getSurvey()

    },[]);

    React.useEffect(() => {
        const getInstance = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/${instanceIdFromURL}`)
                setInstance(response.data);
            } catch (error) {
                console.log(error)
            }
        }

        getInstance()

    },[]);

    React.useEffect(() => {
        const getTrainer = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers/${instance.trainer._id}`)
                setTrainer(response.data);
            } catch (error) {
                console.log(error)
            }
        }
        if(instance) {
            getTrainer()
        }

    },[instance]);

    React.useEffect(() => {
        const getTraining = async () => {
            try {
                const test = await Axios.get(`${backEndAdress}/api/trainings/${instance.trainingId}`);
                setTraining(test.data);
            } catch (error) {
                console.log(error);
            }
        }

        if(instance) {
            getTraining()
        }

    },[instance]);
    }

    const sendSurvey = () => {
        const answersObject = {
            surveyId : surveyIdFromUrl,
            training,
            instance,
            trainer,
            user : 'loserke',
            responses : [{
                question : 'Hoe vond je het zelf gaan',
                possibleAnswers : ['Best kut', 'cava wel eigenlijk'],
                type : 'multiple-choice',
                answerIndex : 1
            },{
                question : 'Punten op een schaal van 10', 
                possibleAnswers : [1,2,3,4,5],
                type : 'multiple-choice',
                answerIndex : 3
            },{
                question : 'Hoe ben je bij ons terecht gekomen?', 
                possibleAnswers : null,
                type : 'free-text',
                answerIndex : 'Online advertentie'
            }]
        }

        const createAnswersObject = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/survey-responses`, answersObject)
                console.log('succes', response.data);
            } catch (error) {
                console.log(error)
            }
        }

        createAnswersObject()
    }

    const rendering = () => {
        if(training && instance && trainer ) {

            return ( 
                <SurveySC>
                    <SurveyContext.Provider value={{
                        state,
                        setState,
                        training,
                        instance,
                        trainer,
                        firstNameRef,
                        lastNameRef,
                        emailRef,
                        sendSurvey,
                        surveyIdFromUrl
                    }}>
                        <>
                            {state.preSendToggled && <PreSendMenu />}

                            <div className='survey-options'>
                                <div className='home'>
                                    <p>Home</p>
                                </div>
                                <div className='other-options'>
                                    <p>Edit survey</p>
                                    <p>Responses</p>
                                    <p>Home</p>
                                </div>
                            </div>

                            <div className='survey-content-container'>
                                <SurveyResponses />
                               
                            </div>
                        </>
                    </SurveyContext.Provider>
                </SurveySC>
            );
        }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveySC = styled.div`
    min-height : 100vh;
    min-width : 100vw;
    background-color : white;
    background-color : #f5f5f5;
    display : flex;
    flex-direction : column;
    box-sizing : border-box;

    .survey-options {
        display : flex;
        justify-content : space-between;
        padding : 10px;
        background-color : white;
        border-bottom : 1px solid #e0e0e0;
        padding : 15px;
        
        .other-options {
            display : flex;
            gap : 15px;
        }
        &>p {
            padding : 10px;
            cursor : pointer;
        }
    }

    .survey-content-container {
        display : flex;
        flex-grow : 1;

        .survey-container {
            display : flex;
            flex-direction : column;
            gap : 20px;
            width : 100%;
        }
    }

`

export default Survey