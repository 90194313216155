import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividualTraining';
import SurveyMenu from './SurveyMenu';

const SureyOptionsButton = ({ training, divRef }) => {

    const { state, setState } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <SureyOptionsButtonSC>
                {state.surveyMenu &&
                    <SurveyMenu
                        instance={state.currentInstance}
                        training={training}
                        state={state}
                        setState={setState}
                        divRef={divRef}
                    />
                }
                <button 
                    className='button' 
                    onClick={() => setState({...state, surveyMenu : true})}
                >Feedback survey</button>
            </SureyOptionsButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SureyOptionsButtonSC = styled.div`
    position : relative; 
    padding : 10px;
    padding-left : 0;
    padding-right : 0;
    margin-right : 10px;
`

export default SureyOptionsButton