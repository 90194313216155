import React from 'react';
import styled from 'styled-components';
import UsersList from './usersList/UsersList';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios';
import UserManagementFooter from './UserManagementFooter';
import IndividualUser from './individualUser/IndividualUser';
import DeleteUserPopUp from './usersList/DeleteUserPopUp';
import { AppContext } from '../../App';

export const UserManagementContext = React.createContext();

const UserManagement = () => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;

    const [users, setUsers] = React.useState([]);
    const [individualUser, setIndividualUser] = React.useState(null);
    const [deleteUserPopup, setDeleteUserPopup] = React.useState(false);
    const [userToDelete, setUserToDelete] = React.useState(null);

    React.useEffect(() => {

        // Fetching the users
        const getUsers = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/users/get-all/${tenant}`);
                setUsers(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getUsers();

    }, []);

    const rendering = () => {
        return (
            <UserManagementContext.Provider value={{
                users,
                setUsers,
                individualUser,
                setIndividualUser,
                setDeleteUserPopup,
                setUserToDelete,
                userToDelete
            }}>
                <UserManagementSC>
                    <>
                        {individualUser && <IndividualUser individualUser={individualUser}/>}
                        {deleteUserPopup && <DeleteUserPopUp />}
                    </>               
                    <UsersList />
                    <UserManagementFooter />
                </UserManagementSC>
            </UserManagementContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UserManagementSC = styled.div`
    flex-grow : 1;
    min-height : 100%;
    display : flex;
    flex-direction : column;
    position : relative; 
`

export default UserManagement