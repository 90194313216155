import React from 'react';
import styled from 'styled-components';
import SearchBar from './SearchBar';
import TemplatesGrid from './TemplatesGrid'; 
import Preview from '../Content/Preview';

const LoadTemplateScreen = ({
    mailTemplates,
    setEmailTemplate,
    mailTemplate,
    handleBarsObject 
}) => {

    const [searchQuery, setSearchQuery] = React.useState('');

    const rendering = () => {
        return (
            <LoadTemplateScreenSC>
                <div className='column-one'>
                    <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery}/>
                    <TemplatesGrid 
                        mailTemplates={mailTemplates} 
                        searchQuery={searchQuery}
                        setMailTemplateForPreview={setEmailTemplate}
                        mailTemplate={mailTemplate}
                    /> 
                </div>
                <div className='column-two'>
                    <Preview 
                        mailTemplate={mailTemplate}
                        handleBarsObject={handleBarsObject}
                    />
                </div>
            </LoadTemplateScreenSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
} 

const LoadTemplateScreenSC = styled.div`
    flex-grow : 1;
    display : flex;
    overflow-y : hidden;
    position : relative ; 

    .column-one {   
        display : flex;
        flex-direction : column;
        width : 30%;
        border-right : 1px solid lightgrey;
    }

    .column-two {
        display : flex;
        flex-direction : column;
        min-width : 70%;
        max-width : 70%;
        overflow-y : hidden;
    }
`

export default LoadTemplateScreen