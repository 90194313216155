import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const InstanceRecord = ({ instance, onClickFunction, preOpenTraing}) => {

    const rendering = () => {
        return (
            <InstanceRecordSC onClick={() => onClickFunction(preOpenTraing, instance)}>

                <p>{instance.instanceName}</p>

                <div className='instance-info'>
                    <p>{moment(instance.startDate).format('DD/MM/YYYY')}</p>
                    <p>{instance.trainer.firstName} {instance.trainer.lastName}</p>
                </div>
            </InstanceRecordSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstanceRecordSC = styled.div`
    border-bottom : 1px solid #e0e0e0;
    padding : 13px;
    display : flex;
    justify-content : space-between;

    .instance-info {
        display : flex;
        justify-content : space-between;
        width : 30%;
    }

    &:hover {
        background-color : #f5f5f5;
        cursor:  pointer;
    }
`

export default InstanceRecord