import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import IndividualAttendeeHeader from './IndividualAttendeeHeader';
import MailsFollowUp from './MailsFollowUp/MailsFollowUp';

const IndividualAttendee = ({ attendee, state } ) => {

    const touchPoints = state.currentInstance.touchPoints;

    const rendering = () => {
        return (
            <Modal>
                <IndividualAttendeeSC>
                    <IndividualAttendeeHeader />
                    <MailsFollowUp 
                        touchPoints={touchPoints}
                        attendee={attendee}    
                    />

                </IndividualAttendeeSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualAttendeeSC = styled.div`
    min-height : 100px;
    min-width : 100px;
    background-color : white;   
    min-width : 80vw;
    min-height : 80vh;
    display : flex;
    flex-direction : column;
    padding : 20px;
    border-radius : 5px;
    box-shadow : 0px 0px 10px 0px lightgrey;
`

export default IndividualAttendee