import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividualTraining';
import TrainingPropertyNonEdit from './TrainingPropertyNonEdit';
import TrainingPropertyEdit from './TrainingPropertyEdit';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import CustomDropDown from '../GeneralComponents/CustomDropDown';
import PropertiesNew from './PropertiesNew';
import InstanceTrainer from './TrainingProperties/InstanceTrainer/InstanceTrainer';
import InstanceLocation from './TrainingProperties/InstanceLocation/InstanceLocation';
import InstanceCertificate from './TrainingProperties/InstanceCertificate/InstanceCertificate';
import InstanceSurvey from './TrainingProperties/instanceSurvey/InstanceSurvey';

const TrainingProperties = () => {
    
    const rendering = () => {
        return (
            <TrainingPropertiesSC>
                <InstanceLocation />
                <InstanceTrainer />
                <InstanceCertificate />
                <InstanceSurvey />
            </TrainingPropertiesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingPropertiesSC = styled.div`
    display : flex; 
    flex-direction : column;
    
`

export default TrainingProperties