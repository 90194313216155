import React from 'react';
import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';
import { UserManagementContext } from '../UserManagement';

const DeleteUserButton = ({ data }) => {

    const { setDeleteUserPopup, setUserToDelete } = React.useContext(UserManagementContext);

    const deleteUser = (e) => {
        console.log('you')
        setUserToDelete(data._id);
        setDeleteUserPopup(true);
    }

    const rendering = () => {
        return (
            <DeleteUserButtonSC>
                <DeleteIcon 
                    onClick={deleteUser}
                />
            </DeleteUserButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DeleteUserButtonSC = styled.div`
    display : flex;
    align-items : center;
    min-height : 100%;
    
    &>svg {
        color : grey;
        &:hover {
            cursor : pointer;
            color : lightgrey;
        }
    }
`

export default DeleteUserButton