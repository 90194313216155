import React from 'react';
import styled from 'styled-components';
import CertificatesHeaderMenu from './CertificatesHeaderMenu';
import CertificateTemplatesGrid from './CertificateTemplatesGrid';

const CertificateTemplatesOverview = () => {

    const rendering = () => {
        return (
            <CertificateTemplatesOverviewSC>
                <CertificatesHeaderMenu />
                <CertificateTemplatesGrid />
            </CertificateTemplatesOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificateTemplatesOverviewSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;
`

export default CertificateTemplatesOverview