import React from 'react';
import styled from 'styled-components';

const MailsFollowUp = ({ touchPoints, attendee }) => {

    const rendering = () => {
        return (
            <MailsFollowUpSC>
                {touchPoints.map((touchPoint, index) => {

                    const received = attendee.mailsReceived.includes(touchPoint._id);

                    return (
                        <div 
                            className='mail-row'
                            key={index}
                        >
                            <p>{touchPoint.mailTemplate.title}</p>
                            {received ? <p>Received</p> : <p>Not Received</p>}
                        </div>
                    );
                })}
            </MailsFollowUpSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailsFollowUpSC = styled.div`
    .mail-row {
        display : flex;
        justify-content : space-between;
        padding : 10px;
        border-bottom : 1px solid light
    }
`

export default MailsFollowUp