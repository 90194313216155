import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';

const Survey = ({ individualTraining }) => {

    const [loading, setLoading] = React.useState(true);
    const [survey, setSurvey] = React.useState(null);

    React.useEffect(() => {
        const getSurvey = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/surveys/${individualTraining.surveyId}`);
                setSurvey(response.data);
                setLoading(false);
            }
            catch (error) {
                console.log(error);
            }
        }

        if (individualTraining?.surveyId) {
            getSurvey();
        }
        else {
            setLoading(false);
        }
    }, []);

    const hasSurvey = individualTraining?.surveyId; 

    const rendering = () => {
        if (loading) {
            return <p>Loading...</p>
        }
        return (
            <SurveySC>
                <p className='title'>Survey</p>
                <div className='survey'>
                {hasSurvey ? 
                    <>
                        <img src='https://cdn-icons-png.flaticon.com/512/3273/3273583.png'/>
                    </>
                    :
                    <>
                        <img src='https://cdn-icons-png.flaticon.com/512/3273/3273583.png'/>
                    </>
                }
                </div>
                <p className='cert-subtitle'>{hasSurvey ? survey.surveyName : 'No survey available'}</p>

            </SurveySC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveySC = styled.div`
    display : flex;
    flex-direction : column;
    margin : 20px;
    margin-left : 0;
    margin-top : 0;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    min-width : 200px;
    min-height : 200px;
    max-width : 200px;
    max-height : 200px;
    background-color : white;

    .survey {
        display : flex;
        flex : 1;
        justify-content : center;
        align-items : center;
        flex-direction : column;

        img {
            max-height : 50%;
            max-width : 50%;
            object-fit : contain;
        }
    }

    .cert-subtitle {
        padding : 10px;
        margin : 0;
        font-size : 10px;
        font-weight : 300;
    }


    
    .title {
        padding : 10px;
    }
`

export default Survey