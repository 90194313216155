import React from 'react';
import styled from 'styled-components';

const Actions = () => {

    const rendering = () => {
        return (
            <ActionsSC>
                <h1>Actions</h1>
                <div className='action-item'>
                    <img src='https://cdn-icons-png.flaticon.com/512/1828/1828665.png' />
                    <div className='action-item-text'>
                        <h3>Edit training</h3>
                        <p>Edit the current training</p>
                    </div>
                </div>
                <div className='action-item'>
                    <img src='https://cdn-icons-png.flaticon.com/512/1828/1828665.png' />
                    <div className='action-item-text'>
                        <h3>Schedule session</h3>
                        <p>Plan a session of this training</p>
                    </div>
                </div>
                <div className='action-item' style={{ borderBottom : '0'}}>
                    <img src='https://cdn-icons-png.flaticon.com/512/1828/1828665.png' />
                    <div className='action-item-text'>
                        <h3>Schedule session</h3>
                        <p>Plan a session of this training</p>
                    </div>
                </div>
            </ActionsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ActionsSC = styled.div`
    background-color : white;
    border-radius : 2px;
    padding : 10px;
    margin : 20px;
    margin-left: 0;
    margin-top : 0;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    display : flex;
    flex-direction : column;

    h1 {
        padding-bottom : 20px;
    }

    .action-item {
        display : flex;
        padding : 10px;
        border-bottom : 1px solid rgba(0,0,0,0.1);
        align-items : center;
        
        img {
            width : 20px;
            height : 20px;
            margin-right : 20px;
        }

        p {
            font-size : 14px;
            font-weight : 300;
        }
    }
`

export default Actions