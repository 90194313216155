import React from 'react';
import styled from 'styled-components';

const ToggleJobsButton = ({ state , setState }) => {

    const rendering = () => {
        return (
            <ToggleJobsButtonSC>
                <button
                    className='button'
                    onClick={() => setState({...state, jobsListToggled : true })}
                >
                    Show Jobs
                </button>
            </ToggleJobsButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ToggleJobsButtonSC = styled.div`
    position : relative; 
    padding : 10px;
    padding-left : 0;
    padding-right : 0;
    margin-right : 10px;

`

export default ToggleJobsButton