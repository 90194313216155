import React from 'react';
import styled from 'styled-components';
import { Route, HashRouter as Router, Routes  } from 'react-router-dom';
import Dashboard from '../Dashboard/Dashboard';
import Sidebar from '../sidebar/SideBar';
import Exercises from '../Exercises/Exercises';
import CourseMaterials from '../CourseMaterials/CourseMaterials';
import Chat from '../Chat/Chat';

const MainContent = () => {

    const rendering = () => {
        return (
            <MainContentSC>
                <Router>
                    <Sidebar />
                    <Routes>
                        <Route exact path="/" element={
                            <Dashboard />
                        } />
                        <Route exact path="/course-materials" element={
                            <CourseMaterials />
                        } />
                        <Route exact path="/exercises" element={
                            <Exercises />
                        } />
                        <Route exact path="/chat" element={
                            <Chat />
                        } />
                    </Routes>
                </Router>
            </MainContentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MainContentSC = styled.div`
    display : flex;
    flex-grow : 1;

    .title {
        padding : 0; 
        margin : 20px; 
        margin-left : 0;
        margin-top : 0;
        font-size : 1rem;
        font-weight : 400;

    }

    .dashboard-container {
        background-color : white;
        display : flex;
        flex-direction : column;
        border-radius : 10px;
        box-shadow : 0 0 10px rgba(0,0,0,0.1);
        padding : 20px;
        margin : 20px;
    }
`

export default MainContent