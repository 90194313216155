import React from 'react';
import styled from 'styled-components';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { IndividualTrainingContext } from './IndividualTraining';
import moment from 'moment';
import { colors } from '../../variables/Colors';

const TrainingHeader = () => {

    const { state, setState, training } = React.useContext(IndividualTrainingContext);
    const { trainingName, trainer, price, location, difficulty, language, format, type, capacity, attendees, startDate, endDate, instanceName } = state.currentInstance;

    const rendering = () => {

        if(training) {
            return (
                <TrainingHeaderSC>
                    <div className='left-panel'>
                        <div 
                            className='thumbnail-image' 
                            style={{ backgroundImage : `url(${training.imageUrl})`}}
                        >
                        </div>
                    </div>

                    <div className='right-panel'>
                        <p className='training-title'>{training.name}</p>
                        {/* <p className='training-description'>{training.description}</p> */}

                        <div className='dates'>
                            {state.currentInstance.dates.map(date => {
                                return (
                                    <div 
                                        className='date-entry'
                                        key={moment(date).format('DD/MM/YYYY')}    
                                    >
                                        <CalendarMonthIcon />
                                        <p>{moment(date).format('DD/MM/YYYY')}</p>
                                    </div>
                                )
                            })}
    
    
                        </div>
    
                    </div>
                </TrainingHeaderSC>
            );
        }


    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingHeaderSC = styled.div`
    padding-top : 0;
    display : flex; 
    background-color : white;
    margin-top : 10px;
    border-radius : 5px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    min-height : 260px;

    .left-panel {
        min-width : 40%;

        &>.thumbnail-image {
            min-width : 100%;
            max-width : 100%;
            height : 100%;
            background-size : cover;
            background-position : center;
            border-radius : 15px

        }
    }


    .right-panel {
        flex-grow : 1;
        padding-top : 15px;

        &>.training-title {
            font-size : 24px;
            padding : 10px;
            padding-bottom : 0;
            padding-top : 0;
            color : ${colors.darkGrey};
        }

        &>.training-description {
            padding : 10px;
            padding-top : 0;
            color : ${colors.midGrey};
        }
    }

    .dates {
        display : flex;
        flex-direction : column;
        gap : 10px;
        padding : 10px;
    }

    .dates .date-entry {
        display : flex;
        align-items : center;
        padding-top : 0;
        padding-left : 0;
        color : ${colors.darkGrey};

        &>svg {
            font-size : 24px;
            margin-right : 5px;
        }
    }
`

export default TrainingHeader