import React from 'react';
import styled from 'styled-components';
import TopBar from './TopBar';
import Grid from './Grid';

const TemplatesGrid = () => {

    const rendering = () => {
        return (
            <TemplatesGridSC>
                <TopBar />
                <Grid />
            </TemplatesGridSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TemplatesGridSC = styled.div`
    display : flex;
    flex-direction : column;
    width : 440px;
    border-right : 1px solid rgba(0,0,0,0.1);

`

export default TemplatesGrid