import { CircularProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const TitleAndSubject = ({ state, setState, subjectLineRef, titleTemplateRef }) => {


    React.useEffect(() => {
        if(state.designToLoad) {
            document.getElementById('title-template').value = state.designToLoad.title;
            document.getElementById('subject-line').value = state.designToLoad.subjectLine;
        }
    }, [state.designToLoad])

    const rendering = () => {

        return (
            <TitleAndSubjectSC>

            {state.saveDesignLoading &&
                <div className='saving'>
                    <p>SAVING</p>
                    <CircularProgress size={15} color='primary'/>
                </div>
            }

                <div className='title-of-template template-bar'>
                    <p className='title-or-subject-label'>title template</p>
                    <input 
                        type='text' 
                        defaultValue={state.designToLoad ? state.designToLoad.title : 'New template...'}
                        ref={titleTemplateRef}
                        id='title-template'
                    />
                </div>
                <div className='subject-of-template template-bar' style={{marginLeft : '15px'}}>
                    <p className='title-or-subject-label'>Subject mail</p>
                    <input 
                        type='text' 
                        defaultValue={state.designToLoad ? state.designToLoad.subjectLine : 'Enter subject...'}
                        ref={subjectLineRef}  
                        id='subject-line'  
                    />
                </div>
            </TitleAndSubjectSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TitleAndSubjectSC = styled.div`
    display : flex;
    flex-grow : 1;
    margin-top : 10px;
    margin-bottom : 10px;
    
    .template-bar {
        display : flex; 
        width : 48%;
        display : flex;
    }

    .title-or-subject-label {
        background-color : #1976D2;
        color : white;
        font-size : 14px;
        padding : 10px;
        border-top-left-radius : 5px;
        border-bottom-left-radius : 5px;
    }

    input {
        background-color : white;
        color : black;
        font-size : 14px;
        padding : 10px;
        flex-grow : 1;
        border : 1px solid lightgrey;
        outline : none;
        border-top-right-radius : 5px;
        border-bottom-right-radius : 5px;

    }
`

export default TitleAndSubject