import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Validate_Website = () => {

    const rendering = () => {
        return (
            <Validate_WebsiteSC>
                <p>Title and description</p>
                <CheckCircleIcon />
            </Validate_WebsiteSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const Validate_WebsiteSC = styled.div`
    padding : 20px;
    border : 1px solid #ccc;
    border-radius : 5px;
    display : flex;
    justify-content : space-between;
    align-items : center;

    svg {
        color : green;
    }

`

export default Validate_Website