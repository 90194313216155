import React from 'react';
import styled from 'styled-components';

const Card = ({
    title,
    value, 
    period,
    growthChart
}) => {

    const rendering = () => {
        const formattedGrowthChart = growthChart > 0 ? `+${growthChart}%` : `${growthChart}%`;

        return (
            <CardSC>
            <div className='card-content'>
                <h2>{title}</h2>
                <h1>{value}</h1>
                <p>{period}</p>
            </div>
            <div className='growth-chart-content'>
                <p>{formattedGrowthChart}</p>
            </div>
            </CardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CardSC = styled.div`
    border : 1px solid lightgrey;
    border-radius : 10px;
    padding : 15px;
    position : relative;

    .card-content {
        display : flex; 
        flex-direction : column;
        padding : 10px;
        min-width : 240px;

        h2 {
            margin : 0; 
            line-height : 0; 
            font-size : 1.1rem;
            color : grey;
            font-weight : 400;
        }

        h1 {
            color : #373737;
            margin : 0;
            font-size : 2.4;
            font-weight : 600;
            margin-top : 10px;
        }

        p {
            margin : 0;
            font-size : 0.7rem;
            color : grey;
        }
    }

    .growth-chart-content {
        position : absolute;
        right : 20px;
        top : 20px;
        font-size : 0.8rem;
        font-weight : 600;
        background-color : rgba(24, 231, 45, 0.42);
        padding : 5px 15px;
        color : #006C04;
        border-radius : 5px;
    }


`

export default Card