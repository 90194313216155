import React from 'react';
import styled from 'styled-components';
import CertificateItem from './CertificateItem';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios'; 
import CertificatesHeaderMenu from './CertificatesHeaderMenu';

const CertificatesList = () => {

    const [state, setState] = React.useState({
        certificates : []
    });

    React.useEffect(() => {
        const getCertificates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificates`)
                setState({
                    ...state,
                    certificates : response.data
                })
            } catch (error) {
                console.log(error)
            } 
        }

        getCertificates()
    },[]);

    const rendering = () => {

        return (
            <CertificatesListSC>
                <CertificatesHeaderMenu />
                    <div className='page-with-licenses'>
                        {state.certificates.length === 0 ?
                            <h1>No certificates found</h1>
                                :
                                <>
                                    <h1>Awarded certificates</h1>
                                    {state.certificates.map((certificate) => {
                                        return (
                                            <CertificateItem key={certificate._id} certificate={certificate} />
                                        );
                                    })}
                                </>
                        }
                    </div>
            </CertificatesListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificatesListSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;
    
    .none-found {
        padding : 15px;
        font-size : 0.8rem;
        font-weight : 400;
    }

    .page-with-licenses {
        display : flex;
        flex-direction : column;
        background-color : white;
        margin : 10px;
        border-radius : 5px;
        flex-grow : 1;
    }

    h1 {
        padding : 15px;
        margin : 0;
        font-size : 1.2rem;
        font-weight : 400;
    }
`

export default CertificatesList