import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import ReactForm from './ReactForm';
import RegisteredConfirmation from './RegisteredConfirmation';

export const SignUpFormContext = React.createContext();

const SignUpForm = () => {

    const trainingId = window.location.hash.split('/')[2];
    const instanceId = window.location.hash.split('/')[3];

    const [state, setState] = React.useState({
        training : null,
        instance : null,
        registeredConfirmation : false
    });

    const [training, setTraining] = React.useState(null);
    const [instance, setInstance] = React.useState(null);

    React.useEffect(() => {

        // const getTraining = async () => {
        //     const response = await Axios.get(`${backEndAdress}/api/trainings/${trainingId}`);
        //     setTraining(response.data);
        // }

        // const getInstance = async () => {
        //     const response = await Axios.get(`${backEndAdress}/api/instances/${instanceId}`);
        //     setInstance(response.data);
        // }
        
        // getTraining();
        // getInstance();
    }, []);

    const rendering = () => {

        return (
            <SignUpFormContext.Provider value={{ 
                state, 
                setState,
                training,
                instance
            }}>
                <SignUpFormSC>

                    <>
                        {state.registeredConfirmation && 
                            <RegisteredConfirmation 
                                state={state}
                                setState={setState}
                            />
                        }
                    </>

                    <ReactForm 
                        instance={instance}
                        state={state}
                        setState={setState}    
                    />
                </SignUpFormSC>
            </SignUpFormContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SignUpFormSC = styled.div`
    position : fixed;
    min-height : 100vh;
    min-width : 100vw;
    top : 0;
    left : 0;
    bottom : 0;
    background-color : #00b3db;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;
    justify-content : center;
    padding-left : 80px;

    @media (max-width: 600px) {
        display : flex;
        flex-direction : column;
        align-items : center;
        justify-content : center;
        padding-left : 0;
    }
`

export default SignUpForm