import React from 'react';
import styled from 'styled-components';


const TextFormInput = ({ children }) => {

    const rendering = () => {
        return (
            <TextFormInputSC>
                {children}
            </TextFormInputSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TextFormInputSC = styled.div`
    min-height: 45px;
    border : 1px solid grey;
    border-radius : 5px;
    padding : 5px;
    display : flex; 
    position : relative; 

    .error-message {
        color : #db1f1f;
        background-color : white;
        padding-left : 10px;
        padding-right : 10px;
        position : absolute; 
        bottom : -7px;
        font-size : 12px;
        left : 15px;
    }

    &>input {
        width :0;
        flex-grow : 1;
        font-size : inherit;
        border : none;
        outline : none;
        font-family : inherit;
        padding-left : 5px;
    }

`

export default TextFormInput