import React from 'react';
import styled from 'styled-components';
import MenuIcon from '@mui/icons-material/Menu';
import { HeigtsPaddingBorderRadius } from '../../variables/HeigtsPaddingBorderRadius';
import { useNavigate } from 'react-router-dom';

const { headerHeight } = HeigtsPaddingBorderRadius;

const MenuIconComponent = ({ appState , setAppState }) => {

    const navigate = useNavigate();

    const rendering = () => {
        return (
            <MenuIconSC>
                <MenuIcon onClick={() => setAppState({...appState, sideBarExpanded : !appState.sideBarExpanded})}/>
                <p onClick={() => navigate('/')}>Training Manager</p>
            </MenuIconSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MenuIconSC = styled.div`
    display : flex;
    height : ${headerHeight};
    align-items : center;
    color : white;
    width : 220px;


    &>svg {
        min-width : ${headerHeight};
        max-width : ${headerHeight};
        min-height : ${headerHeight};
        max-height : ${headerHeight};
        padding : 15px;
        box-sizing : border-box;
    }

    &>p {
        padding-right : 15px;
        &:hover {
            cursor : pointer;
            color : grey;
        }
    }

`

export default MenuIconComponent