import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';

const BasicInput = ({ label, defaultValue, reference, style }) => {

    const rendering = () => {
        return (
            <BasicInputSC>
                <input 
                    type='text' 
                    defaultValue={defaultValue ? defaultValue : ''} 
                    ref={reference}
                    size={1}
                    style={{...style}}
                />
                <div className='label'>{label}</div>
            </BasicInputSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BasicInputSC = styled.div`
    position : relative; 
    font-size : inherit;
    font-family : inherit;
    
    input {
        box-sizing : border-box;
        width : 100%;
        padding : 14px;
        border : 1px solid black;
        border-radius : 5px;
        font-size : inherit;
        font-family : inherit;
    }

    .label {
        position : absolute;
        top : -11px;
        left : 5px; 
        background-color : white;
        padding-left : 14px;
        padding-right : 14px;
        color : ${colors.midGrey};
        font-size : 0.8rem;
        font-weight : 400;
}
`

export default BasicInput