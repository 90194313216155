import React from 'react';
import styled from 'styled-components';
import { EmailScheduleContext } from '../EmailSchedule';
import TemplatesGrid from './templatesGrid';
import SubjectAndPreview from './SubjectAndPreview';
import LoadTemplateScreen from '../loadTemplateScreen/LoadTemplateScreen';
import Preview from './Preview';
import { defaultMergeTags } from '../../../variables/defaultMergeTags';

const IndividualTouchPoint_Content = ({ 
    mailScheduleState, 
    setMailScheduleState, 
    contentRef, 
    currentInstance,
    mailTemplates,
    training,
    trainingLocation,
    trainer
  }) => {

    const setEmailTemplate = (emailTemplate) => {
        setMailScheduleState({...mailScheduleState, mailTemplate : emailTemplate})
    }
 
    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const handleBarsObject ={
        //user 
            first_name: currentInstance ? currentInstance.attendees[0] ? capitalizeFirstLetter(currentInstance.attendees[0].firstName) : defaultMergeTags.first_name : defaultMergeTags.first_name,
            last_name: currentInstance ? currentInstance.attendees[0] ? capitalizeFirstLetter(currentInstance.attendees[0].lastName) : defaultMergeTags.last_name : defaultMergeTags.last_name,
            imageURL : null,

        //Trainer
            trainer_name : trainer ? trainer.firstName : defaultMergeTags.trainer_name,
            trainer_email : trainer ? trainer.email : defaultMergeTags.trainer_email,
            trainer_phone : trainer ? trainer.phone : defaultMergeTags.trainer_phone,
            trainer_description : trainer ? trainer.shortDescription : defaultMergeTags.trainer_description,
            trainer_image : trainer ? trainer.imageUrl : defaultMergeTags.trainer_image, 

        //Training
            training_name : training ? training.name : defaultMergeTags.training_name, 
            dates_concatted : currentInstance ? currentInstance.trainingDates : null,
            short_description : training ? training.description : defaultMergeTags.short_description,
            requirements : 'to be added',

        //Location
            adress : trainingLocation ? trainingLocation.adress : defaultMergeTags.adress,
            instructions : trainingLocation ? trainingLocation.instructions : defaultMergeTags.instructions,
            instructions_image_url : trainingLocation ? trainingLocation.instructionsMapImageUrl : defaultMergeTags.instructions_image_url,

        //Survey 
            survey_link : null
    }

    const rendering = () => {
        return (
            <IndividualTouchPoint_ContentSC> 
                <LoadTemplateScreen  
                    mailTemplates={mailTemplates} 
                    setEmailTemplate={setEmailTemplate}
                    mailTemplate={mailScheduleState.mailTemplate}
                    handleBarsObject={handleBarsObject}
                /> 
            </IndividualTouchPoint_ContentSC>
        );
    }

    return(
        <React.Fragment> 
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTouchPoint_ContentSC = styled.div`
    background-color : white;   
    display : flex;
    flex-grow : 1;
    overflow-y : scroll;
`

export default IndividualTouchPoint_Content