import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';

const CertificatesHeaderMenu = () => {

    const navigate = useNavigate(); 
    const currentMenu = window.location.hash.split('/')[2];

    const rendering = () => {
        return (
            <CertificatesHeaderMenuSC>
                <p  
                    onClick={() => navigate('/certificates/awarded')} 
                    className={currentMenu==='awarded' ? 'active' : ''}
                >
                    Awarded certificates
                </p>
                
                <p 
                    onClick={() => navigate('/certificates/overview')} 
                    className={currentMenu==='overview' ? 'active' : ''}
                >
                    Certificate templates
                </p>
            </CertificatesHeaderMenuSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificatesHeaderMenuSC = styled.div`
    padding : 15px;
    background-color : white;
    border-radius : 5px;
    margin : 10px;
    margin-bottom : 0;
    display : flex;

    &>p {
        padding-right : 15px;
        &:hover {
            cursor : pointer;
        }
    }

    .active {
        color : black;
        font-weight : 600;
    }
`

export default CertificatesHeaderMenu