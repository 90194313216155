import React, { useEffect } from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const CustomInputField = ({ property }) => {

    const [editMode, setEditMode] = React.useState(false);

    const handleEnter = (e) => {
        if(e.key === 'Enter') {
            console.log('enter G')
            setEditMode(false)
        }
    }

    useEffect(() => {
        if(editMode) {
            document.querySelector('.input-value').focus()
            document.querySelector('.input-value').select()
        }
    }, [editMode])

    const rendering = () => {
        if(editMode) {
            return (
                <EditModeSC >
                    <input type='text' className='input-value' defaultValue={property.value} size={1} onKeyDown={(e) => handleEnter(e)}/>
                    <div className='edit-buttons'>
                        <CheckIcon onClick={() => setEditMode(!editMode)}/>
                        <CloseIcon onClick={() => setEditMode(!editMode)}/>
                    </div>
                </EditModeSC>
            );
        } else {
            return (
                <NonEditModeSC onClick={() => setEditMode(!editMode)}>
                    <div className='text-values'>
                        <p className='label'>{property.name}</p>
                        <p className='value' >{property.value}</p>
                    </div>
                </NonEditModeSC>
            );
        }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const NonEditModeSC = styled.div`
    border : 1px solid ${colors.lightGrey};
    border-radius : 5px;
    display : flex;
    flex-direction : column;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    min-height : 80px;
    max-height : 80px;
    box-sizing : border-box;
    position : relative;
    max-width : 100%;
    justify-content : center;
    padding-left : 20px;

    &:hover {
        cursor : pointer; 
        background-color : rgba(0,0,0,0.05);
    }

    .text-values {
        padding : 10px;
        display : flex;
        flex-direction : column;
        justify-content : center;
    }


    &:hover {
        cursor : pointer; 
    }

    .label {
        font-size : 18px;
        padding : 0;
        margin : 0;
    }

    .value {
        font-size : 14px;
        color : ${colors.lightGrey};
        margin : 0;
    }

    .edit-mode {
        display : flex;
        max-width : 100%;
        flex : 1;

        &>input {
            font-size : 18px;
            font-family : inherit;
            border : 0;
            outline : 0;
            padding : 10px;
            padding-left : 20px;
        }
    }

    .input-value {
        min-height : 50px;
        max-height : 50px;
        border : 0;
        outline : 0;
        font-size : 18px;
        font-family : inherit;
        background-color : red;
        flex-grow : 1;


    }

    .edit-buttons {
        display : flex;
        max-width : 45px;
        min-width : 45px;
        align-items : center;
        padding : 10px;

        &>svg {
            font-size : 20px;
            color : ${colors.darkGrey};
        }
    }   


`

const EditModeSC = styled.div`
    border : 1px solid ${colors.lightGrey};
    border-radius : 5px;
    display : flex;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    min-height : 80px;
    max-height : 80px;
    box-sizing : border-box;
    position : relative;
    max-width : 100%;
    justify-content : space-between;
    position : relative;
    padding : 10px;

    &>input {
        font-size : 18px;
        font-family : inherit;
        border : 0;
        outline : 0;
        padding-left : 15px;
        flex-grow : 1;
    }

    .edit-buttons {
        display : flex;
        max-width : 45px;
        min-width : 45px;
        align-items : center;


        &>svg {
            font-size : 20px;
            color : ${colors.darkGrey};
        }
    }
`

export default CustomInputField