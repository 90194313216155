import React from 'react';
import styled from 'styled-components';

const FreeText = ({textItems}) => {
    const rendering = () => {
        return (
            <FreeTextSC>
                {textItems.map((item, index) => {
                    if(index !== 0) {
                        return (
                            <p>{item[0]}</p>
                    )}
                })}
            </FreeTextSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FreeTextSC = styled.div`

    p {
        padding : 15px;   
        &:nth-child(odd) {
            background-color : #f2f9ff;
        }
    }
    
`

export default FreeText