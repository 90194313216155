import React from 'react';
import styled from 'styled-components';
import Validate_TitleAndDescription from './Validate_TitleAndDescription copy';
import Validate_Website from './Validate_Website';
import Validate_MailSchedule from './Validate_MailSchedule';
import Validate_Feedback from './Validate_Feedback';
import Validate_Certificate from './Validate_Certificate';
import Modal from '../../../GeneralComponents/Modal';
import ErrorsList from './ErrorsList';

const Review = ({ formValues, trigger, errors, state, visible, activeItem, imageURL, trainingToEditLocal, reviewRef }) => {

    const imageToUse = imageURL ? imageURL : 'https://previews.123rf.com/images/sodis/sodis1804/sodis180400047/100084305-business-training-flat-design-vector-illustration.jpg';
    const [errorsModal, setErrorsModal] = React.useState(true);

    React.useEffect(() => {
        const errorsArray = Object.keys(errors);
        if(errorsArray.length>0 && activeItem === 5){
            setErrorsModal(true);
        }else{
            setErrorsModal(false);
        }
    }, [errors.title, errors.description]);


    const rendering = () => { 

        return (
            <ReviewSC ref={reviewRef} id='section7' className='content-section'>
                <div className='left-column'>
                    <Validate_TitleAndDescription formValues={formValues} />
                    <Validate_MailSchedule state={state} trainingToEditLocal={trainingToEditLocal}/>
                    <div className='bottom-row'>
                        <Validate_Feedback state={state} />
                        <Validate_Certificate state={state} />
                    </div>
                </div>

                <div className='right-column'>
                    <img src={imageToUse} alt='training' />
                    <ErrorsList errors={errors} />
                </div>

            </ReviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ReviewSC = styled.div`
    padding : 25px;
    background-color : white;
    border-radius : 10px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    margin : 20px;
    margin-bottom : 0;
    display : flex;

    .left-column {
        max-width : 55%;
        min-width  : 55%;
    }
    .right-column {
        flex : 1; 

        &>img {
            max-width : 45%;
            max-height : 45%;
            object-fit : cover;
            border-radius : 10px;
        }
    }
    .bottom-row {
        display : flex;
        gap : 20px;
    }
`

export default Review