import React from 'react';
import styled from 'styled-components';

const SubjectInput = ({ subjectRef, individualTouchPointState }) => {

    const rendering = () => {
        return (
            <SubjectInputSC>
                <p>Subject</p>

                <input 
                    type="text" 
                    placeholder="Subject" 
                    ref={subjectRef} 
                    defaultValue={individualTouchPointState.subject}
                />
            </SubjectInputSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SubjectInputSC = styled.div`
    display : flex;

    p {
        font-size : 1.2rem;
        color : white;
        align-items : center;
        display : flex;
        justify-content : center;
        padding : 0 15px 0 15px;
        background-color : #fcba03;

    }

    input {
        background-color : #f4f4f4;
        padding : 10px;
        border : none;
        border-bottom : 1px solid #ccc;
        font-size : 1.2rem;
        flex-grow : 1;
        outline : none;
        
    }
`

export default SubjectInput