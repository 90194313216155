import React from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import AddImage from './AddImage';
import ImageUpload from '../../../GeneralComponents/ImageUpload';
 
const BasicInfo = ({ register, errors, trigger, imageURL, setImageURL, file, setFile, visible, trainingToEdit, generalInfoRef }) => {

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    const rendering = () => {

        return (
            <BasicInfoSC ref={generalInfoRef} className='content-section' id='section1'>
                <h1 className='content-section-title'>General info</h1>
                <div className='basic-info-content'>
                    <div className='form-group'>
                        <TextField  
                            label='Title'
                            type='text' 
                            defaultValue={trainingToEdit.name}
                            {...register('title', {
                                required : {
                                    value : true,
                                    message : 'title is requried'
                                },
                                minLength : {
                                    value : 5,
                                    message : 'This field must be at least 5 characters'
                                }
                            })}
                            error={!!errors.title}
                            helperText={errors.title?.message}
                            sx={{
                                marginBottom: '20px'
                            }}
                        />
                        <div className='filler'>
                        <TextField  
                            defaultValue={trainingToEdit.description}
                            label='Description'
                            multiline
                            size={'small'}

                            {...register('description', {
                                required : {
                                    value : true,
                                    message : 'description is requried'
                                },
                                minLength : {
                                    value : 5,
                                    message : 'This field must be at least 5 characters'
                                }
                            })}
                            sx={{
                                width: '100%',
                                height: '100%', // Ensure the text field takes the full height of the parent div
                                '& .MuiInputBase-root': {
                                height: '100%', // Ensures input area fills the div,
                                alignItems: 'flex-start',
                                },
                            }}
                            error={!!errors.description}
                            helperText={errors.description?.message}
                        />
                        </div>
                    </div>
                    <div className='image-upload-container'>
                        <ImageUpload
                            imageURL={imageURL}
                            setImageURL={setImageURL}
                            file={file}
                            setFile={setFile}
                            id={'thumbnail-image-upload'}
                            description={'Upload thumbnail image'}
                        />
                    </div>
                </div>
            </BasicInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BasicInfoSC = styled.div`
    flex : 1;
    display : flex;
    min-width : 70%;
    flex-direction : column;    
    padding : 30px;
    box-sizing : border-box;
    background-color : white;
    border-radius : 10px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    margin : 0px;
    margin-right : 20px;
    margin-bottom : 0;

    .basic-info-content {
        display : flex; 
    }

    .form-group {
        flex : 1;
        display : flex;
        flex-direction : column;
        padding-right : 20px;

        .filler {
            flex : 1;
        }
    }

    .image-upload-container {
        min-width : 350px;
        max-width : 350px;
        max-height : 250px;
        min-height : 250px;
        display : flex;
        flex-direction : column;
        align-items : center;
    }
`

export default BasicInfo