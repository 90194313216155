export const defaultMergeTags = {

    //user 
    first_name: 'John',
    last_name: 'Doe',
    imageURL : null,

    //Trainer
    trainer_name : 'John Doe',
    trainer_email : 'trainer@holli.com',
    trainer_phone : '03667212',
    trainer_description : 'Hank is a ficitional trainer. This description should be replaced with the actual trainer description',
    trainer_image : 'default trainer image.jpg',

    //Training
    training_name : 'Test training',
    dates_concatted : [],
    short_description : 'This is a test training description, this should be replaced with the actual training description',
    requirements : 'Please bring your own pc',

    //Location
    adress : 'Test street 123',
    instructions : 'Test street is a test location, this should be replaced with the actual location instructions',
    instructions_image_id : 'default maps image.png',

//Survey 
    survey_link : null
}