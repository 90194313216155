import React from 'react';
import styled from 'styled-components';
import MultipleDatesPicker from '@ambiot/material-ui-multiple-dates-picker'
import { Button } from '@mui/material';
import moment from 'moment';
import { AddInstanceContext } from '../AddInstance';

const DateComponentTest = () => {

    const { setDates } = React.useContext(AddInstanceContext);
    const [open, setOpen] = React.useState(false)

    const setDatesFunction = (dates) => {
        setDates(dates);
        setOpen(false);
    }
 
    const rendering = () => {
        return (
            <>
                <div>
                <Button 
                    variant='contained'
                    onClick={() => setOpen(!open)}
                    style={{marginLeft : '15px', marginTop : '0px'}}
                >
                        Select Dates
                </Button>
                <MultipleDatesPicker
                    open={open}
                    selectedDates={[]}
                    onCancel={() => setOpen(false)}
                    onSubmit={dates => setDatesFunction(dates)}
                />
                </div>
            </>

          )
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DateComponentTestSC = styled.div`
    
`

export default DateComponentTest