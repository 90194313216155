import React from 'react'; 
import styled from 'styled-components';
import { EmailScheduleContext } from '../EmailSchedule';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import { IndividualTrainingContext } from '../../IndividualTraining/IndividualTraining';
import moment from 'moment';
import { CircularProgress } from '@mui/material';

const Preview = ({ mailTemplate, handleBarsObject }) => {

    const [currentFile, setCurrentFile] = React.useState(null);
    const [currentFileUrl, setCurrentFileUrl] = React.useState(null); 
    const [loadingTemplate, setLoadingTemplate] = React.useState(true);
    const [templateNotFound, setTemplateNotFound] = React.useState(false);

    // const { state : individualTrainingState, training } = React.useContext(IndividualTrainingContext);
    // const { currentInstance : instance } = individualTrainingState;

    const getTemplateFile = async () => {
        setLoadingTemplate(true);
        try {
            const response = await Axios.get(`${backEndAdress}/api/mail-templates/${mailTemplate._id}`)
            const url = response.data.signedUrl;
            setCurrentFileUrl(url);
        } catch (error) {
            console.log('now setting the not found value to true');
        }
    }

    React.useEffect(() =>  {
        if(mailTemplate) {
            getTemplateFile();
        }
    },[mailTemplate])

    React.useEffect(() => {
        const getFile = async () => {
            try {
                const response = await Axios.post(`http://localhost:8080/api/mail-templates/get-html-file/${mailTemplate._id}` , { handleBarVariables : handleBarsObject });
                const template = response.data.toString();
                setCurrentFile(template);
                setLoadingTemplate(false);

            } catch (error) {
                console.error(error);
            }
        }

        getFile();

    },[currentFileUrl]);

    const rendering = () => {
        return (
            <PreviewSC>
                {mailTemplate &&

                    loadingTemplate ?
                        <div className='loading-template'>
                            <CircularProgress />
                        </div>
                    
                        :

                        templateNotFound ?

                            <div className='loading-template'>
                                <h1>Template not found</h1>
                            </div>

                            :
                            
                            <div className='mail-preview'>
                                {mailTemplate && <div className='subject-of-template template-bar' style={{marginLeft : '15px'}}>
                                    <p className='title-or-subject-label'>Subject mail</p>
                                    <p className='value-of-subject' id='value-of-subject'>{mailTemplate ? mailTemplate.subjectLine : 'no subject'}</p>
                                </div>}

                                <div 
                                    className='test' 
                                    dangerouslySetInnerHTML={{__html : currentFile}}>
                                </div>
                            </div>
                }
            </PreviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PreviewSC = styled.div`
    flex-grow : 1;
    display : flex;
    overflow-y : scroll;

    .loading-template {
        background-color : white;
        display : flex;
        justify-content : center;
        align-items : center;
        flex-grow : 1;
    }

    .test {
        flex-grow : 1;

        &>table {
            background-image: radial-gradient(#e8e8e8 1px, transparent 1px);

        }
    }

    .mail-preview {
        display : flex;
        flex-direction : column;
        background-color : white;
        border-radius : 5px;
        box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.2);

        .subject-of-template {
            display : flex;
            margin : 15px;
        }

        .title-or-subject-label {
            color : grey;
            font-size : 14px;
            padding : 10px;
            border-top-left-radius : 5px;
            border-bottom-left-radius : 5px;
        }

        .value-of-subject {
            background-color : white;
            color : black;
            font-size : 14px;
            padding : 10px;
            flex-grow : 1;
            outline : none;
            border-top-right-radius : 5px;
            border-bottom-right-radius : 5px;
        }
    }
`

export default Preview