import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';


const RescheduleJobsButton = ({ mailJobs, scheduledJobIds, setLoading, setMailJobs}) => {

    const errorJobs = mailJobs.filter(job => new Date(job.sendAt) >= new Date() && !scheduledJobIds.includes(job.mailJobId));

    const rescheduleJobs = async () => {
        try {
            setLoading(true)
            const response = await Axios.post(`${backEndAdress}/api/mail-jobs/reschedule-jobs`, errorJobs);
            setMailJobs(response.data); 
            setLoading(false); 
        } catch (error) {
            console.log('error : ', error);
        }
    }



    const rendering = () => {
        return (
            <RescheduleJobsButtonSC>
                <button
                    onClick={() => rescheduleJobs()}
                >
                    Reschedule Jobs
                </button>
            </RescheduleJobsButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RescheduleJobsButtonSC = styled.div`
    
`

export default RescheduleJobsButton