import React from 'react';
import styled from 'styled-components';
import MailSchedule from '../../MailScheduleComponent/MailSchedule';

const TrainingMailSchedule = ({ 
    state, 
    setState, 
    mailTemplates, 
    touchPointsForTraining,
    visible,
    trainingToEditLocal,    
    setTrainingToEditLocal,
    mailScheduleRef
}) => {

    const rendering = () => {
        return (
            <TrainingMailScheduleSC ref={mailScheduleRef}>
                <h1 className='content-section-title'>Mail schedule</h1>
                <MailSchedule 
                    state={state}
                    setState={setState}
                    mailTemplates={mailTemplates}
                    defaultTouchPoints={touchPointsForTraining}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                />
            </TrainingMailScheduleSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingMailScheduleSC = styled.div`
    flex-grow :1;
    overflow-x : scroll;
    display : flex;
    flex-direction : column;
    min-height : 550px;
    background-color : white;
    border-radius : 10px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    margin : 20px;
    padding : 25px;
    margin-bottom : 0;
`

export default TrainingMailSchedule