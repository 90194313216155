import React from 'react';
import styled from 'styled-components';

const QuestionWrapper = ({ children }) => {

    const rendering = () => {
        return (
            <QuestionWrapperSC>
                {children}
            </QuestionWrapperSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const QuestionWrapperSC = styled.div`
    background-color : white;
    border-radius : 10px;
    margin-top : 20px;
    padding : 40px;
    box-shadow : 0px 0px 10px rgba(0,0,0,0.1);
`

export default QuestionWrapper