import React from 'react';
import styled from 'styled-components';
import CustomDropDown from '../../GeneralComponents/CustomDropDown';

const TestMailSelectors = ({ allTrainings, allInstances, setCurrentTraining, trainingRef, setCurrentInstance}) => {

    const rendering = () => {
        return (
            <TestMailSelectorsSC>
                <CustomDropDown 
                    onChangeFunction={(value) => setCurrentTraining(value)}
                    options={allTrainings}
                    valuesToShow={[
                        {
                            name : 'imageUrl',
                            type : 'image'
                        },{
                            name : 'name',
                            type : 'text'
                        }
                    ]}
                    valueInBox='name'
                    inputRef={trainingRef}
                    label='Training'
                />
                <CustomDropDown 
                    onChangeFunction={(value) => setCurrentInstance(value)}
                    options={allInstances}
                    valuesToShow={[
                        {
                            name : 'instanceName',
                            type : 'text'
                        },{
                            name : 'startDate',
                            type : 'text'
                        }
                    ]}
                    valueInBox='instanceName'
                    inputRef={trainingRef}
                    label='Instance'
                />
            </TestMailSelectorsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TestMailSelectorsSC = styled.div`
    
`

export default TestMailSelectors