import React from 'react';
import styled from 'styled-components';
import { EmailScheduleContext } from './EmailSchedule';
import { Button } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const EmailTouchPointsOverview = () => {

    const { state, setState } = React.useContext(EmailScheduleContext);
    const { touchPoints } = state;

    const addTouchPoint = () => {
        const newTouchPoint = {
            touchPointId : uuidv4(),
            name : '',
            type : null, 
            trigger : null
        }

        setState({
            ...state,
            individualTouchPoint : newTouchPoint
        })
    }

    const rendering = () => {
        return (
            <EmailTouchPointsOverviewSC>
                {/* {touchPoints.map((touchPoint, index) => {
                    return (
                        <div className='touchpoint' 
                            key={touchPoint._id}
                            onClick={() => {
                                setState({
                                    ...state,
                                    individualTouchPoint : touchPoint
                                })
                            }}
                        >
                            <div className='top-row'>
                                <h1 className='touchpoint-title'>{touchPoint.name}</h1>
                                <p className='trigger-name'>On sign-up</p>
                            </div>
                            <div className='bottom-row'>
                                <div className='mail-icon'>
                                    <AttachEmailIcon />
                                </div>
                                <p className='template-name'>Sign-up confirmed.html</p>
                            </div>
                        </div>
                    );
                })} */}
                {/* <div className='add-touchpoint' onClick={() => addTouchPoint()}>
                    <p>add touch point</p>
                    <AddCircleIcon />
                </div> */}

            </EmailTouchPointsOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const EmailTouchPointsOverviewSC = styled.div`
    display : flex;
    justify-content : center;
    gap : 20px;

    .touchpoint {
        padding : 25px;
        margin : 10px;
        display : flex;
        justify-content : space-between;
        flex-direction : column;
        align-items : center;
        position : relative;
        background-color : white;
        border-radius : 20px;
        max-width : 150px;
        min-height : 220px;
        min-width : 150px;
        box-shadow : 0 0 2px rgba(0,0,0,0.1);

        &:hover {
            cursor : pointer;
            background-color : #f8f8f8;
        }
    }

    .top-row {
        display : flex;
        flex-direction : column;
        align-items : center;

    }

    .touchpoint-title {
        margin : 5px 0;
        font-weight : 500;
        font-size : 1.3rem;
        font-weight : 400;
        text-align : center;
        line-height : 1.1;
    }

    .trigger-name {
        font-size : 0.65rem;
        color : #666;
    }

    .bottom-row {
        display : flex;
        flex-direction : column;
        align-items : center;
        margin-top : 20px;
        justify-content : center;
        min-height : 50%;
        max-height : 50%;
        aspect-ratio : 1;
    }

    .mail-icon {
        min-height : 75px;
        max-height : 75px;
        background-color : #e8e8e8;
        aspect-ratio : 1;
        display : flex;
        justify-content : center;
        align-items : center;
        border-radius : 10px;
        margin-bottom : 10px;
        & svg {
            color : white;
            font-size : 2.5rem;
        }
    }

    .template-name {
        font-size : 0.7rem;
        color : #666;
    }

    .add-touchpoint {  
        padding : 25px;
        margin : 10px;
        display : flex;
        flex-direction : column;
        align-items : center;
        justify-content : center;
        position : relative;
        background-color : white;
        border-radius : 20px;
        max-width : 100px;
        min-height : 220px;
        min-width : 100px;
        box-shadow : 0 0 2px rgba(0,0,0,0.1);

        p {
            font-size : 0.8rem;
            color : #666;
            margin-bottom : 10px;
        }

        & svg {
            font-size : 3rem;
            color : #666;
        }

        &:hover {
            cursor : pointer;
            background-color : #f8f8f8;
        }
    }

`

export default EmailTouchPointsOverview