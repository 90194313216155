import Axios from 'axios';
import React from 'react';
import styled from 'styled-components';
import { backEndAdress } from '../../variables/back-endAdress';

const MailPreviewerDiv = ({ template, handleBarVariables }) => {

    const [currentFile, setCurrentFile] = React.useState(null);
    const [currentFileUrl, setCurrentFileUrl] = React.useState(null);

    //Get the s3 url when the template is selected
    React.useEffect(() =>  {
        const getTemplateFile = async () => {
            if(template) {
                try {
                    const response = await Axios.get(`${backEndAdress}/api/mail-templates/${template._id}`)
                    const url = response.data.signedUrl;
                    setCurrentFileUrl(url);
                } catch (error) {
                    console.log(error);
                }
            }
        }
    
        getTemplateFile();
    },[template])

    //Fetch the html and set the file to the state (run on selecting template or changing handlebar variables)
    React.useEffect(() => {

        const mailTempalteId = template.mailTemplateId; 

        const getFile = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/mail-templates/get-html-file/${mailTempalteId}`, { handleBarVariables });
                setCurrentFile(response.data);

            } catch (error) {
                console.error(error);
            }
        }

        if(currentFileUrl) {
            getFile();
        }


    },[currentFileUrl, handleBarVariables]);

    const rendering = () => {

        return (
            <MailPreviewerDivSC>
                <div className='mail-preview-container'>
                    <iframe 
                        srcDoc={currentFile} 
                        style={{ width : '605px', height : '95%', marginTop : '2.5%', border : 0}}></iframe>
                </div>
            </MailPreviewerDivSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailPreviewerDivSC = styled.div`
    flex-grow : 1;
    display : flex;
    justify-content : center;

    .mail-preview-container {
        max-width : 600px;
    }
`

export default MailPreviewerDiv