import React from 'react';
import styled from 'styled-components';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios'; 
import { Button } from '@mui/material';
import { AppContext } from '../../App';
import LoadingSpinner from '../GeneralComponents/LoadingSpinner';
import IndividualLocation from './individualLocation2/IndividualLocation'

export const LocationsContext = React.createContext();

const LocationsGrid = () => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;

    const routeDescriptionImageRef = React.useRef();
    const thumbnailImageRef = React.useRef();
    
    const [inputProperties, setInputProperties] = React.useState({
        title : '',
        adress : '',
        instructions : '',
    });

    const [locationsState, setLocationsState] = React.useState({
        individualLocation : null,
        locations : [],
        loading : true, 
        thumbnailFile : null,
        routeDescriptionFile : null
    });

    React.useLayoutEffect(() => {
        if(locationsState.individualLocation) {

            const { title, adress, instructions, place } = locationsState.individualLocation;

            setInputProperties({
                ...inputProperties,
                title, 
                adress,
                instructions,
                place
            });
        }
    }, [locationsState.individualLocation]);


    React.useEffect(() => {
        const getLocations = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/locations`);
                const locations = response.data;
                setLocationsState({
                    ...locationsState,
                    locations,
                    loading : false
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        getLocations();
    }, []);

    const openModalNewLocation = () => {
        setLocationsState({
            ...locationsState,
            individualLocation : {
                title : '',
                adress : '',
                imageId : '',
                instructions : '',
                place : ''
            }
        });
    }

    const saveLocation = () => {
        const { title, adress, instructions, place  } = inputProperties;

        setLocationsState({
            ...locationsState,
            loading : true
        });

        const newLocation = {
            locationId : locationsState.individualLocation._id ? locationsState.individualLocation._id : '',
            _id : locationsState.individualLocation._id,
            thumbnailImageId : locationsState.individualLocation.thumbnailImageId,
            instructionsMapImageId : locationsState.individualLocation.instructionsMapImageId,
            title,
            adress,
            instructions,
            tenant, 
            place
        }

        const formData = new FormData();
        formData.append('thumbnailFile', locationsState.thumbnailFile);
        formData.append('routeDescriptionFile', locationsState.routeDescriptionFile);
        formData.append('locationInfo', JSON.stringify(newLocation));

        const saveLocationFunction = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/locations/${tenant}`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                setLocationsState({
                    ...locationsState,
                    individualLocation : null,
                    loading : false,
                    locations : response.data
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveLocationFunction();
    }

    const setThumbnailFile = (file) => {
        setLocationsState({
            ...locationsState,
            thumbnailFile : file
        });
    }

    const setRouteDescriptionFile = (file) => {
        setLocationsState({
            ...locationsState,
            routeDescriptionFile : file
        });
    }

    const rendering = () => {
        if(locationsState.loading) {
            return <LoadingSpinner />
        }
        return (
            <LocationsContext.Provider value={{
                locationsState,
                setLocationsState,
                openModalNewLocation,
                routeDescriptionImageRef,
                saveLocation,
                setThumbnailFile,
                setRouteDescriptionFile,
                inputProperties,
                setInputProperties,
            }}>
                <LocationsGridSC>

                    {locationsState.individualLocation && 
                        <IndividualLocation 
                            location={locationsState.individualLocation}
                            locationsState={locationsState}
                            setLocationsState={setLocationsState}    
                        />
                    }

                    <div className='locations-overview'>
                        {locationsState.locations.map((location, index) => {
                            return (
                                <div 
                                    key={index} 
                                    className='location'
                                    onClick={() => setLocationsState({ ...locationsState, individualLocation : location })}    
                                >
                                    <div className='background-image' style={{backgroundImage : `url(${location.thumbnailImageUrl})`}}></div>
                                    <h3>{location.title}</h3>
                                </div>
                            )
                        })}
                    </div>
                    <Button
                        className='button-bottom-right' 
                        variant='contained' 
                        color='primary'
                        style={{ position : 'absolute', bottom : '20px', right : '20px' }}
                        onClick={() => openModalNewLocation()}
                    >   
                        Add Location
                    </Button>
                </LocationsGridSC>
            </LocationsContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LocationsGridSC = styled.div`
    flex-grow : 1;
    min-height : 100%;
    position : relative;

    .locations-overview {
        display : grid;
        grid-template-columns : repeat(auto-fill, minmax(150px, 1fr));
        grid-gap : 20px;
        padding : 20px;
        position : relative;
    }

    .location {
        background-color : white;
        min-height : 220px;
        align-items : center;
        display : flex;
        flex-direction : column;
        justify-content : space-between;
        border-radius : 15px;
        box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
        padding : 20px;
        box-sizing : border-box;
        padding-bottom : 10px;

        &:hover {
            box-shadow : 0 0 10px 0 rgba(0,0,0,0.2);
            background-color : #f9f9f9;
            cursor : pointer;
        }

        h3 {

            display: -webkit-box;        /* Use a flexible box layout */
            -webkit-box-orient: vertical; /* Make the box behave vertically */
            -webkit-line-clamp: 2;        /* Limit to 2 lines */
            overflow: hidden;            /* Hide overflowed text */
            text-overflow: ellipsis;     /* Add ellipsis at the end */
            max-width : 80%;
            font-weight : 500;
            font-size : 1.2rem;
            text-align : center;
            min-height : 48px;
            max-height : 48px;
        }
    }

    .background-image {
        min-height : 100px;
        min-width : 100px;
        max-height : 100px;
        max-width : 100px;
        background-size : cover;
        background-position : center;
        border-radius : 100%;
    }
`

export default LocationsGrid