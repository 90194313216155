import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import IndividualLocationHeader from './IndividualLocationHeader';
import ImageUpload from '../../GeneralComponents/ImageUpload';
import { useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { AppContext } from '../../../App';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import { useAuth0 } from '@auth0/auth0-react';

const IndividualLocation = ({ locationsState, setLocationsState }) => {
    const { user } = useAuth0();
    const form = useForm();

    const { register, handleSubmit, watch, trigger, control, formState } = form;
    const { errors } = formState;
    const { appState } = React.useContext(AppContext);
    const { tenant  } = appState;

    const formRef = React.useRef(null);

    const [locationThumbnail, setLocationThumbnail] = React.useState(null);
    const [locationImageUrl, setLocationImageUrl] = React.useState(null);
    const [descriptionImage, setDescriptionImage] = React.useState(null);
    const [descriptionImageUrl, setDescriptionImageUrl] = React.useState(null);     

    React.useLayoutEffect(() => {
        if(locationsState.individualLocation) {
            console.log('location staet individual location', locationsState.individualLocation);
            const { thumbnailImageUrl, instructionsMapImageUrl } = locationsState.individualLocation;
            setLocationImageUrl(thumbnailImageUrl);
            setDescriptionImageUrl(instructionsMapImageUrl);
        }
    }, [locationsState.individualLocation]);
    
    // Save location 
    const onSubmit = (data) => {
        const { title, adress, description, place } = data;

        setLocationsState({
            ...locationsState,
            loading : true
        });

        const newLocation = {
            organizationId : user.org_id,
            locationId : locationsState.individualLocation._id ? locationsState.individualLocation._id : '',
            _id : locationsState.individualLocation._id,
            thumbnailImageId : locationsState.individualLocation.thumbnailImageId,
            instructionsMapImageId : locationsState.individualLocation.instructionsMapImageId,           
            place,
            title,
            adress,
            instructions : description,
            tenant,
            hasThumbnailUrl : locationImageUrl ? true : false,
            hasInstructionsMapImageUrl : descriptionImageUrl ? true : false
        }; 

        const formData = new FormData();
        formData.append('thumbnailFile', locationThumbnail);
        formData.append('routeDescriptionFile', descriptionImage);
        formData.append('locationInfo', JSON.stringify(newLocation));

        const saveLocationFunction = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/locations`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                setLocationsState({
                    ...locationsState,
                    individualLocation : null,
                    loading : false,
                    locations : response.data
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveLocationFunction();
    }

    // Delete location
    const deleteLocation = async () => {
        setLocationsState({
            ...locationsState,
            loading : true
        });

        const deleteLocationFunction = async () => {
            try {
                const response = await Axios.delete(`${backEndAdress}/api/locations/${locationsState.individualLocation._id}`);
                setLocationsState({
                    ...locationsState,
                    individualLocation : null,
                    loading : false,
                    locations : response.data
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        deleteLocationFunction();
    }

    const rendering = () => {
        return (
            <Modal>
                <form 
                    onSubmit={handleSubmit(onSubmit)} 
                    noValidate
                    ref={formRef} 
                >
                    <IndividualLocationSC>
                        <IndividualLocationHeader 
                            formRef={formRef}
                            deleteLocation={deleteLocation}
                        />
                        <div className='main-content'>
                            <ImageUpload 
                                file={locationThumbnail}
                                setFile={setLocationThumbnail}
                                imageURL={locationImageUrl}
                                setImageURL={setLocationImageUrl}
                                id={'location-thumbnail'}
                                description={'Thumbnail image for location'}
                            />
                            <div className='input-fields-title-and-adress'>
                                <TextField  
                                    label='Title'
                                    type='text' 
                                    defaultValue={locationsState.individualLocation.title}
                                    {...register('title', {
                                        required : {
                                            value : true,
                                            message : 'title is requried'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : 'This field must be at least 3 characters'
                                        }
                                    })}
                                    sx={{
                                        width: '100%',

                                    }}
                                    error={!!errors.title}
                                    helperText={errors.title?.message}
                                />
                                <TextField  
                                    label='Adress'
                                    type='adress' 
                                    defaultValue={locationsState.individualLocation.adress}
                                    {...register('adress', {
                                        required : {
                                            value : true,
                                            message : 'adress is requried'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : 'This field must be at least 3 characters'
                                        }
                                    })}
                                    sx={{
                                        width: '100%',
                                        marginTop: '20px'
                                    }}
                                    error={!!errors.adress}
                                    helperText={errors.adress?.message}
                                />
                                <TextField  
                                    label='Place'
                                    type='place' 
                                    defaultValue={locationsState.individualLocation.place}
                                    {...register('place', {
                                        required : {
                                            value : true,
                                            message : 'place is requried'
                                        },
                                        minLength : {
                                            value : 3,
                                            message : 'This field must be at least 3 characters'
                                        }
                                    })}
                                    sx={{
                                        width: '100%',
                                        marginTop: '20px'
                                    }}
                                    error={!!errors.place}
                                    helperText={errors.place?.message}
                                />
                            </div>

                            <ImageUpload 
                                file={descriptionImage}
                                setFile={setDescriptionImage}
                                imageURL={descriptionImageUrl}
                                setImageURL={setDescriptionImageUrl}
                                id={'description-thumbnail'}
                                description={'Google maps screenshot with description'}
                            />
                            <div className='input-field-description'>
                                <TextField  
                                    label='Instructions on how to get to location'
                                    multiline
                                    type='text' 
                                    {...register('description', {
                                        required : {
                                            value : true,
                                            message : 'description is requried'
                                        },
                                        minLength : {
                                            value : 5,
                                            message : 'This field must be at least 5 characters'
                                        }
                                    })}
                                    sx={{
                                        width: '100%',
                                        height: '100%', // Ensure the text field takes the full height of the parent div
                                        '& .MuiInputBase-root': {
                                        height: '100%', // Ensures input area fills the div,
                                        alignItems: 'flex-start',
                                        },
                                    }}
                                    error={!!errors.description}
                                    helperText={errors.description?.message}
                                    defaultValue={locationsState.individualLocation.instructions}
                                />
                            </div>
                        </div>
                    </IndividualLocationSC>
                </form>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualLocationSC = styled.div`
    min-height : 550px;
    max-height : 550px; 
    min-width : 900px;
    max-width : 900px;
    background-color : white;
    border-radius : 15px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    display : flex; 
    flex-direction : column;
    display : flex; 
    flex-direction : column;
    box-sizing : border-box;

    .main-content {
        flex-grow : 1;
        display : grid; 
        grid-template-columns : 1fr 2fr;
        padding : 25px;
        gap : 25px;

    }
`

export default IndividualLocation