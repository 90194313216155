import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import AreYouSure from '../../GeneralComponents/AreYouSure';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';

const IndividualTemplateHeader = ({ state, setState, formRef, template, imageURL }) => {

    const submitFunction = (e) => {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }

    const [confirmationObject, setConfirmationObject] = React.useState(false);

    const deleteFunction = () => {

        const deleteTemplate = async () => {
            try {
                const response = await Axios.delete(`${backEndAdress}/api/certificate-template/${state.individualTemplate._id}`);
                setState({
                    ...state,
                    templates : response.data,
                    individualTemplate : null
                });
                setConfirmationObject(null);
            } catch (error) {
                console.log('error', error);
            }
        }

        const close = () => {
            console.log('close');
            setConfirmationObject(null);
        }

        setConfirmationObject({
            title : 'Are you sure you want to delete this template?',
            textOption1 : 'Yes',
            textOption2 : 'No',
            function1 : deleteTemplate,
            function2 : close
        });

    }

    const rendering = () => {
        return (
            <IndividualTemplateHeaderSC>

                { confirmationObject && 
                    <AreYouSure
                        title={confirmationObject.title}
                        textOption1={confirmationObject.textOption1}
                        textOption2={confirmationObject.textOption2}
                        function1={confirmationObject.function1}
                        function2={confirmationObject.function2}
                    />
                }

                <p>Add template</p>
                <div className='buttons'>
                    {template._id && <Button variant='outlined' color='primary' onClick={() => deleteFunction()}>Delete</Button>}
                    <Button 
                        variant='outlined' 
                        color='primary' 
                        onClick={() => submitFunction()}
                        disabled={!imageURL}    
                    >Save</Button>
                    <Button variant='outlined' color='primary' onClick={() => setState({...state, individualTemplate : null})}>Cancel</Button>
                </div>
            </IndividualTemplateHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTemplateHeaderSC = styled.div`
    display : flex;
    justify-content : space-between;
    padding : 15px;
    border-bottom : 1px solid lightgrey;
    align-items : center;
    .buttons {
        display : flex;
        gap : 10px;
    }

`

export default IndividualTemplateHeader