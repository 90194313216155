import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';

const BasicTextArea = ({ label, defaultValue, reference, style }) => {

    const rendering = () => {
        return (
            <BasicTextAreaSC style={{...style}}>
                <textarea
                    defaultValue={defaultValue ? defaultValue : ''} 
                    ref={reference}
                    size={1}
                    resize='none'
                />
                <div className='label'>{label}</div>
            </BasicTextAreaSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BasicTextAreaSC = styled.div`
    position : relative; 
    font-size : inherit;
    font-family : inherit;
    flex-grow : 1;
    
    textarea {
        box-sizing : border-box;
        max-width : 100%;
        min-width : 100%;
        padding : 14px;
        border : 1px solid black;
        border-radius : 5px;
        font-size : inherit;
        font-family : inherit;
        resize : none;
        min-height : 100%;
        box-sizing : border-box;

    }

    .label {
        position : absolute;
        top : -11px;
        left : 5px; 
        background-color : white;
        padding-left : 14px;
        padding-right : 14px;
        color : ${colors.midGrey};
        font-size : 0.8rem;
        font-weight : 400;
}
`

export default BasicTextArea