import React from 'react';
import styled from 'styled-components';

const ErrorsList = ({ errors }) => {

    const errorsArray = Object.keys(errors);

    const rendering = () => {
        return (
            <ErrorsListSC>
                {errorsArray.map((error, index) => {
                    return (
                        <p>{errors[error].message}</p>
                    );
                })}
            </ErrorsListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ErrorsListSC = styled.div`
    
`

export default ErrorsList