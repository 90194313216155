import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { TrainingsOverViewContext } from '../../TrainingsOverview/TrainingsOverview';
import { AddOrEditTrainingContext } from '../AddOrEditTraining';

const Header = ({ closeWindowFunction }) => {

    const { navigationItems, activeItem, setActiveItem } = React.useContext(AddOrEditTrainingContext);

    const rendering = () => {
        return (
            <HeaderSC>
                <div className='items-container'>
                    {navigationItems.map((item, index) => {
                        return (
                            <div 
                                key={index}
                                className={`header-item ${activeItem === index ? 'active' : ''}`}
                                onClick={() => setActiveItem(index)}   
                            >
                                {item.icon}
                                <p className='navigation-title'>{item.title}</p>
                            </div>
                        )}
                    )}
                </div>
                <div className='buttons-end-container'>

                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={closeWindowFunction}
                        style={{marginRight : '10px'}}
                    >
                        Cancel
                    </Button>
                </div>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    display : flex; 
    min-height : 80px;
    max-height : 80px;

    .items-container {
        display : flex;
        flex : 1;
        justify-content : space-around;
        position : relative; 

        &::after {
            content: '';
            position: absolute;
            top: 50%; /* Adjust to vertically center */
            width : 80%;
            margin : 0 auto;
            left: 0;
            right: 0;
            height: 0.5px; /* Thickness of the line */
            background: lightgrey; /* Color of the line */
        }    

        .header-item {
            display : flex;
            flex-direction : column;
            align-items : center;
            justify-content : center;
            cursor : pointer;
            color : grey;
            background-color : white;
            z-index : 4;
            padding-left : 10px;
            padding-right : 10px;

            &>.navigation-title {
                font-size : 0.6em;
            }
        }

        .active {
            color : #3764c4;
        }   
    }

    .buttons-end-container {
        display : flex;
        justify-content : flex-end;
        align-items : center;
        padding-right : 20px;
        box-sizing : border-box;

    }
`

export default Header