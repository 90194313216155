import React from 'react';
import styled from 'styled-components';
import SearchBar from './SearchBar';
import CreateNewButton from './CreateNewButton';
import Profile from './Profile'; 
import { colors } from '../../variables/Colors';
import { HeigtsPaddingBorderRadius } from '../../variables/HeigtsPaddingBorderRadius';

const { headerFontSize } = HeigtsPaddingBorderRadius;

const HeaderBar = () => {

    const rendering = () => {
        return (
            <HeaderBarSC>
                <SearchBar />
                <div className='end'>
                    <CreateNewButton />
                    <Profile />
                </div>
            </HeaderBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderBarSC = styled.div`
    flex-grow : 1;
    background-color : ${colors.primary};
    display : flex;
    justify-content : space-between;
    font-size : ${headerFontSize};
    box-sizing : border-box;
    max-width : 100vw;
    overflow : hidden;

    .end {
        display : flex;
        justify-content : flex-end;
        align-items : center;
        padding-right : 10px;
        box-sizing : border-box;
    }
`

export default HeaderBar