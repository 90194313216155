import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import { Button, TextField, TextareaAutosize } from '@mui/material';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';

const AddTraining = () => {

    const [imageURL, setImageURL] = React.useState(null);
    const [file, setFile] = React.useState(null);

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    const saveFunction = () => {

        const training = {
            name : document.getElementById('trainingName').value,
            description : document.getElementById('trainingDescription').value
        };
        
        console.log('training', training);

        const formData = new FormData();
        formData.append('s3Image', file);
        formData.append('training', JSON.stringify(training));

        const saveTraining = async () => {
            try {
                // const response = await Axios.post(`${backEndAdress}/trainings`, formData);
                const response = await Axios.post(`${backEndAdress}/api/trainings`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveTraining();

    }


    const rendering = () => {
        return (
            <Modal>
                <AddTrainingSC>
                    <div className='title'> 
                        <h1>Add Training</h1>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <TextField
                                label='Training Name'
                                variant='outlined'
                                style={{ marginBottom : '15px' }}
                                id="trainingName"
                            />

                            <TextField
                                label='Training Description'
                                variant='outlined'
                                style={{ marginBottom : '15px' }}
                                multiline
                                rows={4}
                                id='trainingDescription'
                            />

                        </div>

                        <div className='right'>
                            <div className='upload' style={{ display : imageURL && 'hidden' }}>
                                <label for='image' >
                                    <input type='file' onChange={handleImageUpload} />
                                </label>
                            </div>
                            <div className='preview'>
                                {imageURL && <img src={imageURL} alt='Uploaded' />}
                            </div>
                        </div>
                    </div>

                    <div className='actions'>
                        <Button
                            variant='contained'
                            color='secondary'
                            style={{ marginRight : '15px' }}
                        >
                                Cancel
                        </Button>
                        <Button
                            variant='contained'
                            color='primary'
                            style={{ marginRight : '15px' }}
                            onClick={() => saveFunction()}
                        >
                                Save
                        </Button>
                    </div>


                </AddTrainingSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTrainingSC = styled.div`
    background-color : white;
    width : 80%;
    height : 80%;
    border-radius : 5px;
    display : flex; 
    flex-direction : column;
    padding : 15px;
    box-sizing : border-box;
    z-index : 1000;

    #file {
        display : none;
    }

    .left {
        display : flex;
        flex-direction : column;
        width : 50%;
        padding : 15px;
        box-sizing : border-box;
    }

    .right {
        flex-grow : 1;
        display : flex;
        padding : 15px;
        box-sizing : border-box;
        flex-direction : column;


        .preview {
            display : flex;
            flex-direction : column;
            width : 300px;
            max-width : 300px;
            aspect-ratio : 16/9;
            padding : 15px;
            box-sizing : border-box;

            img {
                width : 100%;
                height : 100%;
                object-fit : cover;
            }
        }

    }
`

export default AddTraining