import React from 'react';
import styled from 'styled-components';

const KPICard = ({
    title,
    subTitle,
    value,
    dateRange
}) => {

    const rendering = () => {
        return (
            <KPICardSC>
                <h1 className='KPI-title'>{title}</h1>
                <p className='KPI-description'>{subTitle}</p>
                <p className='KPI-value'>{value}</p>  
                <p className='date-range'>{dateRange}</p>
            </KPICardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const KPICardSC = styled.div`
    border : 1px solid #C9C9C9;
    padding : 20px;
    border-radius : 10px;
    max-width : 270px;
    min-width : 270px;
    margin-right : 20px;

    h1 {
        font-weight : 400;
        margin : 0; 
        padding : 0;
        font-size : 1.2em;
    }

    .KPI-description {
        font-size : 0.8em;
        color : #666;
    }

    .KPI-value {
        font-size : 1.7rem;
        font-weight : 550;
        color : #333;
        padding : 5px 0;
    }

    .date-range {
        font-size : 0.8em;
        color : #666;
        font-weight : 300;
    }
`

export default KPICard