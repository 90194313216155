import { Button, IconButton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';

const TestMailHeader = ({ state, setState }) => {

    const rendering = () => {
        return (
            <TestMailHeaderSC>
                <p className='title'>
                    Sending test mail
                </p>

                <Button 
                    component="label" 
                    role={undefined} 
                    variant="contained" 
                    startIcon={<CloseIcon />}
                    onClick={() => setState({ ...state, testMailPopUp : false})}
                >
                    close
                </Button>

            </TestMailHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TestMailHeaderSC = styled.div`
    display : flex;
    justify-content : space-between;
    padding : 15px;

`

export default TestMailHeader