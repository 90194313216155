import React from 'react';
import styled from 'styled-components';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios';
import JobsList from './jobsList/JobsList';
import RescheduleJobsButton from './RescheduleJobsButton';
import Modal from '../GeneralComponents/Modal';
import { AppContext } from '../../App';

const MailTraffic = () => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;

    const [mailJobs, setMailJobs] = React.useState([]);
    const [scheduledJobIds, setScheduledJobIds] = React.useState([]);
    const [loading, setLoading] = React.useState(false);

    React.useEffect(() => {
        const getMailJobs = async () => {
            try {
                const mailJobs = await Axios.get(`${backEndAdress}/api/mail-jobs/get-all/${tenant}`);
                setMailJobs(mailJobs.data);
            } catch (error) {
                console.log('error : ', error);
            }
        }

        getMailJobs();
    },[]);

    const rendering = () => {
        return (
            <MailTrafficSC>
                {loading && <Modal><p style={{height : "200px", minWidth : "600px", backgroundColor : "white"}}>loading</p></Modal>}
                <RescheduleJobsButton 
                    mailJobs={mailJobs}
                    setMailJobs={setMailJobs}
                    scheduledJobIds={scheduledJobIds}
                    loading={loading}
                    setLoading={setLoading}    
                />
                <JobsList 
                    mailJobs={mailJobs} 
                />
            </MailTrafficSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailTrafficSC = styled.div`
    background-color : white; 
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    margin : 10px;
    border-radius : 10px;
    padding : 10px;
`

export default MailTraffic