import React from 'react';
import styled from 'styled-components';
import IndividualTemplateHeader from './IndividualTemplateHeader';
import IndividiualTemplateInfo from './IndividualTemplateInfo';
import Axios from 'axios'; 
import Modal from '../../GeneralComponents/Modal';
import AddImage from '../../addTraining/AddImage';

import { useAuth0 } from '@auth0/auth0-react';
import { backEndAdress } from '../../../variables/back-endAdress';
import { useForm } from 'react-hook-form';
import ImageUpload from '../../GeneralComponents/ImageUpload';

const IndividualTemplate = ({ template, state, setState }) => {

    console.log('template :', template);

    const form = useForm();
    const formRef = React.useRef();

    const { user } = useAuth0();
    const { register, handleSubmit, formState } = form;
    const { errors } = formState;

    const [imageURL, setImageURL] = React.useState(null);
    const [file, setFile] = React.useState(null);
    
    const submitIt = (data) => {

        setState({
            ...state,
            loading : true
        });

        const newTemplate = {
            _id : template ? template._id : null,
            imageId : template ? template.imageId : null,
            certificateName : data.certificateName,
            certificateText : data.certificateText,
        }

        const formData = new FormData();
        formData.append('templateImage', file);
        formData.append('templateInfo', JSON.stringify(newTemplate));

        const saveTemplateFunction = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/certificate-template`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                setState({
                    ...state,
                    individualTemplate : null,
                    loading : false,
                    templates : response.data,

                });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveTemplateFunction();
    }

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    React.useEffect(() => {
        if(template) {
            setImageURL(template.imageUrl);
        }
    }, [template]);

    const rendering = () => {
        return (
            <Modal>
                <IndividualTemplateSC>
                    <IndividualTemplateHeader
                        state={state}
                        setState={setState} 
                        formRef={formRef}
                        template={template}
                        imageURL={imageURL}
                    />
                    <form 
                        noValidate 
                        onSubmit={handleSubmit(submitIt)} 
                        ref={formRef}
                    >
                        <div className='container'>
                            <IndividiualTemplateInfo 
                                template={template} 
                                register={register}    
                                errors={errors}

                            />
                            <div className='image-container'>
                                <ImageUpload
                                    imageURL={imageURL}
                                    setImageURL={setImageURL}
                                    file={file}
                                    setFile={setFile}
                                    id={'thumbnail-image-upload'}
                                    description={'Upload thumbnail image'}
                                />
                            </div>
                        </div>
                    </form>
                </IndividualTemplateSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTemplateSC = styled.div`
    background-color : white;
    min-height : 450px;
    display : flex; 
    flex-direction : column;
    border-radius : 5px;
    box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.1);

    form {
        display : flex;
        flex : 1;

    }
    .container {
        display : flex;
        flex : 1;
    }

    .image-container {
        flex : 1;
        padding : 25px;
        box-sizing: border-box;
        min-width : 400px;
    }
`

export default IndividualTemplate