import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { LocationsContext } from '../LocationsGrid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const IndividualLocationHeader = ({ formRef, deleteLocation }) => {

    const submitFunction = (e) => {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }

    const { locationsState, setLocationsState } = React.useContext(LocationsContext);

    const rendering = () => {
        return (
            <IndividualLocationHeaderSC>
                <p className='title'>Location</p>
                <div className='buttons'>
                    <Button
                        variant='outlined'
                        color='primary'
                        style={{marginRight : '10px'}}
                        onClick={(e) => submitFunction(e)}
                    >
                        Save
                    </Button>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setLocationsState({ ...locationsState, individualLocation : null })}
                    >
                        Cancel
                    </Button>
                    <IconButton
                        onClick={() => deleteLocation()}
                        style={{marginLeft : '10px'}}
                    >
                        <DeleteIcon />
                    </IconButton>
                </div>
            </IndividualLocationHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualLocationHeaderSC = styled.div`
    display : flex;
    justify-content : space-between;
    border-bottom : 1px solid rgba(0,0,0,0.1);
    padding : 25px;

`

export default IndividualLocationHeader