import React from 'react';
import styled from 'styled-components';
import { MailScheduleBothLocationsContext } from './MailScheduleBothLocations';

const SelectLocationBar = () => {

    const { locationType, setLocationType } = React.useContext(MailScheduleBothLocationsContext);

    const rendering = () => {
        return (
            <SelectLocationBarSC>
                <div 
                    className='location-type-item' 
                    onClick={() => setLocationType('our-location')}
                    style={{ border : locationType === 'our-location' ? '1px solid #000' : '1px solid #fff' }}    
                >
                    <h3>Training at our location</h3>
                    <p>These include information about lunch, opening hours of the office etc. </p>
                </div>
                
                <div 
                    className='location-type-item' 
                    onClick={() => setLocationType('customer-location')}
                    style={{ border : locationType === 'customer-location' ? '1px solid #000' : '1px solid #fff' }}    
                >
                    <h3>Training at customer location</h3>
                    <p>These contain less info, as the training is hosted at the customers location </p>
                </div>
            </SelectLocationBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SelectLocationBarSC = styled.div`
    display : flex; 
    background-color : white;
    
    .location-type-item {
        max-width : 300px;
        padding : 10px;
        border-radius : 10px;
        &:hover {
            cursor : pointer; 
        }
        h3 {
            font-size : 1rem;
            font-weight : 400;
            font-family : inherit;
            margin : 0;
            padding : 0;
        }

        p {
            font-size : 0.7rem;
            color : grey;
            font-weight : 300;
            font-family : inherit;
            margin : 0;
            padding : 0;
        }
    }
`

export default SelectLocationBar