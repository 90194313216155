import React from 'react';
import styled from 'styled-components';
import AddAPhoto from '@mui/icons-material/AddAPhoto';

const PictureOpload = ({ padding, id, setFileFunction, defaultImageURL } ) => {

    const [file, setFile] = React.useState(null);
    const [imageURL, setImageURL] = React.useState(null);

    const handleImageUpload = (e) => {
        const fileItem = e.target.files[0];
        setFileFunction(fileItem);

        setFile(fileItem);
        const reader = new FileReader();
        reader.readAsDataURL(fileItem);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    React.useEffect(() => {
        if(defaultImageURL) {
            setImageURL(defaultImageURL);
        }
    }, []);


    const rendering = () => {
        return (
            <PictureOploadSC style={{padding : padding ? `${padding}px` : 0}}>
                <label 
                    htmlFor={id}
                    className="custom-file-upload"
                    style={{ backgroundImage : `url(${imageURL})` }}
                >
                    <div className='upload-icon'>
                        <AddAPhoto />
                    </div>
                </label>

                <input 
                    id={id} 
                    type="file" 
                    style={{display: 'none'}}
                    accept='.png, .jpg, .jpeg'
                    onChange={(e) => handleImageUpload(e)}    
                />
            </PictureOploadSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PictureOploadSC = styled.div`
    flex-grow : 1;
    background-color : white;
    display : flex; 
    flex-direction : column;
    padding-top : 0;
    box-sizing : border-box;
    border : 1px solid lightgrey;
    border-radius : 5px;
    position : relative;
    overflow: hidden;
    

    .custom-file-upload {
        flex-grow : 1;
        position : absolute;
        top : 0;
        left : 0; 
        right : 0; 
        bottom : 0;
        display : flex; 
        cursor: pointer;
        justify-content : center;
        align-items : center;
        overflow: hidden;
        transition : 0.3s;
        flex-grow : 1;
        background-position : center;
        background-size : cover;
        background-repeat : no-repeat;
    }

    .upload-icon {
        position : absolute;
        top : 0;
        left : 0;
        right : 0;
        bottom : 0;
        z-index : 1000;
        color : grey;
        display : none;
        justify-content : center;
        align-items : center;
        
        &>svg {
            font-size : 5em;
        }
    }
`

export default PictureOpload