import React from 'react';
import styled from 'styled-components';
import TrainingInfo from './TrainingInfo';
import InfoIcon from '@mui/icons-material/Info';
import TrainingDates from './TrainingDates';

import FeedIcon from '@mui/icons-material/Feed';
import EmailIcon from '@mui/icons-material/Email';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import SchoolIcon from '@mui/icons-material/School';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import { colors } from '../../variables/Colors.js';
import { AddTrainingContext } from './AddTraining2.js';

const AddTrainingSidebar = () => {

    const { state , setState } = React.useContext(AddTrainingContext);

    const titles = [{
        name : 'General info',
        icon : <FeedIcon />
    },{
        name : 'Mail schedule',
        icon : <EmailIcon />
    },{
        name : 'Feedback form',
        icon : <FormatAlignJustifyIcon />
    },{
        name : 'Certificates',
        icon : <SchoolIcon />
    },{
        name : 'Review',
        icon : <CheckBoxIcon />
    }]
    const rendering = () => {
        return (
            <AddTrainingSidebarSC>
                {titles.map((item, index) => {
                    return (
                        <div className='training-sidebar-item' key={index} onClick={() => setState({...state, currentComponentName : item.name})}>
                            {item.icon}
                            <p>{item.name}</p>
                        </div>
                    )})
                }
            </AddTrainingSidebarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTrainingSidebarSC = styled.div`
    padding : 15px;
    border-right : 1px solid #e0e0e0;
    box-sizing : border-box;
    min-width : 220px;

    .training-sidebar-item {
        display : flex;
        padding : 15px;
        box-sizing : border-box;
        align-items : center;
        color : ${colors.darkGrey};

        &>svg {
            margin-right : 15px;
            color : ${colors.darkGrey};
            font-size : 1rem;
        }
    }
`

export default AddTrainingSidebar