import React from 'react';
import styled from 'styled-components';
import Modal from '../../../components/GeneralComponents/Modal';
import { Button, TextField } from '@mui/material';
import LocalPostOfficeIcon from '@mui/icons-material/LocalPostOffice';
import { backEndAdress } from '../../../variables/back-endAdress';
import Axios from 'axios'; 
import { MailsContext } from '../Mails';
import { AppContext } from '../../../App';

const AddMailTemplate = () => {

    const nameRef = React.useRef();
    const descriptionRef = React.useRef();
    const { state , setState } = React.useContext(MailsContext);
    const { appState } = React.useContext(AppContext); 
    const [file, setFile] = React.useState(null);

    const handleFileUpload = (e) => {
        setFile(e.target.files[0]);
    }

    const saveTemplate = () => {
        setState({...state, mailTemplatesLoading : true});

        const formData = new FormData();

        const templateInfo = {
            title : nameRef.current.value,
            description : descriptionRef.current.value,
            company : appState.company,
            createdInTool : false
        }

        formData.append('mailTemplateInfo', JSON.stringify(templateInfo));
        formData.append('mailTemplate', file);

        const saveTraining = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/mail-templates`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                setState({
                    ...state,
                    mailTemplates : response.data,
                    addTemplateToggled : false,
                    mailTemplatesLoading : false
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveTraining();
        setState({
            ...state,
            addTemplateToggled : false
        });

    }

    const rendering = () => {

        const conditions = nameRef.current && descriptionRef.current && file;

        return (
            <Modal>
                <AddMailTemplateSC>
                    <div className='top-bar'>
                        <p className='title'>Add mail template</p>
                        <div className='actions'>
                            <Button 
                                variant='contained' 
                                style={{ marginRight : '15px'}}
                                onClick={() => saveTemplate()}    
                                disabled={!conditions}
                            >
                                Save
                            </Button>

                            <Button 
                                variant='contained'
                                onClick={() => setState({...state, addTemplateToggled : false})}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='left'>
                            <TextField
                                label='Name'
                                variant='outlined'
                                inputRef={nameRef}
                            />
                            <TextField 
                                label='description'
                                variant='outlined'
                                multiline
                                rows={8}
                                inputRef={descriptionRef}
                            />
                        </div>
                        <div className='right'>
                            <div className='upload-container'>
                                <label for="file-upload" class="custom-file-upload">
                                    <div className='upload-icon'>
                                        <LocalPostOfficeIcon style={{color : 'white'}}/>
                                    </div>
                                    <p>{file? file.name : 'Upload template'}</p>
                                </label>
                                <input 
                                    id="file-upload" 
                                    type="file" 
                                    style={{display: 'none'}}
                                    accept='.html'
                                    onChange={(e) => handleFileUpload(e)}    
                                />
                            </div>
                        </div>
                    </div>
                </AddMailTemplateSC>
            </Modal>

        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddMailTemplateSC = styled.div`
    min-width : 550px;
    min-height : 400px;
    display : flex; 
    flex-direction : column;
    background-color : white;

    .top-bar {
        display : flex;
        justify-content : space-between;
        align-items : center;
        padding-right : 15px;
        border-bottom : 1px solid lightgrey;
    }

    .title {
        font-size : 20px;
        font-weight : 500;
        padding : 20px;
    }

    .content {
        display : flex;
        background-color : white;
        max-width : 100%;   

    }

    .left {
        max-width : 50%;
        flex-grow : 1;
        padding : 15px;
        box-sizing : border-box;
        display : flex;
        flex-direction : column;
        background-color : white;


        &>div {
            margin-bottom : 15px;
        }
    }

    .right {
        padding : 15px;
        padding-left : 0;
        box-sizing : border-box;
        flex-grow : 1;
        display : flex;
        flex-direction : column;

        .upload-container {
            border-radius : 10px;
            border : 1px solid lightgrey;
            flex-grow : 1;
            display : flex;
            justify-content : center;
            flex-direction : column;

            .custom-file-upload {
                display : flex;
                flex-direction : column;
                justify-content : center;
                align-items : center;

                .upload-icon {
                    width : 160px;
                    height : 160px;
                    border-radius : 15px;
                    background-color : lightgrey;
                    margin-bottom : 10px;
                    display : flex;
                    justify-content : center;
                    align-items : center;

                    &:hover {
                        background-color : grey;
                        cursor: pointer;
                    }

                    &>svg {
                        font-size : 4rem;
                    }
                }

                p {
                    font-size : 1.2rem;
                    color : grey;
                }
            }
        }

    }
`

export default AddMailTemplate