import React from 'react';
import styled from 'styled-components';
import DescriptionIcon from '@mui/icons-material/Description';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';

const TitleComponent = (p) => {
    console.log('p : ', p);
    return (
        <div className='title-component'>
            <DescriptionIcon />
            <p>{p.data.name}</p>
        </div>
    );
}

const TypeComponent = (p) => {
    return (
        <div className='type-component'>
            <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcsk4OzAR5EFN6Ww4K8uT2vbQptY373-uUqw&s'/>
        </div>
    );
}

const DownloadButton = (p) => {
    return (
        <div className='download-button'>   
            <FileDownloadIcon />
        </div>
    );
}


const FilesList = ({ files }) => {

    const rendering = () => {
        return (
            <FilesListSC>
                <div className='ag-theme-material' style={{height : "100%", width : "100%"}} >
                    <AgGridReact
                        rowData={files} 
                        columnDefs={[
                            { valueGetter : p => p.data, cellRenderer : TitleComponent, headerName : 'Title',  flex : 2 },
                            { valueGetter : p => p.data, cellRenderer : TypeComponent, headerName : 'Type',  flex : 1 },
                            {field : 'uploadedBy', headerName : 'Uploaded by', flex : 3},
                            { valueGetter : p => p.data, cellRenderer : DownloadButton, headerName : '', flex : 1}
                        ]}
                    />
                </div>
            </FilesListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FilesListSC = styled.div`
    border : 1px solid lightgrey;
    margin-top : 20px;
    border-radius : 5px;
    flex-grow : 1;   

    .title-component {
        display : flex;
        align-items : center;
        gap : 10px;

        &>svg {
            font-size : 20px;
            color : grey;
        }
    }

    .type-component {
        justify-content : center;
        padding-top : 4px;
        &>img {
            width : 20px;
            height : 20px;
        }
    }

    .ag-theme-material {
        --ag-font-size: inherit;
        --ag-material-accent-color : #1976d2;
        --ag-selected-row-background-color : rgba(42,115,207,0.1);
        .ag-cell-focus {
            outline: none; /* Remove the default outline */
        }
    }

    .download-button {
        padding-top : 12px;
        display : flex;
        justify-content : center;
        align-items : center;
        color : #1976d2;
        font-size : 20px;
        cursor : pointer;
    }

`

export default FilesList