import { TextField } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const TrainingPropertyEdit = ({ property }) => {

    const rendering = () => {
        return (
            <TrainingPropertyEditSC>
                <div className='icon-holder'>
                    {property.icon}
                </div>
                <TextField
                    label={property.name}
                    defaultValue={property.value}
                    variant='outlined'
                    fullWidth
                    style={{ fontSize : '12px' }}
                    inputRef={property.ref}
                />
            </TrainingPropertyEditSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingPropertyEditSC = styled.div`
    display : flex;
    align-items : center;

    .icon-holder {
        min-height : 50px;
        min-width : 50px;
        max-height : 50px;
        max-width : 50px;
        border : 1px solid #e0e0e0;
        border-radius : 100%;
        display : flex;
        justify-content : center;
        align-items : center;
        background-color : grey;
        margin-right : 5px;
    }
`

export default TrainingPropertyEdit