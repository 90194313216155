import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';
import { MailsContext } from '../Mails';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';

const ButtonsBottom = () => {

    const removeTemplate = () => {
        setState({...state, mailTemplatesLoading : true});

        const removeTemplate = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/mail-templates/delete/${state.currentMailTemplate._id}`)
                console.log('remaining templates : ', response.data);
                setState({
                    ...state,
                    currentMailTemplate : null,
                    mailTemplates : response.data,
                    mailTemplatesLoading : false
                });
            } catch (error) {
                console.log(error);
            }
        }

        removeTemplate();
    }

    const { state , setState } = React.useContext(MailsContext);

    const rendering = () => {
        return (
            <ButtonsBottomSC>
                <Button
                    variant='contained'
                    style={{ marginRight : '15px'}}
                    onClick={() => removeTemplate()}
                    disabled={!state.currentMailTemplate}
                >
                    Delete
                </Button>

            </ButtonsBottomSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ButtonsBottomSC = styled.div`
    position : absolute; 
    bottom : 15px; 
    right : 0;
    
`

export default ButtonsBottom