import { Button, IconButton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { TrainersOverviewContext } from '../TrainersOverview';
import DeleteIcon from '@mui/icons-material/Delete';

const IndividualTrainerHeader = ({ trainer, formRef }) => {

    const { saveTrainer, trainersState, setTrainersState, deleteTrainer } = React.useContext(TrainersOverviewContext);

    const close = () => {
        setTrainersState({
            ...trainersState,
            individualTrainer : null
        });
    }

    const submitFunction = (e) => {
        formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
    }

    const rendering = () => {
        return (
            <IndividualTrainerHeaderSC>
                <p className='trainer-name'>{trainer.firstName} {trainer.lastName}</p>
                <div className='buttons'>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => close()}
                        style={{ marginRight : '10px'}}
                    >Cancel</Button>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={(e) => submitFunction(e)}
                    >Save</Button>
                    <IconButton
                        onClick={() => deleteTrainer()}
                        style={{marginLeft : '10px'}}
                    >
                        <DeleteIcon />
                    </IconButton>

                </div>
            </IndividualTrainerHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTrainerHeaderSC = styled.div`
    display: flex;
    justify-content: space-between;
    padding : 20px;
    box-sizing : border-box;
    border-bottom : 1px solid lightgrey;
    align-items : center;
    

    .trainer-name {
        font-size : 1.3em;
        padding : 10px;
    }
    
`

export default IndividualTrainerHeader