import React from 'react';
import styled from 'styled-components';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Modal from './Modal';

const OptionsTable = ({ options, valuesToShow, value, setValue, toggleTable, label}) => {

    const clickFunction = (option) => {
        setValue(option);
        toggleTable(false);
    }

    return (
        <Modal>
            <OptionsTableSC>
                <div className='title'>
                    <p>{label}</p>
                </div>

                <div className='options'>
                    {options.map((option, index) => {
                        return (
                            <div 
                                className='option' 
                                key={index}
                                onClick={() => clickFunction(option)}    
                            >
                                {valuesToShow.map((value, index) => {
                                    if(value.type === 'text') {
                                        return (
                                            <p 
                                                className='value-cell' 
                                                key={index}
                                            >
                                                {option[value.name]}
                                            </p>
                                        )
                                    } else if(value.type === 'image') {
                                        return (
                                            <img 
                                                src={option[value.name]} 
                                                alt={option[value.name]} 
                                                style={{ width : '30px', height : '30px', borderRadius : '50%', marginRight : '15px'}}
                                            />
                                        )
                                    }
                                })}
                            </div>
                        );
                    })}
                </div>

            </OptionsTableSC>
        </Modal>
    );
}

const OptionsTableSC = styled.div`
    position : absolute;
    background-color : white;
    border : 1px solid lightgrey;
    border-radius : 5px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    z-index : 1000;
    min-width : 500px;

    .title {
        padding : 15px;
        display : flex;
        justify-content : space-between;
    }

    .option {
        display : flex;
        padding : 15px;
        border-bottom : 1px solid lightgrey;
        align-items : center;
        border-top : 1px solid lightgrey;

        text-align : left;


        &:hover {
            background-color : lightgrey;
            cursor : pointer;
        }
    }

    .value-cell {
        margin-right : 15px;
    }
`;

const CustomDropDown = ({ options, valuesToShow, inputRef, defaultValue, valueInBox, disabled, label, onChangeFunction }) => {

    const [showTable, toggleTable] = React.useState(false);
    const [value, setValue] = React.useState(null);

    React.useEffect(() => {
        inputRef.current = value; 
    }, [value]);

    React.useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const rendering = () => {

        return (
            <CustomDropDownSC>
                {showTable && 
                    <OptionsTable 
                        options={options}
                        valuesToShow={valuesToShow}    
                        setValue={setValue}
                        value={value}
                        toggleTable={toggleTable}
                        label={label}
                />}

                <div 
                    onClick={() => !disabled && toggleTable(!showTable)}
                    className={`container ${!disabled ? 'enabled' : ''}`}  

                >
                    <p 
                        className='value'
                        onChange={onChangeFunction && onChangeFunction(value)}    
                    >{value ? value[valueInBox] : `${label}...`}</p>
                    {!disabled && <ArrowDropDownIcon />}

                    <p className='label'>{label}</p>
                </div>


            </CustomDropDownSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CustomDropDownSC = styled.div`
    position : relative; 
    border : 1px solid #949494;
    border-radius : 5px;
    padding : 15px;
    display : flex;
    justify-content : space-between;
    align-items : center;
    position : relative;
    margin : 5px;

    .enabled {
        cursor : pointer;
    }


    .container {
        display : flex;
        justify-content : space-between;
        flex-grow : 1;
        
    }

    .value {
    }

    svg {
        color : grey;
    }

    .label {
        position : absolute;
        top : -3px;
        left : 0;
        transform : translate(15px, -6px);
        background-color : white;
        padding : 0 5px;
        color : grey;
        font-size : 12px;
    }
`

export default CustomDropDown