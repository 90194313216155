import React from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = () => {

    const rendering = () => {
        return (
            <SearchBarSC>
                <SearchIcon />
                <input type="text" placeholder="Search..." />
            </SearchBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SearchBarSC = styled.div`
    display : flex;
    align-items : center;

    &>svg {
        min-width : 50px;
        max-width : 50px;
        min-height : 50px;
        max-height : 50px;
        padding : 13px;
        color : white;
        box-sizing : border-box;
        padding-right : 5px;
    }

    &>input {
        border : none;
        outline : none;
        background-color : #2B87DF;
        box-sizing : border-box;
        background-color : white;
        max-height : 55%;
        border-radius : 25px;
        padding-left : 15px;
        font-family : inherit;
        padding : 5px;
        padding-left : 15px;
        font-size  : inherit;

    }
`

export default SearchBar