import React from 'react';
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';

const Difficulty = ({ trainingToEditLocal , setTrainingToEditLocal }) => {

    const [languages, setAvailableLanguages] = React.useState(trainingToEditLocal.customProperties.availableLanguages);

    const handleChange = (event) => {
      const { target: { value } } = event;
      console.log('target : ', value);
      setAvailableLanguages(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    React.useEffect(() => {
        if(trainingToEditLocal) {
            setTrainingToEditLocal({
                ...trainingToEditLocal,
                customProperties : {
                    ...trainingToEditLocal.customProperties,
                    availableLanguages : languages
                }
            });
        }
    }, [languages]);

    const langaugesToChooseFrom = [
        'English',
        'Nederlands',
        'Français'
      ];

    const rendering = () => {
        return (
            <DifficultySC>
                    <FormControl 
                        sx={{ 
                            width : '100%',
                        }}
                            
                    >
                        <InputLabel 
                            id="demo-multiple-checkbox-label"
                            sx={{
                                backgroundColor : 'white',
                                paddingRight : '5px',
                                fontFamily : 'Outfit, sans-serif',
                                color : 'black',
                                fontSize : '16px',
                            }}        
                        >
                            Language
                        </InputLabel>
                        <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={languages}
                            onChange={handleChange}
                            input={<OutlinedInput label="Tag" />}
                            renderValue={(selected) => selected.join(', ')}
                            sx={{ fontFamily : 'inherit', fontSize : 'inherit'}}

                        >
                        {langaugesToChooseFrom.map((language) => (
                            <MenuItem 
                                sx={{ padding : '0px'}}
                                key={language} 
                                value={language}
                            >
                                <Checkbox checked={languages.includes(language)} />
                                <ListItemText primary={language} />
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
            </DifficultySC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DifficultySC = styled.div`

`

export default Difficulty