import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import moment from 'moment';

const WorkshopsList = ({ preOpenTraing, trainingId, onClickFunction }) => {

    const trainingIdValue = preOpenTraing ? preOpenTraing._id : trainingId;

    const [instances, setInstances] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [rowData, setRowData] = useState([]);

    const clickRow = () => {
        console.log('clicked');
    }

    const defaultColDef = useMemo(() => {
        return (
            {
                flex : 1,
                minWidth : 100,
            }
        )
    });

    const TrainerComponent = (p) => {
        const { firstName , imageUrl, lastName} = p.value;
        return (
            <div className='trainer' style={{ display : 'flex'}}>
                <div 
                    className='avatar' 
                    style={{
                        backgroundImage : `url(${imageUrl})`,
                        width : '42px',
                        height : '42px',

                    }}
                >
                </div>
                <p>{firstName}</p>
            </div>
        );
    }

    const AvatarComponent = (p) => {
        const { firstName, lastName, imageUrl } = p.data.trainer;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <img 
                    src={imageUrl} 
                    alt='avatar' 
                    style={{ 
                        width : '35px', 
                        height : '35px', 
                        objectFit : 'cover',
                        borderRadius : '50%'
                    }}
                />
                <p style={{paddingLeft : '10px'}}>{`${firstName} ${lastName}`}</p>
            </div>
        );
    }

    const LocationComponent = (p) => {
        if(p.data.locationType == 'our-location') {

        const { title, thumbnailImageUrl } = p.data.location;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <img 
                    src={thumbnailImageUrl} 
                    alt='avatar' 
                    style={{ 
                        width : '35px', 
                        height : '35px', 
                        objectFit : 'cover',
                        borderRadius : '50%'
                    }}
                />
                <p style={{paddingLeft : '10px'}}>{title}</p>
            </div>
        );
        } else {
            return (
                <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                    <img 
                        src={'https://images.adsttc.com/media/images/5e66/5dc0/b357/65bd/db00/0024/newsletter/10.jpg?1583766967'} 
                        alt='avatar' 
                        style={{ 
                            width : '35px', 
                            height : '35px', 
                            objectFit : 'cover',
                            borderRadius : '50%'
                        }}
                    />
                    <p style={{paddingLeft : '10px'}}>Customer location</p>
                </div>
            );
        }
    }

    React.useEffect(() => {
        const fetchInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/training/${trainingIdValue}`)
                setInstances(response.data);
                setRowData(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchInstances();

    }, []);

    // Column Definitions: Defines the columns to be displayed.
    const [colDefs, setColDefs] = useState([
        // { field: "trainingName", headerName : 'title', flex : 2},
        { field: "startDate", headerName : 'Start date', valueFormatter : p => moment(new Date(p.value)).format('DD/MM/YYYY')},
        { valueGetter : p => p.data, cellRenderer : AvatarComponent, headerName : 'Trainer', flex : 1 },
        { valueGetter : p => p.data, cellRenderer : LocationComponent, headerName : 'Location', flex : 1 },

    ]);

    const testingFunc = (e) => {
        onClickFunction(e.data);


        // const instanceId = e.data._id;
        // const trainingId = e.data.trainingId; 
        // onClickFunction(instanceId, trainingId);
    }

    const rendering = () => {
        return (
            <WorkshopsListSC>
                <div className='ag-theme-material' style={{ height: 400, width: 600 }}>
                    <AgGridReact
                        rowData={rowData} 
                        columnDefs={colDefs}
                        defaultColDef={defaultColDef}
                        onRowClicked={(e) => testingFunc(e)}
                    />
                </div>

            </WorkshopsListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const WorkshopsListSC = styled.div`
    flex-grow : 1;
    display : flex; 
    flex-direction : column;

    .ag-theme-material {
        min-width : 100%;
        flex-grow : 1;

        --ag-font-size: inherit;
        --ag-font-family: inherit;    
}

    .trainer {
        display : flex;
        align-items : center;
        min-width : 80px;

        p {
            padding-left : 10px;
        }

        .avatar {
            margin-left : 10px;
            background-size : cover;
            background-position : center;
            background-repeat : no-repeat;
        }

    }
    
`

export default WorkshopsList