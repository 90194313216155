import React from 'react';
import styled from 'styled-components';
import Switch from '@mui/material/Switch';

const FreeTraining = ({ setTrainingToEditLocal, trainingToEditLocal}) => {

    const handleChange = (event) => {
        setTrainingToEditLocal({
            ...trainingToEditLocal,
            customProperties : {
                ...trainingToEditLocal.customProperties,
                free : event.target.checked
            }
        });
    }
    const rendering = () => {
        return (
            <FreeTrainingSC>
                <p>Free</p>
                <Switch onChange={(e) => handleChange(e)} size="small" defaultChecked={trainingToEditLocal.customProperties.free} />
            </FreeTrainingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FreeTrainingSC = styled.div`

`

export default FreeTraining