import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import Modal from '../Modal';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Button } from '@mui/material';

const DropdownWithTableModal = ({ 
    options, 
    valueFromParent, 
    colDefs, 
    setValueFunction, 
    keyToUse, 
    style, 
    icon,
    label
}) => {

    const [value, setValue] = React.useState('');
    const [showOptions, setShowOptions] = React.useState(false);    
    const [rowData, setRowData] = React.useState([]);

    React.useEffect(() => {
        if(options.length>0) {
            setRowData(options);
        }
    }, [options]);

    React.useEffect(() => {
        if(valueFromParent) {
            setValue(valueFromParent);
        } else {
            setValue(options[0]);
        }
    }, []);

    const clickFunction = (data) => {
        setValue(data);
        setShowOptions(!showOptions);
    }

    React.useEffect(() => {
        if(value) {
            setValueFunction(value); 
        }
    }, [value]);

    const rendering = () => {
        return (
            <DropdownWithTableModalSC >
                <div 
                    className='input-field' 
                    onClick={() => setShowOptions(!showOptions)}
                >
                    <div className='icon-and-value'>
                        {icon}
                        <p>{value[keyToUse]}</p>
                    </div>
                    <ArrowDropDownIcon />
                    <p className='label'>{label}</p>

                </div>

                { showOptions &&
                <div className='testing-g'>
                    <div className='table-header'>
                        <Button
                            onClick={() => setShowOptions(!showOptions)}
                            variant='outlined'
                        >
                            Close
                        </Button>
                    </div>
                    <div className='ag-theme-material' style={{height : "100%", width : "100%"}} >
                        <AgGridReact
                            rowData={rowData} 
                            columnDefs={colDefs}
                            onRowClicked={(e) => clickFunction(e.data)}
                        />
                    </div>
                </div>

/*



*/

                }
            </DropdownWithTableModalSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DropdownWithTableModalSC = styled.div`

    .input-field {
        display : flex; 
        align-items : center;
        padding : 10px;
        border : 1px solid #ccc;
        border-radius : 5px;
        position : relative; 
        min-height : 35px;

        &:hover {
            cursor : pointer;
            background-color : #fcfcfc;
        }

        .icon-and-value {
            display : flex;
            align-items : center;
            width : 100%;

            >svg {
                color : grey;
                padding-right : 10px;
            }
        }

        &>svg {
            color : grey;
        }


    }

    .label {
        font-size : 12px;
        color : grey;
        position : absolute;
        top : -8px;
        left : 10px;
        background-color : white;
        padding : 0 5px;
        z-index : 1;
    }

    .testing-g {
        position : absolute;
        top : 0; 
        right : 0; 
        left : 0; 
        bottom : 0;
        z-index : 2;
        display : flex; 
        flex-direction : column;

        .table-header {
            padding : 15px;
            border-bottom : 1px solid #ccc;
            display : flex;
            justify-content : flex-end;
            background-color : white;
        }

        .ag-theme-material {
            --ag-border-radius : 10px;
            --ag-font-size: inherit;
            --ag-font-family: inherit;    
        }
    }

`

export default DropdownWithTableModal