import React from 'react';
import styled from 'styled-components';
import KPICard from './KPICard';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';

const KPIRow = () => {

    const [registrations, setRegistrations] = React.useState(0);    
    const [revenue, setRevenue] = React.useState(0);
    const [revenueByTraining, setRevenueByTraining] = React.useState([]);

    React.useEffect(() => {
        const getRevenue = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/get-total-revenue`);
                const revenue = result.data[0].totalRevenue;
                const formattedRevenue = new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR' }).format(revenue);
                setRevenue(formattedRevenue);
            }
            catch(err) {
                console.log(err);
            }
        }

        const getAttendees = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/total-attendees`);
                setRegistrations(result.data.totalAttendees);
            }
            catch(err) {
                console.log(err);
            }
        }

        const getRevenueByTraining = async () => {
            try {
                const result = await Axios.get(`${backEndAdress}/api/instances/revenue-by-training`);
                setRevenueByTraining(result.data);
            } catch(err) {
                console.log(err);
            }
            }

        getRevenue();
        getAttendees();
        getRevenueByTraining();

    }, []);

    const rendering = () => {
        return (
            <KPIRowSC>
                <h1 className='title'>Key metrics</h1>
                <div className='kpi-cards'>
                    <KPICard 
                        title={'Total revenue'}
                        subTitle={'Attendees signed up times training price'}
                        value={revenue}
                        dateRange={'01/01/2024 - 12/11/2024'}
                    />
                    <KPICard 
                        title={'# Registrations'}
                        subTitle={'Attendees signed up for training'}
                        value={registrations}
                        dateRange={'01/01/2024 - 12/11/2024'}                    
                    />
                    {/* <KPICard 
                        title={'Revenue by training'}
                        subTitle={'Average evenue generated by each training'}
                        value={revenueByTraining}
                        dateRange={'01/01/2024 - 12/11/2024'}                    
                    /> */}
                </div>
            </KPIRowSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const KPIRowSC = styled.div`
    padding : 20px;
    display : flex;
    flex-direction : column;
    border-radius : 10px;
    padding : 20px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    background-color : white;
    margin-bottom : 20px;

    .kpi-cards  {
        display : flex;
        flex-wrap : wrap;
        justify-content : space
    }


`

export default KPIRow