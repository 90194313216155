import React from 'react';
import styled from 'styled-components';
import TipTapEditor from './TipTapEditor';

const TextForWebsite = ({ editor, websiteRef }) => {

    const rendering = () => {
        return (
            <TextForWebsiteSC ref={websiteRef} className='content-section' id='section3'>
                <h1 className='content-section-title'>Text for training page on website</h1>
                <TipTapEditor
                    editor={editor}
                />
                </TextForWebsiteSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TextForWebsiteSC = styled.div`
    display : flex;
    flex-direction : column;
    padding : 25px;
    background-color : white;
    border-radius : 10px;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    margin : 20px;
    margin-bottom : 0;
    margin-left : 0;

`

export default TextForWebsite