import CastForEducationIcon from '@mui/icons-material/CastForEducation';

export const trainingProperties = [,{
        name : 'Price',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : true
    },{
        name : 'Difficulty',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : false
    },{
        name : 'Language',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : true
    },{
        name : 'Format',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : true
    },{
        name : 'Type',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : true
    },{
        name : 'Capacity',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : true
    },{
        name : 'Attendees',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : false
    },{
        name : 'Start date',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : false
    },{
        name : 'End date',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : false
    },{
        name : 'Instance name',
        value : '',
        icon : <CastForEducationIcon />,
        showInCreate : false
    }];