import styled from 'styled-components';
import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer } from 'recharts';


const PieChartComponent = ({ data }) => {

    const pieData = data.map((item) => {
        return {
            name: item._id,
            value: item.totalRevenue
        }
    });

    const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    const rendering = () => {
        return (
            <PieChartSC>
                <ResponsiveContainer width="100%" height="100%">
                <PieChart width={800} height={400}>
                    <Pie
                        data={pieData}
                        cx={120}
                        cy={200}
                        innerRadius={60}
                        outerRadius={80}
                        fill="#8884d8"
                        paddingAngle={5}
                        dataKey="value"
                        label={({ name, value, percent }) => `${name}: ${Math.round(percent * 100)}%`} // Custom label

                    >
                        {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                    </Pie>
                </PieChart>
                </ResponsiveContainer>
            </PieChartSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PieChartSC = styled.div`
    border : 1px solid lightgrey;
    border-radius : 10px;
    flex : 1;
    display : flex;
    margin-left : 20px;
    min-height : 400px;
    flex-direction : column;
    justify-content : center;

`

export default PieChartComponent