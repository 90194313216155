import React from 'react';
import styled from 'styled-components';
import { useAuth0 } from "@auth0/auth0-react";

const LoginInfo = () => {

    const url = window.location.href;
    const value = url.split('.')[0].split('//')[1];
    console.log('value : ', value);

    const { loginWithRedirect } = useAuth0();

    const rendering = () => {
        return (
            <LoginInfoSC>
                <div className='login-info'>
                    <p className='title-login-info'>Log in to your account</p>
                    <p className='title-login-subtitle'>Welcome, select a method to log in</p>
                </div>
                <div className='authentication-methods'>
                    <div className='google-authentication' onClick={() => loginWithRedirect()}>
                        <img src='https://cdn-teams-slug.flaticon.com/google.jpg' />
                        <p >Google</p>
                    </div>
                </div>
                <p className='continue-with-email'>Or continue with e-mail</p>
                <div className='username-password-authentication'>
                    <input type='text' placeholder='Username'></input>
                    <input type='password' placeholder='Password'></input>
                </div>
                <button className='login-button'>Log In</button>
                <p className='footer-sign-up'>Don't have an acocunt?</p>
                <p className='footer-sign-up'>Sign Up</p>
            </LoginInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LoginInfoSC = styled.div`
    display : flex;
    max-width : 50%;
    min-width: 45%;
    box-sizing : border-box;
    flex-direction : column;
    justify-content : center;
    padding : 50px;

    .login-info {
        display : flex;
        flex-direction : column;

        .title-login-info {
            font-size : 30px;
        }

        .title-login-subtitle {
            margin-bottom : 20px;
            color : grey; 

        }
    }

    .authentication-methods {
        display : flex;
        flex-direction : column;
        margin-bottom : 20px;

        .google-authentication {
            display : flex;
            align-items : center;
            justify-content : center;
            padding : 10px;
            border-radius : 5px;
            cursor : pointer;
            border : 1px solid lightgrey;
            background-color : white;

            img {
                width : 30px;
                height : 30px;
                padding-right : 10px;
            }
        }


    }

    .continue-with-email {
        color : grey;
        margin-bottom : 20px;
        font-size : 12px;
        text-align : center;
        display : flex;
        border-bottom: 1px solid lightgrey;
        padding-bottom : 10px;
    }

    .username-password-authentication {
        display : flex;
        flex-direction : column;
        margin-bottom : 20px;

        input {
            padding : 10px;
            margin-bottom : 10px;
            border : 1px solid lightgrey;
            border-radius : 5px;
        }
    }

    .login-button {
        padding : 10px;
        background-color : #007bff;
        color : white;
        border : none;
        border-radius : 5px;
        cursor : pointer;
        margin-bottom : 20px;
    }

`

export default LoginInfo