import React from 'react';
import styled from 'styled-components';
import { HeigtsPaddingBorderRadius } from '../../variables/HeigtsPaddingBorderRadius';

const { headerFontSize } = HeigtsPaddingBorderRadius;  

const CreateNewButton = () => {

    const rendering = () => {
        return (
            <CreateNewButtonSC>
                <button>Create New</button>
            </CreateNewButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CreateNewButtonSC = styled.div`
    display : flex;
    justify-content : flex-end;
    align-items : center;
    padding-right : 30px;
    box-sizing : border-box;

    &>button {
        box-sizing : border-box;

        border : none;
        outline : none;
        background-color : #2B87DF;
        box-sizing : border-box;
        background-color : white;
        max-height : 55%;
        border-radius : 25px;
        font-family : inherit;
        padding : 3px;
        padding-left : 20px;
        padding-right : 20px;
        color : grey;
        font-size : ${headerFontSize};
    }

`

export default CreateNewButton