import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';

const AreYouSure = ({title, textOption1, textOption2, function1, function2}) => {

    const rendering = () => {
        return (
            <Modal>
                <AreYouSureSC>
                    <div className='header'>
                        <h1 className='header-title'>{title}</h1>
                    </div>
                    <div className='buttons'>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => function1()}
                            style={{ marginRight : '10px'}}
                        >
                            {textOption1}
                        </Button>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => function2()}
                        >
                            {textOption2}
                        </Button>

                    </div>
                </AreYouSureSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AreYouSureSC = styled.div`
    background-color : white;
    display : flex;
    flex-direction : column;
    border-radius : 5px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    padding : 20px;

    .header-title {
        margin : 0;
        font-weight : 400;
        font-size : 1.2rem;
        padding-bottom : 15px;
    }
       
`

export default AreYouSure