import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const JobsListHeader = ({ setState, state}) => {

    const rendering = () => {
        return (
            <JobsListHeaderSC>
                <p>Jobs overview</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setState({...state, jobsListToggled : false})}
                >
                    Close
                </Button>
            </JobsListHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const JobsListHeaderSC = styled.div`
    display : flex;
    justify-content : space-between;
    padding : 20px;

    p {
        font-size : 1.5rem;
        font-weight : 500;
        line-height : 1;
    }
`

export default JobsListHeader