import React from 'react';
import styled from 'styled-components';
import CertificateTemplateOptions from './CertificateTemplateOptions';
import AddTemplateModal from './AddTemplateModal';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios'; 
import TemplatesGrid from './TemplatesGrid';
import { AppContext } from '../../App';
import IndividualTemplate from '../certificates/IndividualTemplate/IndividualTemplate';

const CertificateTemplatesGrid = () => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;

    const certificateNameRef = React.useRef();
    const certificateTextRef = React.useRef();


    const [state, setState] = React.useState({
        templates : [],
        loading : true,
        individualTemplate : null
    });

    React.useEffect(() => {
        const getTemplates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificate-template/`);
                setState({
                    ...state,
                    templates : response.data,
                    loading : false
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        getTemplates();
    },[]);

    const rendering = () => {
        return (
            <CertificateTemplatesGridSC>
                <p>templates overview</p>
                <>
                    {state.individualTemplate && 

                        <IndividualTemplate
                            state={state}
                            setState={setState}
                            template={state.individualTemplate}
                        />

                    }
                </>
                <TemplatesGrid 
                    templates={state.templates}
                    state={state}
                    setState={setState}    
                />
                <CertificateTemplateOptions state={state} setState={setState}/>
            </CertificateTemplatesGridSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificateTemplatesGridSC = styled.div`
    display : flex;
    flex-direction : column;
    grid-template-columns : repeat(auto-fill, minmax(200px, 1fr));
    grid-gap : 10px;
    padding : 10px;
    margin : 10px;
    border-radius : 10px;
    flex-grow : 1;
    position : relative;

`

export default CertificateTemplatesGrid