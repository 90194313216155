import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material'; 
import { MailsContext } from '../Mails';

const TopBar = () => {

    const { state, setState } = React.useContext(MailsContext);

    const rendering = () => {
        return (
            <TopBarSC>
                <input type='text' />
                <Button
                    variant='contained'
                    color='primary'
                    onClick={() => setState({...state, addTemplateToggled : true})}
                >
                    Add template
                </Button>
            </TopBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TopBarSC = styled.div`
    min-width : 100%;
    min-height : 40px;
    padding : 15px;
    padding-bottom : 0;
    box-sizing : border-box;
    display : flex;

    input {
        flex-grow : 1;
        padding : 5px;
        border : none;
        border-radius : 5px;
        outline : none;
        box-sizing : border-box;
        font-size : 1rem;
        font-family : inherit;
        background-color : white;
    }

    button {
        margin-left : 10px;
    }
`

export default TopBar