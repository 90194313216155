import React from 'react';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment'; 
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const TrainingCard = ({ instance, setModalOpened }) => {

    const navigate = useNavigate();

    const rendering = () => {
        return (
            <TrainingCardSC>
                <div className='wrapper-container'>
                    <div className='instance-info'>
                        <h1>{instance.trainingName}</h1>
                        <p className='dates'>{`${moment(instance.startDate).format('DD/MM/YYYY')} - ${moment(instance.dates[instance.dates.length-1]).format('DD/MM/YYYY')}`}</p>
                        <div className='instance-property'>
                            <LocationOnIcon />
                            <p>{instance.location.title}</p>
                        </div>
                        <div className='instance-property'>
                            <PeopleAltIcon />
                            <p>{instance.attendees.length}</p>
                        </div>
                    </div>
                </div>

                <div className='trainer-and-link'>
                    <CloseIcon onClick={() => setModalOpened(false)} />
                        <Button
                            variant='contained'
                            color='primary'
                            onClick={() => navigate(`/sessions/${instance._id}`)}
                        >
                            Open workshop
                        </Button>
                    
                </div>

            </TrainingCardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingCardSC = styled.div`
    display : flex; 
    flex-direction : column;
    background-color : white;
    position : absolute;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    z-index : 1000;
    min-width : 270px;
    padding : 20px;
    margin-bottom : 40px;
    position : fixed; 

    .instance-info {
        max-width : 85%;

        h1 {
            margin : 0;
            padding : 0; 
            font-weight : 300;
            font-size : 20px;
        }

        .dates {
            font-size : 12px;
            color : grey;
            margin-bottom : 10px;
        }

        .instance-property {
            display : flex;
            align-items : center;
            font-size : 14px;
            font-weight : 300;

            &>svg {
                font-size : 16px;
                margin-right : 3px;
            }
        }
    }

    .trainer-and-link {
        right : 15px;
        position : absolute;
        display : flex; 
        flex-direction : column;
        align-items : flex-end;
        justify-content : space-between;
        min-height : 75%;


        svg {
            cursor : pointer;
            font-size : 20px;

        }
    }


`

export default TrainingCard