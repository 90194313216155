import React from 'react';
import styled from 'styled-components';

const TrainingFeedBackForm = ({ visible, surveys, state, setState }) => {

    const rendering = () => {
        return (
            <TrainingFeedBackFormSC style={{ display : visible === 'visible' ? 'flex' : 'none'}}>
                <p className='title-select-certificate'>Training feedback form</p>
                <div className='certificate-templates-grid'>

                    {surveys.map((survey, index) => {

                        const isSelected = state.selectedSurvey && state.selectedSurvey._id === survey._id;

                        return (
                            <div 
                                key={survey._id} 
                                className={`template-item ${isSelected && 'selected'}`}
                                onClick={() => setState({...state, selectedSurvey : survey})}    
                            >
                                <div className='background-image' style={{backgroundImage : `url(https://www.eventbrite.co.uk/blog/wp-content/uploads/2022/06/Post-Event-Survey-scaled.jpg)`}}></div>
                                <div className='template-info'>
                                    <p className='template-name'>{survey.surveyName}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </TrainingFeedBackFormSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingFeedBackFormSC = styled.div`
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    max-height : 100%;
    padding : 10px;
    box-sizing : border-box;
    background-color : #fafafa;

    .title-select-certificate {
        font-size : 1rem;
        padding : 10px;
    }

    .certificate-templates-grid {
        display : grid;
        grid-template-columns : repeat(auto-fill, minmax(170px, 1fr));
        grid-gap : 10px;
        padding : 10px;
        padding-left : 0;
        flex-grow : 1;
        overflow-y : scroll;
        overflow-x : hidden;
        height : 100px;

        .template-item {
            display : flex;
            flex-direction : column;
            align-items : center;
            padding : 10px;
            border-radius : 10px;
            background-color : white;
            min-height : 280px;
            max-height : 280px;

            &:hover {
                cursor : pointer;
                box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.1);
            }

            .background-image {
                min-width : 100%;
                aspect-ratio : 1/1;
                background-size : cover;
                background-position : center;
                border-radius : 10px;
            }

            .template-info {
                display : flex;
                flex-direction : column;
                padding : 25px;
                width : 100%;

                .template-name {
                    font-size : 1.2em;
                    font-weight : 600;
                    padding-bottom : 0;

                }

                .template-text {
                    font-size : 0.8em;
                }
            }
        }   

        .selected {
            border : 3px solid green;
        }
    }
`

export default TrainingFeedBackForm