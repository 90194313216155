import React from 'react';
import styled from 'styled-components';

const TrainingProperties = () => {

    const rendering = () => {
        return (
            <TrainingPropertiesSC>
                <p>properties</p>
            </TrainingPropertiesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingPropertiesSC = styled.div`
    
`

export default TrainingProperties