import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividualTraining';
import { useNavigate } from 'react-router-dom';

const SubscribeButton = () => {

    const { state, setState } = React.useContext(IndividualTrainingContext);
    const navigate = useNavigate();
    const navToSignUp = () => {
        navigate(`/sign-up/${state.currentInstance.trainingId}/${state.currentInstance._id}` , { replace : true });
    }

    const rendering = () => {
        return (
            <SubscribeButtonSC>
                <button className='button' onClick={() => navToSignUp()}>Sign up</button>
            </SubscribeButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SubscribeButtonSC = styled.div`
    position : relative; 
    padding : 10px;
    padding-left : 0;
`

export default SubscribeButton