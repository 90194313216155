import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const AddTemplateHeader = ({ addTemplate, state, setState }) => {

    const rendering = () => {
        return (
            <AddTemplateHeaderSC>
                <p>Add template</p>
                <div className='buttons'>
                    <Button
                        variant='contained'
                        color='primary'
                        style={{marginRight : '10px'}}
                        onClick={() => setState({...state, individualTemplate : null})}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => addTemplate()}
                    >
                        Save
                    </Button>
                </div>
            </AddTemplateHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTemplateHeaderSC = styled.div`
    display : flex;
    flex-direction : row;
    justify-content : space-between;
    align-items : center;
    padding : 5px;   

`

export default AddTemplateHeader