import React from 'react';
import styled from 'styled-components';

const TrainingDates = () => {

    const rendering = () => {
        return (
            <TrainingDatesSC>
                <p>training dates</p>
            </TrainingDatesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingDatesSC = styled.div`
    
`

export default TrainingDates