import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { IndividualTouchPointContext } from '../IndividualTouchPoint';

const IndividualTouchPointFooter = () => {

    const { currentMenu, setCurrentMenu, closeIndividualTouchPoint, saveFunction, mailScheduleState } = React.useContext(IndividualTouchPointContext);

    const rendering = () => {
        return (
            <IndividualTouchPointFooterSC>
                <Button
                    variant='outlined'
                    color='primary'
                    onClick={closeIndividualTouchPoint}
                >
                    Cancel
                </Button> 
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setCurrentMenu(currentMenu - 1)}
                        disabled={currentMenu === 1}
                    >
                        Previous
                    </Button>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => setCurrentMenu(currentMenu + 1)}
                        disabled={currentMenu === 2}
                    >
                        Next
                    </Button>
                    {currentMenu === 2 && <Button
                        variant='contained'
                        color='primary'
                        onClick={() => saveFunction()}
                        disabled={!mailScheduleState.mailTemplate}
                    >
                        SAVE
                    </Button>
                    }

            </IndividualTouchPointFooterSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTouchPointFooterSC = styled.div`
    padding : 20px;
    display : flex;
    justify-content : flex-end;
    min-height : 30px;
    border-top : 1px solid #e0e0e0;
    
    button {
        margin-left : 10px;
    }
`

export default IndividualTouchPointFooter