import React from 'react';
import styled from 'styled-components';
import FolderSelection from './FolderSelection';
import FilesList from './FilesList';

export const CourseMaterialsContext = React.createContext();

const CourseMaterials = () => {
    const folders = [{
        name : 'Day 1',
        files : [
            {
                name : 'file 1',
                type : 'pdf',
                uploadedBy : 'Maxim Polderman'
            },
            {
                name : 'file 2',
                type : 'ppt',
                uploadedBy : 'Maxim Polderman'

            }
        ]
    },
    {
        name : 'Day 2',
        files : [
            {
                name : 'file 3',
                type : 'pdf'
            },
            {
                name : 'file 4',
                type : 'pdf'
            }
        ]
    },
    {
        name : 'Day 3',
        files : [
            {
                name : 'file 1',
                type : 'pdf'
            },
            {
                name : 'file 2',
                type : 'pdf'
            }
        ]
    }]

    const [activeFolder, setActiveFolder] = React.useState(folders[0]);

    const rendering = () => {
        return (
            <CourseMaterialsContext.Provider value={{
                activeFolder, 
                setActiveFolder
            }}>
                <CourseMaterialsSC className='dashboard-container'>
                    <h1 className='title'>Course materials</h1>
                    <FolderSelection folders={folders}/>
                    <FilesList 
                        files={activeFolder.files}
                    />
                </CourseMaterialsSC>
            </CourseMaterialsContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CourseMaterialsSC = styled.div`
    min-width : 500px;   
`

export default CourseMaterials