import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Navigation = () => {

    const items = [{
        name : 'Overview',
        path : ''
    }]
    
    const [activeIndex, setActiveIndex] = React.useState(0);

    const navigate = useNavigate();
    const clickItem = (index, item) => {
        setActiveIndex(index);
        navigate(item.path)
    }

    const rendering = () => {
        return (
            <NavigationSC>
                {items.map((item, index) => {

                    const isSelected = activeIndex === index;   

                    return (
                        <p 
                            key={index}
                            onClick={() => clickItem(index, item)}    
                            className={`menu-item ${isSelected ? "selected" : ""}`}	
                        >{item.name}</p>
                    );
                })}
            </NavigationSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const NavigationSC = styled.div`
    display : flex;
    padding : 10px;
    border-radius : 5px;
    padding-top : 20px;
    padding-bottom : 20px;
    p {
        margin-right : 10px;
        font-size : 16px;
        cursor : pointer;
    }

    .selected {
        font-weight : 600;
    }
`

export default Navigation