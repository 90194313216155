import React from 'react';
import styled from 'styled-components';

const MultipleChoice = ({ item, answers, setAnswers }) => {

    const onChangeFunction = (index) => {
        const responses = answers; 

        const responseObject = {
            question : item.question,
            possibleAnswers : item.options,
            type : 'multiple-choice',
            answerIndex : index +1
        }

        // check if the question is already answered
        const questionIndex = responses.findIndex(response => response.question === item.question);
        if(questionIndex === -1) {
            responses.push(responseObject);
        } else {
            responses[questionIndex] = responseObject;
        };

        setAnswers(responses);

    }
    
    const rendering = () => {
        return (
            <MultipleChoiceSC>
                <p className='question'>{item.question}</p>
                {item.options.map((option, index) => { 
                    return (
                        <div key={index} className='option-value'>
                            <input 
                                type="radio" 
                                id={option} 
                                name={item.question} 
                                value={option} 
                                onChange={() => onChangeFunction(index)}
                                style={{marginRight : '10px'}}
                            ></input>
                            <label htmlFor={option}>{option}</label>
                        </div>
                    )})
                }
            </MultipleChoiceSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MultipleChoiceSC = styled.div`
    .question {
        font-size : 1.2rem;
        padding-bottom : 10px;

    }

    .option-value {
        padding : 5px;
        font-size : 1.1rem;
        color : #353b37;
        display : flex; 
        align-items : center;
    }
`

export default MultipleChoice