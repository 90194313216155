import React from 'react';
import styled from 'styled-components';
import { AddTrainingContext } from '../IndividualTraining';
import BasicInfo from './basicInfo/BasicInfo';
import TrainingMailSchedule from './TrainingMailSchedule';
import TrainingFeedBackForm from './feedbackForm/TrainingFeedbackForm';
import TrainingCertificates from '../certificates/TrainingCertificates';
import Review from './review/Review';

const MainContent = ({ 
    register, 
    errors,
    surveys,
    mailTemplates,
    state,
    setState,
    defaultTouchPoints, 
    formValues,
    trigger,
    imageURL,
    setImageURL,
    file,
    setFile,
    activeItem
}) => {

    const { navigationItems, setActiveItem } = React.useContext(AddTrainingContext);

    const rendering = () => {
        return (
            <MainContentSC>

                <BasicInfo 
                    visible={activeItem === 0}
                    register={register} 
                    errors={errors}
                    trigger={trigger}    
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    file={file}
                    setFile={setFile}
                />

                <TrainingMailSchedule
                    visible={activeItem === 2}
                    register={register} 
                    errors={errors}
                    state={state}
                    setState={setState}
                    mailTemplates={mailTemplates}
                    defaultTouchPoints={defaultTouchPoints}
                />

                <TrainingFeedBackForm
                    visible={activeItem === 3}
                    surveys={surveys}
                    state={state}
                    setState={setState}
                />

                <TrainingCertificates
                    visible={activeItem === 4}
                    state={state}
                    setState={setState}
                />
                   
                <Review
                    visible={activeItem === 5}
                    state={state}
                    setState={setState}
                    formValues={formValues}
                    trigger={trigger}
                    errors={errors}
                    activeItem={activeItem}
                    imageURL={imageURL}
                />
                </MainContentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MainContentSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;
    max-height : 100%;
    overflow-y : scroll;
    border-bottom : 1px solid #ccc;

`

export default MainContent