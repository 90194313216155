import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../IndividiualTraining';
import AddOrEditTraining from '../../addOrEditTrainingComponent/AddOrEditTraining';
import { AppContext } from '../../../App';

const EditTraining = () => {

    const { appState } = React.useContext(AppContext);
    const { setEditMode, state, setState } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <EditTrainingSC>
                {/* <p>edit</p>
                <button onClick={() => setState({...state, trainingToEdit : null})}>save</button> */}
                <AddOrEditTraining
                    appState={appState}
                    state={state}
                    setState={setState}
                    trainingToEdit={state.trainingToEdit}
                    closeWindowFunction={() => setState({...state, trainingToEdit : null})}
                    // setTraining={setTraining}
                    // setMailJobs={setMailJobs}
                />
            </EditTrainingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const EditTrainingSC = styled.div`
    flex : 1;
    overflow: hidden;
    display : flex;
`

export default EditTraining