import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const items = [{
    name : 'Dashboard',
    path : '/'
},{
    name : 'Course materials',
    path : '/course-materials'
},{
    name : 'Exercises',
    path : '/exercises'
},{
    name : 'Chat',
    path : '/chat'
}]




const Sidebar = () => {
    const [activeIndex, setActiveIndex] = React.useState(0);

    const navigate = useNavigate();
    const clickItem = (index, item) => {
        setActiveIndex(index);
        navigate(item.path)
    }

    const rendering = () => {
        return (
            <SidebarSC>
                {items.map((item, index) => {

                    const isSelected = activeIndex === index;   

                    return (
                        <p 
                            key={index}
                            onClick={() => clickItem(index, item)}    
                            className={`menu-item ${isSelected ? "selected" : ""}`}	
                        >{item.name}</p>
                    );
                })}
            </SidebarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SidebarSC = styled.div`
    background-color : white;
    min-width : 220px;
    max-width : 220px;
    display : flex;
    flex-direction : column;
    box-sizing : border-box;
    transition : 0.2s;
    overflow : hidden;
    margin-top : 10px;
    border-radius : 0 10px 0 0;

    .menu-item {
        padding : 10px;
        cursor : pointer;
        transition : 0.2s;
    }

    .selected {
        font-weight : 600;
    }

`

export default Sidebar