import React from 'react';
import styled from 'styled-components';
import Header from './Header';
import Certificate from './Certificate/Certificate';
import Survey from './Survey/Survey';
import MailSchedule from './MailSchedule/MailSchedule';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import TrainingOverview from './TrainingOverview/TrainingOverview';
import EditTraining from './EditMode/EditTraining';
import Actions from './Actions/Actions';
import SendOutSurveyModal from '../IndividualTraining/SendOutSurveyModal/SendOutSurveyModal';

export const IndividualTrainingContext = React.createContext();

const IndividualTraining = () => {
    const trainingId = window.location.hash.split('/')[2];

    const [individualTraining, setIndividualTraining] = React.useState(null);
    const [editMode, setEditMode] = React.useState(false);
    const [state, setState] = React.useState({
        trainingToEdit : null
    });


    React.useEffect(() => {
        const getTraining = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings/${trainingId}`);
                setIndividualTraining(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }
        getTraining();
    }, []);

    const rendering = () => {
        if(individualTraining === null) {
            return <div>loading...</div>
        }
        
        return (
            <IndividualTrainingContext.Provider 
                value={{
                    individualTraining,
                    setEditMode,
                    state,
                    setState
                }}>

                {!state.trainingToEdit ? 
                <IndividualTrainingSC>




                    <Header individualTraining={individualTraining}/>
                    <div style={{display : 'flex', flex : 1}}>
                        <TrainingOverview individualTraining={individualTraining} />
                        <div style={{ display : 'flex', flex : 1, flexDirection : 'column'}}>
                            <div style={{ display : 'flex', flexGrow : 1 }}>
                                <Actions />
                                <MailSchedule />
                            </div>
                            <div style={{ display : 'flex', flex : 1, minHeight : '220px' }}>
                                <Certificate />
                                <Survey />
                            </div>
                        </div>
                    </div>

                </IndividualTrainingSC>
                : 
                <EditTrainingSC>
                    <EditTraining />
                </EditTrainingSC>
                }
            </IndividualTrainingContext.Provider>
        );

    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTrainingSC = styled.div`
    display : flex; 
    flex-direction : column;
    flex : 1;
    overflow : hidden;

    h1 {
        padding : 0;
        margin : 0;
        font-size : 16px;
        font-weight : 400;
    }

    h3 {
        padding : 0;
        margin : 0;
        font-size : 16px;
        font-weight : 400;
    }

    h4 {
        padding : 0;
        margin : 0;
        font-size : 16px;
        font-weight : 300;
    }
`

const EditTrainingSC = styled.div`
    overflow-y:  hidden;
    flex : 1;
    display : flex;

`

export default IndividualTraining