import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Validate_TitleAndDescription = ({ formValues }) => {

    const rendering = () => {
        return (
            <Validate_TitleAndDescriptionSC>
                <p className='header-title'>{`Title : ${formValues.title ? formValues.title : 'no title'}`}</p>
                <p className='description'>
                    {formValues.description ? formValues.description : 'no description'}
                </p>
            </Validate_TitleAndDescriptionSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const Validate_TitleAndDescriptionSC = styled.div`
    padding : 20px;

    .header-title{  
        font-size : 1.5rem;
        color : #000;
        margin-bottom : 10px;
    }


`

export default Validate_TitleAndDescription