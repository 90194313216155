import React from 'react';
import styled from 'styled-components';
import TrainingInfo from './TrainingInfo';
import Actions from './Actions/Actions';
import RegisteredUsers from './RegisteredUsers/RegisteredUsers';
import Header from '../Header/Header';
import MailJobs from './MailJobs/MailJobs';
import SurveyAndCertificate from './SurveyAndCertificate/SurveyAndCertificate';
import JobPreviewComponent from './JobPreview/JobPreviewComponent';
import { IndividualSessionContext } from '../IndividualSession';

const Dashboard = ({ individualSession }) => {

    const { jobPreview } = React.useContext(IndividualSessionContext);

    const rendering = () => {
        return (
            <DashboardSC>

                { jobPreview && <JobPreviewComponent /> }

                <Header individualSession={individualSession}/>
                <div style={{display : 'flex'}}>
                    <div style={{display :'flex',  minWidth : '340px', maxWidth : '340px', flexDirection : 'column', flex : 1}}>
                        <TrainingInfo individualSession={individualSession} />
                        <MailJobs individualSession={individualSession} />
                    </div>
                    <div style={{display :'flex',  minWidth : '340px', maxWidth : '340px', flexDirection : 'column', flex : 1}}>
                        <Actions individualSession={individualSession}/>
                        <SurveyAndCertificate individualSession={individualSession}/>
                    </div>
                    <RegisteredUsers individualSession={individualSession} />
                </div>
            </DashboardSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DashboardSC = styled.div`
    display : flex; 
    flex-direction : column;

    .title-container {
        display : flex; 
        align-items : center;
        padding : 15px; 
        
        .title {
            font-size : 1em;
            font-weight : 500;
            color : #333;
            margin : 0;
            padding : 0;
        }

        svg {
            margin-right : 10px;
            font-size : 18px;
        }
    }

    h1 {
        padding : 0;
        margin : 0;
        font-size : 16px;
        font-weight : 400;
    }

    h3 {
        padding : 0;
        margin : 0;
        font-size : 14px;
        font-weight : 400;
        color : #595959
    }

    h4 {
        padding : 0;
        margin : 0;
        font-size : 14px;
        font-weight : 300;
        color : #595959
    }


`

export default Dashboard