import React from 'react';
import styled from 'styled-components';
import DropdownSelect from '../../../GeneralComponents/InputFields/DropDownSelect';
import { IndividualTouchPointContext } from '../IndividualTouchPoint';

const TimingModeTrigger = ({}) => {

    const { setTrigger, trigger } = React.useContext(IndividualTouchPointContext);

    console.log('yu trigger : ', trigger);

    const triggerOptions = {
        object : [{
            name : 'sign-up'
        },{
            name : 'Cancel'
        },{
            name : 'Change in course'
        }],
        key : 'name'
    }

    const rendering = () => {
        return (
            <TimingModeTriggerSC>
                <p className='page-title'>Select trigger</p>
                <DropdownSelect 
                    label='Trigger for the e-mail'
                    options={triggerOptions}
                    setValueFunction={setTrigger}
                    selectedValue={trigger}
                    className='dropdown-select'
                />
                <p className='sub-information'>
                    Attendant will receive the e-mail as soon as he signs up for the training
                </p>
            </TimingModeTriggerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    )
}

const TimingModeTriggerSC = styled.div`
    flex-grow : 1;

    >div {
        margin-top : 20px;
    }
    
    .sub-information {
        font-size : 0.8rem;
        margin-top : 10px;
        padding-left : 10px;
        color : #666;
    }

    .page-title {
        font-size : 1.2rem;
        box-sizing : border-box;
        padding-bottom : 0;
    }

    .inputs-row {
        display : flex;
        justify-content : space-between;
    }


`

export default TimingModeTrigger