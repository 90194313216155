import React from 'react';
import styled from 'styled-components';
import CustomInputField from './CustomInputField';

const PropertiesNew = ({ trainingProperties }) => {

    const rendering = () => {
        return (
            <PropertiesNewSC>
                
                {trainingProperties.map(property => {   

                    if(property.show) {
                        return (
                            <CustomInputField 
                                property={property}
                            />
                        )
                    }
                })}
            </PropertiesNewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const PropertiesNewSC = styled.div`
    display : grid; 
    grid-template-columns : repeat(3, 1fr);
    grid-gap : 10px;
`

export default PropertiesNew