import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../variables/Colors'; 
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import NotesIcon from '@mui/icons-material/Notes';
import ListIcon from '@mui/icons-material/List';
import LabelIcon from '@mui/icons-material/Label';
import { AddInstanceContext } from '../AddInstance';

const AddInstanceNavigation = ({}) => {

    const { currentMenu, setCurrentMenu } = React.useContext(AddInstanceContext);

    const navigationItems = [{
        name : 'Dates', 
        number : 1,
        icon : <AccessTimeIcon />
    },{
        name : 'Properties', 
        number : 2,
        icon : <NotesIcon />
    }]

    const rendering = () => {
        return (
            <AddInstanceNavigationSC>
                <div className='navigation-items'>
                    {navigationItems.map((item, index) => {
                        return (
                            <>
                            <div 
                                key={index}
                                className={`nav-item ${currentMenu === item.number ? 'active' : ''}`}
                                onClick={() => setCurrentMenu(item.number)}
                            >
                                {item.icon}
                                <p className='menu-item-name'>{item.name}</p>
                            </div>
                            {index !== navigationItems.length - 1 && <hr/>}
                            </>
                        );
                    })}
                </div>
            </AddInstanceNavigationSC>
        );
    }

    

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddInstanceNavigationSC = styled.div`
    background-color: white;
    min-height : 100px;
    max-height : 100px;
    border-right: 1px solid #e0e0e0;
    border-right : 1px solid #e0e0e0;
    display : flex;
    justify-content : center;
    border-bottom : 1px solid #e0e0e0;

    hr {
            border: 0.5 solid #e0e0e0; /* Customize the color and thickness */
            width: 100%; /* Customize the width */
            margin: 50px auto; /* Center the line */
    }

    .navigation-items {
        max-width : 80%;
        min-width : 80%;
        justify-content : space-between;
        display : flex;
    }

    .nav-item {
        min-height : 80px;
        aspect-ratio : 1;
        display : flex;
        flex-direction : column;
        justify-content : center;
        align-items : center;
        color : darkgrey;

        &:hover {
            cursor: pointer;
        }

        .menu-item-name {
            font-size : 12px;
        }
    }

    .active {
        color : ${colors.primary};
    }
`

export default AddInstanceNavigation;