import React from 'react';
import styled from 'styled-components';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    LabelList,
    ResponsiveContainer,
  } from 'recharts';
  
const BarCharComponent = ({ data }) => {

    const dataToShow = data.map((item) => {
        return {
            name: item._id,
            revenue: item.totalRevenue
        }
    });

    const tickFormatter = (value, index) => {
        const limit = 15; // put your maximum character
        if (value.length < limit) return value;
        return `${value.substring(0, limit)}...`;
     };

    const rendering = () => {
        return (
            <BarCharComponentSC>
                <ResponsiveContainer width="100%" height="100%">
                    <BarChart width={500} height={300} data={dataToShow} margin={{ top: 20, right: 30, left: 20, bottom: 40 }}>
                        <XAxis dataKey="name" angle={0} textAnchor="end" tickFormatter={tickFormatter}>
                            <LabelList dataKey="name" angle={0} />
                        </XAxis>
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="revenue" fill="#1976d2" />
                        <Bar width={15} />
                    </BarChart>
                </ResponsiveContainer>
            </BarCharComponentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const BarCharComponentSC = styled.div`
    min-height : 100%;
`

export default BarCharComponent