import React from 'react';
import styled from 'styled-components';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PaidIcon from '@mui/icons-material/Paid';
import { IndividualTrainingContext } from './IndividualTraining';

const AttendeeRecord = ({ attendee }) => {

    const { state , setState } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <AttendeeRecordSC
                onClick={() => setState({ ...state, individualAttendee : attendee })}
            >
                <div className='left'>
                    <div className='profile-pic' style ={{ backgroundImage : 'url(https://mui.com/static/images/avatar/1.jpg)'}}></div>

                    <div className='text-info'>
                        <p>{`${attendee.firstName} ${attendee.lastName}`}</p>
                        <p style={{ fontSize : '14px'}}>{attendee.company} | {attendee.role}</p>    
                    </div> 
                </div> 

                <div className='statusses'>
                    <div className='confirmation status-item'>
                        <AssignmentIcon style={{ color : attendee.confirmed ? 'green' : 'grey' }}/>
                    </div>
                    <div className='payment status-item'>
                        <PaidIcon style={{ color : attendee.paymentSettled ? 'green' : 'grey' }}/>
                    </div>

                </div>
            </AttendeeRecordSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AttendeeRecordSC = styled.div`

    display : flex;
    justify-content : space-between;
    padding : 10px;
    border-bottom : 1px solid lightgrey;

    &:hover {
        background-color : #f9f9f9;
        cursor : pointer;
    }

    .left {
        display : flex; 
        padding : 10px;
        align-items : center;
    }

    .statusses {
        display : flex; 
        padding : 10px;
        align-items : center;
    }

    .left .profile-pic {
        width : 50px;
        height : 50px;
        border-radius : 50%;
        background-size : cover;
        background-position : center;
        margin-right : 10px;
    }

    .text-info {   
        display : flex;
        flex-direction : column;
        justify-content : center;
    }

    .status-item {
        min-height : 50px;
        min-width : 50px;
        border-radius : 100%;
        border : 1px solid lightgrey;
        background-color : white;
        display : flex;
        justify-content : center;
        align-items : center;
        color : white;

        &:first-child {
            margin-right : -10px;
        };

        &>svg {
            color : grey;
        }
    }
`

export default AttendeeRecord



