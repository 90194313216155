import React, { useContext } from 'react';
import styled from 'styled-components';
import IndividualTrainerHeader from './IndividualTrainerHeader';
import Modal from '../../GeneralComponents/Modal';
import PictureOpload from '../../GeneralComponents/PictureOpload';
import ImageUpload from '../../GeneralComponents/ImageUpload';
import IndividualTrainerInfo from './IndividualTrainerInfo';
import { useForm } from 'react-hook-form';
import { AppContext } from '../../../App';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import { useAuth0 } from '@auth0/auth0-react';

const IndividualTrainer = ({ trainer, file, setFile, imageURL, setImageURL, trainersState, setTrainersState }) => {
    const { user } = useAuth0();
    const { appState } = useContext(AppContext);
    const { tenant } = appState;
    const form = useForm();
    const { register, handleSubmit, formState: { errors } } = form;
    const formRef = React.useRef();

    React.useEffect(() => {
        if(trainer) {
            console.log('trainer : ', trainer)
            setImageURL(trainer.imageUrl);
        }
    }, [trainer]);

    const saveTrainer = (data) => {

        setTrainersState({
            ...trainersState,
            loading : true
        });

        const newTrainer = {
            organizationId : user.org_id,
            trainerId : trainersState.individualTrainer._id ? trainersState.individualTrainer._id : '',
            firstName : data.firstName,
            lastName : data.lastName,
            email : data.email,
            phone : data.phone,
            shortDescription : data.description,
            linkedIn : data.linkedIn,
            tenant,
            hasThumbnailUrl : !!imageURL,
        }

        const formData = new FormData();
        formData.append('trainerImage', file);
        formData.append('trainerInfo', JSON.stringify(newTrainer));

        const saveTrainerFunction = async () => {
            try {
                const response = await Axios.post(`${backEndAdress}/api/trainers/create-or-update/`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                setTrainersState({
                    ...trainersState,
                    individualTrainer : null,
                    loading : false,
                    trainers : response.data
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveTrainerFunction();
    }


    const rendering = () => {
        return (
            <form 
                noValidate 
                ref={formRef}
                onSubmit={handleSubmit(saveTrainer)}>                
                <Modal>
                    <IndividualTrainerSC>
                        <IndividualTrainerHeader 
                            trainer={trainer}
                            formRef={formRef}    
                        />
                        <div className='trainer-content'>
                            <div className='upload-image-container'>
                                <ImageUpload
                                    trainer={trainer}
                                    file={file}
                                    setFile={setFile}
                                    imageURL={imageURL}
                                    setImageURL={setImageURL}
                                    id={'upload-trainer-image'}
                                    description={'Image of trainer'}

                                />
                            </div>
                                <IndividualTrainerInfo 
                                    register={register}
                                    errors={errors}
                                    trainer={trainer}
                                    form={form}
                                />
                            {/* <IndividualTrainerImage />
                            <IndividualTrainerInfo /> */}
                        </div>
                    </IndividualTrainerSC>
                </Modal>
            </form>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTrainerSC = styled.div`
    min-height : 550px;
    min-width : 800px;
    background-color : white;
    display : flex;
    flex-direction : column;
    border-radius : 10px;
    overflow : hidden;
    box-shadow : 0px 0px 10px 0px rgba(0,0,0,0.3);

    .trainer-content {
        display : flex;
        flex-grow : 1;

        &>.upload-image-container {
            width : 35%;
            height : 250px;
            padding : 20px;
            box-sizing : border-box;
            display : flex;
        }
    }
`   

export default IndividualTrainer