import React from 'react';
import styled from 'styled-components';

const MailSchedule = () => {

    const rendering = () => {
        return (
            <MailScheduleSC>
                <h1>Mail schedule</h1>
            </MailScheduleSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailScheduleSC = styled.div`
    background-color : white;
    display : flex;
    flex-direction : column;
    border-radius : 2px;
    margin : 20px;
    margin-left : 0;
    margin-top : 0;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);    
    flex : 1;

    h1 {
        padding : 10px;
        
    }
`

export default MailSchedule