import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const SearchbarTemplates = ({ state, setState, searchQuery, setSearchQuery}) => {

    const rendering = () => {
        return (
            <SearchbarTemplatesSC>
                <input 
                    type='text' 
                    placeholder='search templates'
                    onChange={(e) => {setSearchQuery(e.target.value)}}    
                />
            </SearchbarTemplatesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SearchbarTemplatesSC = styled.div`
    padding : 15px;
    display : flex; 

    input {
        padding : 10px;
        border : 1px solid #3f51b5;
        border-radius : 5px;
        min-width : 100%;
        box-sizing : border-box;
        font-size : inherit;
        font-family : inherit;
    }
`

export default SearchbarTemplates