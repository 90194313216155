import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';

const Test = () => {

    const rendering = () => {
        return (
            <TestSC>
                <p>testing</p>
                <button> get list of cron jobs</button>
            </TestSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TestSC = styled.div`

`

export default Test