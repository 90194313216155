import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import EmailScheduleHeader from './EmailScheduleHeader';
import EmailTouchPointsOverview from './EmailTouchPointsOverview';
import { Button } from '@mui/material';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import { AppContext } from '../../App';

export const EmailScheduleContext = React.createContext(); 

const EmailSchedule = ({ currentInstance, trainingTouchPoints }) => {

    const { appState } = React.useContext(AppContext);
    const [emailTemplates, setEmailTemplates] = React.useState([]);

    const [state, setState] = React.useState({
        touchPoints : [],
        individualTouchPoint : null,
        currentEmailTemplate : null,
        loadingEmailTemplate : false,
        templateFile : null,
        reRenderer : 1,
        handleBarVariables : null
    });

    const [training, setTraining] = React.useState(null);
    const { touchPoints, individualTouchPoint } = state;

    React.useEffect(() => {

        const getTouchPointsForInstance = async () => {
            try {
                const responseObject = await Axios.get(`${backEndAdress}/api/touch-points/by-instance-id/${currentInstance._id}`);
                const touchPoints = responseObject.data[0].touchPoints.length > 0 ? responseObject.data[0].touchPoints : trainingTouchPoints;
                setState({
                    ...state,
                    touchPoints : touchPoints
                });
            } catch (error) {
                console.log(error);
            }
        }

        getTouchPointsForInstance();

    }, []);

    React.useEffect(() => {

        const getAllEmailTemplates = async () => {  
            try {
                const emailTemplates = await Axios.get(`http://localhost:8080/api/mail-templates/find-all/${appState.company}`);
                setEmailTemplates(emailTemplates.data)
            } catch (error) {
                console.log(error);
            }
        }

        getAllEmailTemplates();

    }, [state.reRenderer]);

    React.useEffect(() => {
        const getTraining = async () => {
            try {
                const training = await Axios.get(`${backEndAdress}/api/trainings/${currentInstance.trainingId}`);
                console.log('fetched training', training.data);
                setTraining(training.data);
            } catch (error) {
                console.log(error);
            }
        }

        getTraining();
    },[currentInstance.trainingId]);

    const rendering = () => {

        return (
            <EmailScheduleContext.Provider value={{
                state, 
                setState,
                emailTemplates,
                currentInstance
            }}>
                <Modal>
                    <EmailScheduleSC>

                        <EmailScheduleHeader/>

                        {/* <div className='sidebar-navigation-touchpoints'>
                            {state.individualTouchPoint && 
                                <IndividualTouchPoint 
                                    individualTouchPoint={individualTouchPoint} 
                                    currentInstance={currentInstance}
                                    training={training}
                                />
                            }
                        </div> */}

                        <EmailTouchPointsOverview/>
                    </EmailScheduleSC>
                </Modal>
            </EmailScheduleContext.Provider >
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const EmailScheduleSC = styled.div`
    background-color : white;
    width : 80%;
    height : 80%;
    max-height : 80%;
    overflow-y : hidden;
    border-radius : 5px;
    display : flex; 
    flex-direction : column;
    padding : 15px;
    box-sizing : border-box;
    position : relative;
    background-color : #fafafa;


`

export default EmailSchedule