import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const JobPreviewHeader = ({ setJobPreview }) => {

    const rendering = () => {
        return (
            <JobPreviewHeaderSC>
                <Button 
                    variant='outlined'
                    onClick={() => setJobPreview (null)}
                >
                    close
                </Button>
            </JobPreviewHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const JobPreviewHeaderSC = styled.div`
    position : absolute; 
    top : 10px;
    right : 100px;

    &>button {
        position : fixed; 

    }
`

export default JobPreviewHeader