import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import TestMailHeader from './TestMailHeader';
import TestMailSelectors from './TestMailSelectors';

const TestMailPopUp = ({ state , setState, allTrainings, allInstances, setCurrentTraining, trainingRef, instanceRef, testMailInputRef, sendTestMailFunction, setCurrentInstance}) => {

    const rendering = () => {
        return (
            <Modal>
                <TestMailPopUpSC>
                    <TestMailHeader state={state} setState={setState}/>

                    <div className='content'>
                        <TestMailSelectors 
                            allTrainings={allTrainings}
                            setCurrentTraining={setCurrentTraining}    
                            setCurrentInstance={setCurrentInstance}
                            trainingRef={trainingRef}
                            instanceRef={instanceRef}
                            allInstances={allInstances}

                        />
                        <div className='action-panel'>
                            <form onSubmit={(e) => sendTestMailFunction(e)}>
                                <input type='email' placeholder='Email' ref={testMailInputRef} defaultValue={'maxim.polderman@gmail.com'}/>
                                <button>Send Test Mail</button>
                            </form>
                        </div>
                    </div>

                </TestMailPopUpSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TestMailPopUpSC = styled.div`
    min-width : 80vw;
    min-height : 80vh;
    background-color : white;
    display : flex;
    flex-direction : column;

    .content {
        flex-grow : 1;

    }
`

export default TestMailPopUp