import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../IndividualTraining/IndividualTraining';

const IndividualAttendeeHeader = () => {

    const { state, setState } = React.useContext(IndividualTrainingContext);

    const rendering = () => {
        return (
            <IndividualAttendeeHeaderSC>
                <p>title</p>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setState({ ...state, individualAttendee : null })}
                >
                    Close
                </Button>
            </IndividualAttendeeHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualAttendeeHeaderSC = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : center;
`

export default IndividualAttendeeHeader