import React from 'react';
import styled from 'styled-components';
import TextInputField from '../../../GeneralComponents/InputFields/TextInputField';
import { IndividualTouchPointContext } from '../IndividualTouchPoint';

const NamingTouchPoint = () => {

    const { touchPointName, setTouchPointName, timingMode , trigger, beforeOrAfter, numberOfDays, suggestedNamevalue } = React.useContext(IndividualTouchPointContext);

    console.log('suggested value : ', suggestedNamevalue);

    const rendering = () => {
        return (
            <NamingTouchPointSC>
                <TextInputField
                    typedValue={touchPointName}
                    suggestedValue={suggestedNamevalue}
                    setValueFunction={setTouchPointName}
                    label='name'
                />
            </NamingTouchPointSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const NamingTouchPointSC = styled.div`
    margin : 20px
`

export default NamingTouchPoint