import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import Avatar from '@mui/material/Avatar';

const RegistrationItem = ({ registration }) => {


   
    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }
      
    function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    const rendering = () => {
        return (
            <RegistrationItemSC>
                <Avatar {...stringAvatar(`${registration.firstName} ${registration.lastName}`)} />
                    <div style={{ marginLeft: '10px' }}>
                        <div className='registration-name'>{`${registration.firstName} ${registration.lastName}`}</div>
                        <div className='contact-item' style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{registration.email}</span>
                        </div>
                        <div className='contact-item' style={{ display: 'flex', alignItems: 'center' }}>
                            <span>{moment(registration.createdAt).format('DD/MM/YYYY hh:mm')}</span>
                        </div>
                    </div>
            </RegistrationItemSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RegistrationItemSC = styled.div`
    display : flex;
    align-items : center;
    border : 1px solid #C9C9C9;
    padding : 20px;
    border-radius : 10px;
    margin-bottom : 10px;

    .registration-name {
        font-size : 1em;
    }

    .contact-item {
        font-size : 0.8em;
        color : #666;
        font-weight : 300;
    }
`

export default RegistrationItem