import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import { Button } from '@mui/material';

const AddFolderModal = ({ addFolder }) => {

    const rendering = () => {
        return (
            <Modal>
                <AddFolderModalSC>
                    <input type='text' defaultValue='folder-name' id='folder-name-input'/>
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={() => addFolder(document.getElementById('folder-name-input').value)}
                    >
                        Add folder
                    </Button>
                </AddFolderModalSC>
            </Modal>

        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddFolderModalSC = styled.div`
    background-color : white;
    padding : 20px;
    border-radius : 5px;
    display : flex;
    align-items : center;
    justify-content : center;

    >input {
        padding : 10px;
        border : 1px solid lightgrey;
        border-radius : 5px;
        margin-right : 10px;
        outline : none;
        
    }
`

export default AddFolderModal