import React from 'react';
import styled from 'styled-components';
import { UserManagementContext } from '../UserManagement';
import Modal from '../../GeneralComponents/Modal';
import TextInputField from '../../GeneralComponents/InputFields/TextInputField';
import DropdownSelect from '../../GeneralComponents/InputFields/DropDownSelect';
import { Button } from '@mui/material';
import { AppContext } from '../../../App';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';

const IndividualUser = ({ individualUser }) => {
    const { setIndividualUser, users, setUsers } = React.useContext(UserManagementContext);
    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;
    
    const [email, setEmail] = React.useState('');
    const [role, setRole] = React.useState('');

    const createUser = async () => {
        try {
            const response = await Axios.post(`${backEndAdress}/api/users/${tenant}`, {
                userEmail : email,
                role : role,
                tenant : appState.tenant,
                userId : individualUser ? individualUser._id : null
            });
            setUsers(response.data);
            setIndividualUser(null);
        } catch (error) {
            console.log(error);
        }
    }
 
    React.useEffect(() => {
        if(individualUser) {
            setEmail(individualUser.userEmail);
            setRole(individualUser.role);
        }
    }, [IndividualUser]);

    const rendering = () => {
        if ( email !== null && role!== null ) {
            return (
                <Modal>
                    <IndividualUserSC>
                        <div className='avatar-container' style={{backgroundImage : 'url(https://t4.ftcdn.net/jpg/07/03/86/11/360_F_703861114_7YxIPnoH8NfmbyEffOziaXy0EO1NpRHD.jpg)'}}>
                        </div>
                        <div className='user-info-container'>
                            <div className='input-fields'>
                                {individualUser._id ? 
                                <p>{individualUser.userEmail}</p>
                                :
                                <TextInputField
                                    label='Email'
                                    typedValue={email}
                                    setValueFunction={setEmail}
                                    suggestedValue={email}
                                />
                                }   


                                <DropdownSelect
                                    options={{
                                        object : [
                                            { role : 'Admin' },
                                            { role : 'Trainer' },
                                            { role : 'Student' },
                                            { role : 'External trainer'}
                                        ],
                                        key : 'role'
                                    }}
                                    setValueFunction={setRole}
                                    selectedValue={role}
                                />
                            </div>

                            <div className='button-container'>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    onClick={createUser}
                                >
                                    Save
                                </Button>
                                <Button
                                    variant='outlined'
                                    color='primary'
                                    style={{marginLeft : '10px'}}
                                    onClick={() => setIndividualUser(null)}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                        </IndividualUserSC>
                </Modal>

            );
        }
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualUserSC = styled.div`
    height : 200px;
    width : 650px;
    background-color : white;
    display : flex; 
    align-items : center;
    padding : 25px;
    border-radius : 10px;
    box-shadow : 0 0 10px rgba(0, 0, 0, 0.1);

    .avatar-container {
        border-radius : 50%;
        height : 110px;
        width : 110px;
        margin-left : 25px;
        margin-right : 25px;
        background-size : cover;
        background-position : center;
    }

    .user-info-container {
        flex-grow : 1;
        margin-left : 25px;

        &>.input-fields {
            display : grid;
            grid-template-columns : 1fr;
            grid-gap : 15px;

            &>p {
                font-size : 1.2em;
                padding-left : 5px;
            }
        }

        display : grid;
        grid-template-columns : 1fr;
        grid-gap : 15px;
    }

`

export default IndividualUser