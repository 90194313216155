import React from 'react';
import styled from 'styled-components';

const variablesHandleBars = [{
    varName : 'trainingName', 
    varValue : 'Training name'
},{
    varName : 'trainingName', 
    varValue : 'Training name'
},{
    varName : 'trainingName', 
    varValue : 'Training name'
},{
    varName : 'trainingName', 
    varValue : 'Training name'
}]

const SetVariables = () => {

    const rendering = () => {
        return (
            <SetVariablesSC>
                {variablesHandleBars.map((variableHandleBar) => {
                    return (
                        <div key={variableHandleBar.varName} className='variable-handle-bar'>
                            <p className='variable-handle-bar-name'>{variableHandleBar.varName}</p>
                            <p className='variable-handle-bar-value'>{variableHandleBar.varValue}</p>
                        </div>
                    );
                })}
            </SetVariablesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SetVariablesSC = styled.div`
    min-width: 200px;
    padding : 15px;

    .variable-handle-bar {
        padding : 7px;
        padding-left : 15px;
        border-radius : 5px;
        box-shadow : 0 0 10px rgba(0,0,0,0.1);
        margin : 10px;
        min-width: 120px;
        background-color : white;
    }   

    .variable-handle-bar-value {
        font-size : 0.7rem;
        color : grey;
    }
`

export default SetVariables