import { colors } from '../../variables/Colors';
import React from 'react';
import styled from 'styled-components';

const TrainingsGridTitleAndSearch = () => {

    const rendering = () => {
        return (
            <TrainingsGridTitleAndSearchSC>
                <p className='title'>Trainings overview</p>
            </TrainingsGridTitleAndSearchSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingsGridTitleAndSearchSC = styled.div`
    .title {
        font-size : 24px;
        padding : 15px;
        padding-left : 17px;
        font-weight : 500;
        color : ${colors.darkGrey};

    }
`

export default TrainingsGridTitleAndSearch