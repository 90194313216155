import React from 'react';
import styled from 'styled-components';
import IndividualTouchPointNavigation from './IndividualTouchPointNavigation/IndividualTouchPointNavigation';
import IndividualTouchPointTiming from './IndividualTouchPointTiming/IndividualTouchPointTiming';
import IndividualTouchPointFooter from './individualTouchPointFooter/IndividualTouchPointFooter';
import IndividualTouchPoint_Content from '../Content/IndividualTouchPoint_Content';
import NamingTouchPoint from './namingTouchPoint/NamingTouchPoint';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import moment from 'moment';
import { v4 } from 'uuid';
import { Button } from '@mui/material';

export const IndividualTouchPointContext = React.createContext();   

const IndividualTouchPoint2 = ({ 
    state : mailScheduleState, 
    setState : setMailScheduleState, 
    mailTemplates,
    currentInstance,
    removeTouchPoint
}) => {

    const midnight = moment().hours(0).minutes(0);

    const [currentMenu, setCurrentMenu] = React.useState(1);
    const [timingMode, setTimingMode] = React.useState('Trigger');
    const [trigger, setTrigger] = React.useState('sign-up');
    const [beforeOrAfter, setBeforeOrAfter] = React.useState('before');
    const [numberOfDays, setNumberOfDays] = React.useState(0);
    const [touchPointName, setTouchPointName] = React.useState('');
    const [time, setTime] = React.useState(midnight);

    // Initializing all the state values based on the individual touch point that is loaded
    React.useEffect(() => {
        console.log('mailScheduleState.individualTouchPoint', mailScheduleState.individualTouchPoint);
        if(mailScheduleState.individualTouchPoint.mailTemplate){

            const time = moment(mailScheduleState.individualTouchPoint.time);
            const { timingMode, selectedTrigger, beforeOrAfter, numberOfDays, name } = mailScheduleState.individualTouchPoint;
            
            setTimingMode(timingMode ? timingMode : 'Trigger');

            timingMode === 'Trigger' && setTrigger(selectedTrigger);
            timingMode === 'Scheduled' && setBeforeOrAfter(beforeOrAfter);
            timingMode === 'Scheduled' && setNumberOfDays(numberOfDays);
            timingMode === 'Scheduled' && setTime(time);
            
            setTouchPointName(name);

            // Loading the previously saved mail template
            setMailScheduleState({
                ...mailScheduleState, 
                mailTemplate : {
                    ...mailScheduleState.individualTouchPoint.mailTemplate,
                    subjectLine : mailScheduleState.individualTouchPoint.subject
                }
            })
            

            // setTime(individualTouchPoint.time);
        }
    },[mailScheduleState.individualTouchPoint]);

    // Closing the individual touch point
    const closeIndividualTouchPoint = () => {
        setMailScheduleState({
            ...mailScheduleState,
            individualTouchPoint : null
        });
    }

    // Savin the touch point
    const saveFunction = () => {

        const getTouchPointName = () => {
            if(timingMode === 'Trigger') {
                return trigger;
            } else {
                return `${numberOfDays} day${numberOfDays == 1 ? '' : 's'} ${beforeOrAfter}`;
            }
        }

        const name = getTouchPointName();

        const { touchPoints, individualTouchPoint } = mailScheduleState;

        const getSubjectFromEmailTemplate = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/mail-templates/${mailScheduleState.mailTemplate._id}`);
                return response.data.subjectLine;
            } catch (error) {
                return 'no subject'; 
            }
        };

        const calculateSendDate = () => {
            if(timingMode === 'Scheduled') {

                console.log('current instance : ', currentInstance);

                const daysDifference = beforeOrAfter === 'before' ? numberOfDays * -1 : numberOfDays;
                const calculatedDate = currentInstance ? new Date(moment(currentInstance.startDate).add(daysDifference, 'days')) : moment().add(daysDifference, 'days');
        
                const sendAt = {
                    minute : time.minutes(), 
                    hour : time.hours(), 
                    day : parseInt(moment(calculatedDate).format('DD/MM/YYYY').split('/')[0]), 
                    month : parseInt(moment(calculatedDate).format('DD/MM/YYYY').split('/')[1]), 
                    year : parseInt(moment(calculatedDate).format('DD/MM/YYYY').split('/')[2])
                }

                return sendAt;
            } else {
                return null;
            }
        }

        const runFunction = async () => {
            const subject = await getSubjectFromEmailTemplate();
            const sendAt = calculateSendDate();

            const newTouchPoint = {
                touchPointId : mailScheduleState.individualTouchPoint.touchPointId ? mailScheduleState.individualTouchPoint.touchPointId : v4(),
                name : name,
                timingMode : timingMode,
                selectedTrigger : timingMode === 'Trigger' ? trigger : null,
                time : timingMode === 'Scheduled' ? time : null,
                numberOfDays : timingMode === 'Scheduled' ? numberOfDays : null,
                beforeOrAfter : timingMode === 'Scheduled' ? beforeOrAfter : null,
                subject : subject ? subject : 'no subject',
                sendAt : sendAt,
                mailTemplate : mailScheduleState.mailTemplate ? mailScheduleState.mailTemplate : null
            }

            const idsExistingTouchPoints = touchPoints.map(touchPoint => touchPoint.touchPointId);
            const isTouchPointNew = !idsExistingTouchPoints.includes(individualTouchPoint.touchPointId);
    
            const newArray = isTouchPointNew ? [...touchPoints, newTouchPoint] : touchPoints.map(touchPointItem => {
                if(touchPointItem.touchPointId === individualTouchPoint.touchPointId){
                    console.log('new touch point : ', newTouchPoint);
                    return newTouchPoint;
                }else{
                    console.log('touch point item : ', touchPointItem);
                    return touchPointItem;
                }
            });

            setMailScheduleState({
                ...mailScheduleState, 
                touchPoints : newArray, 
                individualTouchPoint : null
            });
        }

        runFunction();
    }

    const getSuggestedValue = () => {
        if(timingMode === 'Trigger') {
            return `On ${trigger}`;
        } else {
            return `${numberOfDays} days ${beforeOrAfter}`;
        }
    }

    const suggestedNamevalue = getSuggestedValue();

    const rendering = () => {
        return (
            <IndividualTouchPointContext.Provider value={{
                currentMenu,
                setCurrentMenu,
                timingMode,
                setTimingMode,
                beforeOrAfter,
                setBeforeOrAfter,
                trigger, 
                setTrigger,
                numberOfDays,
                setNumberOfDays,
                closeIndividualTouchPoint,
                touchPointName,
                setTouchPointName,
                saveFunction,
                currentInstance,
                mailScheduleState, 
                time, 
                setTime,
                suggestedNamevalue,
                removeTouchPoint
            }}>
                <IndividualTouchPoint2SC>
                    <IndividualTouchPointNavigation/>
                    <div className='content'>
                        {currentMenu === 1 && <IndividualTouchPointTiming />}
                        {currentMenu === 2 &&  <IndividualTouchPoint_Content 
                            mailScheduleState={mailScheduleState} 
                            setMailScheduleState={setMailScheduleState} 
                            mailTemplates={mailTemplates}
                        />}
                        {currentMenu === 1 &&
                            <div className='touchpoint-options'>
                            <Button
                                variant='outlined'
                                color='primary'
                                onClick={() => removeTouchPoint(mailScheduleState.individualTouchPoint.touchPointId)}
                            >
                                remove e-mail from schedule
                            </Button>
                        </div>
                        }
                    </div>
                    <IndividualTouchPointFooter />
                </IndividualTouchPoint2SC>
            </IndividualTouchPointContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTouchPoint2SC = styled.div`
    flex : 1;
    display : flex; 
    flex-direction : column;
    background-color : white;

    .content {
        flex-grow : 1;
        display : flex; 
        overflow : hidden;
        position : relative;

        .touchpoint-options {
            position : absolute;
            bottom : 15px;
            right : 30px;
        }
    }
    
`

export default IndividualTouchPoint2