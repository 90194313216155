import React from 'react';
import styled from 'styled-components';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';


const AddTemplateImage = ({ file, setFile, imageURL, setImageURL}) => {


    const handleImageUpload = (e) => {
        const fileItem = e.target.files[0];

        setFile(fileItem);
        const reader = new FileReader();
        reader.readAsDataURL(fileItem);
        reader.onloadend = () => {
            setImageURL(reader.result);
        }
    }

    const rendering = () => {
        return (
            <AddTemplateImageSC>
                <label 
                    for="file-upload"
                    class="custom-file-upload"
                >
                    <div className='background-image-container' style={{ backgroundImage : `url(${imageURL})`, margin : '10px' }}>
                        <div className='upload-icon'>
                            <AddAPhotoIcon />
                        </div>
                    </div>

                </label>

                <input 
                    id="file-upload" 
                    type="file" 
                    style={{display: 'none'}}
                    accept='.png, .jpg, .jpeg'
                    onChange={(e) => handleImageUpload(e)}    
                />
            </AddTemplateImageSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTemplateImageSC = styled.div`
    max-width : 230px;
    max-height : 230px;
    min-height : 230px;
    min-width : 230px;
    margin-top : 20px;
    background-color : white;

    .custom-file-upload {
        display : flex; 
        cursor: pointer;
        min-width : 100%;
        min-height : 100%;
        max-width : 100%;
        max-height : 100%;
        justify-content : center;
        box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.75);
        border-radius : 10px;
        align-items : center;
        overflow: hidden;
        transition : 0.3s;
    }

    .background-image-container {
        width : 230px;
        height : 230px;
        background-position : center;
        background-repeat : no-repeat;
        background-size : cover;
        position : relative;

        &:hover .upload-icon {
            display : flex;
            transition : 0.3s;

        }

    }

    .upload-icon {
        position : absolute;
        top : 0;
        left : 0;
        right : 0;
        bottom : 0;
        z-index : 1000;
        color : grey;
        display : none;
        justify-content : center;
        align-items : center;
        
        &>svg {
            font-size : 5em;
        }
    }

`

export default AddTemplateImage