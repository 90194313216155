import React from 'react';
import styled from 'styled-components';
import EventBox from './EventBox';
import AddMailButton from './AddMailButton';
import { Mail } from '@mui/icons-material';
import MailBox from './MailBox';

const RegularFlowBar = ({ selectMailFunction, touchPoints, addTouchPoint, state }) => {

    const addTimeStampToTouchPoints = touchPoints.map(touchPoint => {
        if(touchPoint.timingMode === 'Scheduled') {

            const { year, month, day, hour, minute } = touchPoint.sendAt;
            const dateTime = new Date(year, month-1, day, hour, minute);

            return {
                ...touchPoint,
                dateTime
            }
        }

        else {
            return touchPoint;
        }
    });

    const sortedTouchPoints = addTimeStampToTouchPoints.sort((a, b) => {
        return a.dateTime - b.dateTime;
    });

    const onSignUpTouchPoint = sortedTouchPoints.filter(touchPoint => touchPoint.selectedTrigger === 'sign-up');
    const beforeTraining = sortedTouchPoints.filter(touchPoint => touchPoint.beforeOrAfter === 'before');        

    const afterTraining = sortedTouchPoints.filter(touchPoint => touchPoint.beforeOrAfter === 'after');

    const rendering = () => {
        

        return (
            <RegularFlowBarSC className='mail-flow-row'>
                <EventBox 
                    index={1}
                    label={'Sign-up'}
                    description={'users signs up'}
                />

                {onSignUpTouchPoint.length > 0 && 
                    <div className='sign-up-stuff'>
                    <MailBox 
                        touchPoint={onSignUpTouchPoint[0]} 
                        selectMailFunction={selectMailFunction}
                    />
                    </div>
                }

                {beforeTraining.map((touchPoint) => {
                    return (
                        <MailBox
                            enabled={true}
                            touchPoint={touchPoint}
                            selectMailFunction={selectMailFunction}
                        />
                    );
                })}

                <EventBox 
                    index={2}
                    label={'Training'}
                    description={'users attends training'}
                />

                {afterTraining.map((touchPoint) => {
                    return (
                        <MailBox
                            enabled={true}
                            touchPoint={touchPoint}
                            selectMailFunction={selectMailFunction}
                        />
                    );
                })}

                <AddMailButton addTouchPoint={addTouchPoint}/>
            </RegularFlowBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RegularFlowBarSC = styled.div`

.sign-up-stuff {
    display : flex;

}
`

export default RegularFlowBar