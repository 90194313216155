import { AgGridReact } from 'ag-grid-react';
import React, { useState } from 'react';
import styled from 'styled-components';
import { UserManagementContext } from '../UserManagement';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-material.css';
import UserRoleComponent from './UserRoleComponent';
import DeleteUserButton from './DeleteUserButton';

const UsersList = () => {

    const { users, setIndividualUser } = React.useContext(UserManagementContext);

    const [colDefs, setColDefs] = useState([
        { field: "userEmail", headerName : 'E-mail', flex : 2},
        { field: "role", headerName : 'Role', flex : 2, editable : true},
        { valueGetter : p => p.data, cellRenderer : DeleteUserButton, headerName : 'Options', flex : 1 },
    ]);
    
    const rendering = () => {
            return (
                <UsersListSC>
                    <div className='ag-theme-material' style={{height : 600, width : "100%"}} >
                        <AgGridReact
                            rowData={users}
                            columnDefs={colDefs}
                            onCellClicked={(cell) => {
                                if(cell.colDef.headerName !== 'Options') {
                                    setIndividualUser(cell.data);
                                }
                            }}
                        />
                    </div>
                </UsersListSC>
            );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UsersListSC = styled.div`
    background-color : white; 
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    margin : 10px;
    border-radius : 10px;
    padding : 10px;

    .ag-theme-material {
        --ag-border-radius : 10px;
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
    }

`

export default UsersList