import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';
import { useNavigate } from 'react-router-dom';

const CertificateItem = ({ certificate }) => {

    const navigate = useNavigate(); 

    const navigateToCertificate = () => {
        navigate(`/certificates/individual/${certificate._id}`)
    };

    const rendering = () => {
        return (
            <CertificateItemSC onClick={() => navigateToCertificate()}>
                <p>{certificate.certificateName}</p>
                <p>{certificate.user.userEmail}</p>
            </CertificateItemSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificateItemSC = styled.div`
    display : flex;
    border-bottom : 1px solid #f5f5f5;
    &:hover {
        background-color : #f5f5f5;
        cursor : pointer;
    }

    p {	
        padding : 15px;
        font-size : 1rem;
        font-weight : 400;
        color : ${colors.darkGrey}
    }
`

export default CertificateItem