import React, { useState } from 'react';
import styled from 'styled-components';
import { IndividualSessionContext } from '../../IndividualSession';
import { backEndAdress } from '../../../../variables/back-endAdress';
import Axios from 'axios';
import { Gauge } from '@mui/x-charts/Gauge';

const SurveyResponsesOverview = ({ individualSession }) => {

    const [surveyResponses, setSurveyResponses] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const { individualTraining } = React.useContext(IndividualSessionContext);


    React.useEffect(() => {
        const getSurveyResponses = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/survey-responses/by-instance/${individualSession._id}`);
                setSurveyResponses(response.data);
                setLoading(false); 
            } catch (error) {
                console.log('Error in getSurveyResponses', error);
                setLoading(false);
            }
        }
        getSurveyResponses();
    }, []);

    const rendering = () => {

        const percentageFilledIn = (surveyResponses.length / individualSession.attendees.length) * 100;
        
        const roundedPercentageFilledIn = Math.round(percentageFilledIn);
        return (
            <SurveyResponsesOverviewSC>
            <Gauge width={100} height={100} value={roundedPercentageFilledIn} />
            <div style={{display : 'flex', flexDirection : 'column', padding : '10px'}}>
                <p className='title-certs-and-surveys'>Feedback responses</p>
                <p className='sub-title-certs-and-surveys'>{roundedPercentageFilledIn}% Surveys filled in</p>
            </div>

            </SurveyResponsesOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyResponsesOverviewSC = styled.div`
    display : flex;
    align-items : center;
    border : 1px solid #f0f0f0;
    border-radius : 5px;

`


export default SurveyResponsesOverview