import React from 'react';
import styled from 'styled-components';
import Modal from '../../../GeneralComponents/Modal';
import { Button } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import Axios from 'axios'; 
import { backEndAdress } from '../../../../variables/back-endAdress';

const ChangeTrainerPopUp = ({ closeFunction, clickFunction }) => {

    const [trainers, setTrainers] = React.useState([]);
    const [locations, setLocations] = React.useState([]);

    const AvatarComponent = (p) => {
        const { firstName, lastName, imageUrl } = p.data;

        return (
            <div style={{ display : 'flex', alignItems : 'center', minHeight : '100%'}}>
                <img 
                    src={imageUrl} 
                    alt='avatar' 
                    style={{ 
                        width : '35px', 
                        height : '35px', 
                        objectFit : 'cover',
                        borderRadius : '50%'
                    }}
                />
                <p style={{paddingLeft : '10px'}}>{`${firstName} ${lastName}`}</p>
            </div>
        );
    }

    React.useEffect(() => {
        const getTrainers = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainers`);
                setTrainers(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getTrainers();
    }, []);

    const rendering = () => {
        console.log('trainers', trainers);
        return (

            <Modal>
                <ChangeTrainerPopUpSC>
                    <div className='header'>
                        <p>Change Location</p>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => closeFunction()}
                        >
                            Cancel
                        </Button>
                    </div>

                    <div className='content'>
                        <div className='ag-theme-material' style={{height : "100%", width : "100%"}} >
                            <AgGridReact
                                rowData={trainers} 
                                columnDefs={[
                                    { valueGetter : p => p.data, cellRenderer : AvatarComponent, headerName : 'Trainer', flex : 1 },
                                    {field : 'phone', headerName : 'Adress', flex : 1},
                                ]}
                                onRowClicked={(e) => clickFunction(e.data)}
                            />
                        </div>
                    </div>
                </ChangeTrainerPopUpSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ChangeTrainerPopUpSC = styled.div`
    background-color : white;
    min-width : 700px;
    min-height : 500px;
    border-radius : 5px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);
    display : flex;
    flex-direction : column;

    .header {
        display : flex; 
        justify-content : space-between;
        padding : 20px;
        border-bottom : 1px solid #f2f2f2;
        align-items : center;

    }

    .content {
        height : 1px;
    }

    .ag-theme-material {
        --ag-font-size: inherit;
        --ag-font-family: inherit;    
        --ag-material-accent-color : #1976d2;
        --ag-selected-row-background-color : rgba(42,115,207,0.1);

        .ag-cell-focus {
            outline: none; /* Remove the default outline */
        }
    }
`

export default ChangeTrainerPopUp