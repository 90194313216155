import React from 'react';
import styled from 'styled-components';

const Trainings = () => {

    const rendering = () => {
        return (
            <TrainingsSC>
                <p className='filter-title'>Trainings</p>
                <div className='filter-item'>
                    <input type='checkbox' name='training' value='training1'/>
                    <label htmlFor='training1'>Training 1</label>
                </div>
            </TrainingsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingsSC = styled.div`
    
`

export default Trainings