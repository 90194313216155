import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { MailScheduleBothLocationsContext } from '../MailScheduleBothLocations';

const IndividualTouchPointHeader = ({ individualTouchPoint, onSubmit }) => {

    const { setIndividualTouchPoint } = React.useContext(MailScheduleBothLocationsContext);

    const submitFunction = (e) => {
        e.preventDefault(); // Prevent default behavior
        onSubmit();
    }

    const rendering = () => {
        return (
            <IndividualTouchPointHeaderSC>
                <p>{individualTouchPoint.name}</p>
                <div className='buttons'>
                    <Button
                        variant='outlined'
                        color='primary'
                        style={{ marginRight : '10px' }}
                        onClick={(e) => submitFunction(e)}
                    >
                        Save
                    </Button>
                    <Button
                        variant='outlined'
                        color='primary'
                        onClick={() => setIndividualTouchPoint(null)}
                    >
                        Cancel
                    </Button>
                </div>
            </IndividualTouchPointHeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTouchPointHeaderSC = styled.div`
    display : flex;
    justify-content : space-between;
    align-items : center;
    max-height : 50px;
    background-color : white;
    padding : 15px;
`

export default IndividualTouchPointHeader