import Axios from 'axios';
import React from 'react';
import styled from 'styled-components';
import { backEndAdress } from '../../variables/back-endAdress';
import CertificatesList from './CertificatesList';
import { Route, HashRouter as Router, Routes  } from 'react-router-dom';
import CertificatesHeaderMenu from './CertificatesHeaderMenu';

const Certificates = () => {

    const [state, setState] = React.useState({
        certificates : []
    });

    React.useEffect(() => {
        const getCertificates = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/certificates`)
                setState({
                    ...state,
                    certificates : response.data
                })
            } catch (error) {
                console.log(error)
            }
        }

        getCertificates()
    },[]);

    const rendering = () => {
        return (
            <CertificatesSC>
                <CertificatesHeaderMenu />
            </CertificatesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificatesSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;

`

export default Certificates