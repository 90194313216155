import { DeleteForever } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const TextBlock = ({ textblock, state, setState, trainingToEditLocal, setTrainingToEditLocal }) => {

    const { title, content, textBlockId } = textblock;

    const deleteBlock = (textBlockId) => {
        const newBlocks = trainingToEditLocal.textBlocks.filter(block => block.textBlockId !== textBlockId);
        setTrainingToEditLocal({...
            trainingToEditLocal,
            textBlocks : newBlocks
        })
    }

    const updateTitle = (e) => {
        const newBlocks = trainingToEditLocal.textBlocks.map(block => {
            if(block.textBlockId === textBlockId) {
                block.title = e.target.value;
            }
            return block;
        });
        setTrainingToEditLocal({...
            trainingToEditLocal,
            textBlocks : newBlocks
        })
    }

    const updateContent = (e) => {
        const newBlocks = trainingToEditLocal.textBlocks.map(block => {
            if(block.textBlockId === textBlockId) {
                block.content = e.target.value;
            }
            return block;
        });
        setTrainingToEditLocal({...
            trainingToEditLocal,
            textBlocks : newBlocks
        })
    }

    const rendering = () => {
        return (
            <TextBlockSC>
                <div className='text-block-header'>
                    <input 
                        type='text' 
                        defaultValue={title}
                        onChange={(e) => updateTitle(e)}    
                    />
                    <IconButton>
                        <DeleteForever
                            onClick={() => deleteBlock(textBlockId)}
                        />
                    </IconButton>
                </div>    
                <textarea
                    defaultValue={content}
                    onChange={(e) => updateContent(e)}
                />            
            </TextBlockSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TextBlockSC = styled.div`
    border : 1px solid lightgrey;
    border-radius : 5px;
    padding : 10px;

    .text-block-header {
        display : flex; 
        justify-content : space-between;
        align-items : center;
    }
`

export default TextBlock