import React from 'react';
import styled from 'styled-components';
import Trainings from './Trainings';
import { SurveyResponsesContext } from '../SurveyResponses.js/SurveyResponses';
import { Checkbox } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { SurveyContext } from '../Survey';

const SurveyFilterSideBar = () => {

    const { filters, setFilters } = React.useContext(SurveyResponsesContext);
    const { instance } = React.useContext(SurveyContext);

    const toggleFilter = (filterTitle, value) => {
        const newFilters = filters.map((filterItem) => {
            if(filterItem.filterTitle === filterTitle) {

                if(filterItem.filterValues.includes(value)) {
                    filterItem.filterValues = filterItem.filterValues.filter((item) => item !== value);
                } else {
                    filterItem.filterValues.push(value);
                }
            }
            return filterItem;
        })

        setFilters(newFilters);
    }

    React.useEffect(() => {

        console.log('instance', instance);

        const setTrainingFilter = (value) => {
            const newFilters = filters.map((filterItem) => {
                if(filterItem.filterTitle === 'Training') {
                    filterItem.filterValues = [value];
                }
                return filterItem;
            })

            setFilters(newFilters);
        }

        if(instance) { 
            console.log('yu motje');
            setTrainingFilter(instance.trainingId);
        }

    },[instance])

    const rendering = () => {


        return (
            <SurveyFilterSideBarSC>
                <p className='title'>Filters</p>

                <div className='filter-boxes'>
                    {filters.map((filterItem) => {

                            return (
                                <div className='filter-item' key={filterItem.id}>
                                    <p className='filter-item-title'>{filterItem.filterTitle}</p>

                                    <div className='filter-options'>
                                        {filterItem.possibleValues.map((option) => {

                                            const idOfOption = option._id;
                                            const isInFilter = filterItem.filterValues.includes(idOfOption);
                                            
                                            return (
                                                <div 
                                                    className='filter-option' 
                                                    key={option._id}
                                                    onClick={() => toggleFilter(filterItem.filterTitle, option._id)}    
                                                >
                                                    
                                                    { isInFilter ? 
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={true}
                                                        />
                                                        :
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={false}
                                                        />
                                                    }    

                                                    <p>
                                                        {option[filterItem.propertyToShow]}
                                                    </p>
                                                </div>
                                            )
                                        }
                                        )}  



                                        {/* {filterItem.possibleValues.map((option) => option._id).map((option) => {
                                            const isInFilter = filterItem.filterValues.includes(option._id);
                                            console.log('in filter ? ', isInFilter);
                                            return (
                                                <div 
                                                    className='filter-option' 
                                                    key={option._id}
                                                    onClick={() => toggleFilter(filterItem.filterTitle, option._id)}    
                                                >
                                                    
                                                    { isInFilter ? 
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={true}
                                                        />
                                                        :
                                                        <Checkbox
                                                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                            checked={false}
                                                        />
                                                    }    

                                                    <p>
                                                        {option[filterItem.propertyToShow]}
                                                    </p>
                                                </div>
                                            )
                                        })} */}
                                    </div>
                                </div>
                            )
                    })}
                </div>

            </SurveyFilterSideBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyFilterSideBarSC = styled.div`
    max-width : 280px;
    min-width : 280px;
    border-radius : 5px;
    background-color : white;

    .title {
        font-size : 1.5rem;
        padding : 15px;
    }

    .filter-boxes {
        display : flex;
        flex-direction : column;
    }

    .filter-item {
        background-color : white;
        box-sizing : border-box;
        padding : 15px;
    }
    
    .filter-item-title {
        font-size : 1.2rem;
        padding-bottom : 5px;
    }

    .filter-options {
        display : flex;
        flex-direction : column;

    }

    .filter-option {
        cursor : pointer;
        display : flex; 
        align-items : center;
        color : #545454;
        padding : 5px;
        border-bottom : 1px solid lightgrey;
    }
`

export default SurveyFilterSideBar