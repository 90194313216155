import React from 'react';
import styled from 'styled-components';

const Rating = ({ item }) => {

    let totalRating = 0;
    let totalAnswers = 0;

    item.data.map((item, index) => {
        if(index !== 0) {
            let rating = item[0] * item[1];
            totalRating += rating;
            totalAnswers += item[1];
        }
    })

    const averageRating = totalRating / totalAnswers;

    const rendering = () => {
        return (
            <RatingSC>
                <p>{`rating : ${averageRating.toFixed(1)}`}</p>
            </RatingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RatingSC = styled.div`
    padding : 15px;   
`

export default Rating