import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Validate_Certificate = ({ state }) => {

    const rendering = () => {
        return (
            <Validate_CertificateSC>
                <p className='header-title'>Certificate</p>
                <p>{state.selectedCertificateTemplate ? state.selectedCertificateTemplate.certificateName : 'No certificate!'}</p>
                {state.selectedCertificateTemplate && <div 
                    className='thumbnailImage'
                    style={{ backgroundImage : `url(${state.selectedCertificateTemplate.imageUrl})`}}    
                >
                </div>}
            </Validate_CertificateSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const Validate_CertificateSC = styled.div`
    padding : 20px;

    .header-title {
        font-size : 1.5rem;
        color : #000;
    }

    .thumbnailImage {
        width : 80px;
        height : 80px;
        background-size : cover;
        background-position : center;
        margin-top : 10px;
        border-radius : 10px;
    }
`

export default Validate_Certificate;