import React from 'react';
import styled from 'styled-components';
import { CourseMaterialsContext } from './CourseMaterials';



const FolderSelection = ({ folders }) => {

    const { activeFolder, setActiveFolder } = React.useContext(CourseMaterialsContext);

    const rendering = () => {
        return (
            <FolderSelectionSC>
                {folders.map((folder, index) => {

                    const isSelect = activeFolder.name === folder.name;

                    return (
                        <div className={`folder-item ${isSelect && 'selected'}`} key={index} onClick={() => setActiveFolder(folder)}>
                            <img src='https://images.vexels.com/media/users/3/276662/isolated/lists/45480a3100a9edc6bf3b0ce03b3830e4-blue-folder-rounded.png'/>
                            <p className='folder-title'>{folder.name}</p>
                            <p className='files-info'>{`${10} files | ${22}mb`}</p>
                        </div>
                    )
                })}
            </FolderSelectionSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const FolderSelectionSC = styled.div`
    display : flex;

    .folder-item {
        display : flex; 
        flex-direction : column;
        padding : 10px; 
        background-color : #F4F4F4; 
        min-height : 100px;
        min-width : 100px;
        margin-right : 10px;
        border-radius : 5px;

        &:hover {
            cursor : pointer;
        }

        &>img {
            max-width : 50px;
            max-height : 50px;
        }

        .folder-title {
            margin-left : 5px;
            padding : 0;
            font-weight : 500;
        }

        .files-info {
            margin-left : 5px;
            padding : 0;
            font-size : 0.8rem;
            font-weight : 300;
        }
    }

    .selected {
        background-color : #E0E0E0;
    }
`

export default FolderSelection