import React from 'react';
import styled from 'styled-components';
import SubjectInput from './SubjectInput';
import Preview from './Preview';

const SubjectAndPreview = ({ subjectRef, setIndividualTouchPointState, individualTouchPointState }) => {

    const rendering = () => {
        return (
            <SubjectAndPreviewSC>
                <SubjectInput 
                    subjectRef={subjectRef}
                    individualTouchPointState={individualTouchPointState}    
                />
                <Preview 
                    individualTouchPointState={individualTouchPointState}
                    setIndividualTouchPointState={setIndividualTouchPointState}
                />
            </SubjectAndPreviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SubjectAndPreviewSC = styled.div`
    min-width : 600px;
    max-width : 600px;
    display : flex;
    flex-direction : column;


`

export default SubjectAndPreview