import React from 'react';
import styled from 'styled-components';
import { TrainingCalendarContext } from './TrainingCalendar';

const MonthSelection = () => {

    const { months, setCurrentMonth, currentMonth } = React.useContext(TrainingCalendarContext);


    const rendering = () => {
        return (
            <MonthSelectionSC>
                {months.map((month, index) => {

                    const isCurrentMonth = currentMonth === month;

                    return (
                        <div 
                            key={index} 
                            className='month'
                            onClick={() => setCurrentMonth(month)}
                            style={{
                                cursor : 'pointer',
                                color : isCurrentMonth ? 'black' : 'lightgrey'
                            }}
                        >
                            <p>{month}</p>
                        </div>
                    );
                })}
            </MonthSelectionSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MonthSelectionSC = styled.div`
    display : flex; 
    box-sizing : border-box;
    margin-bottom : 20px;
    p {
        margin : 0;
        padding : 0;
        font-size : 1rem;
        margin-right : 20px;

    }


`

export default MonthSelection