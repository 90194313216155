import React from 'react';
import styled from 'styled-components';
import LoginInfo from './LoginInfo';

const LoginContainer = () => {

    const rendering = () => {
        return (
            <LoginContainerSC>
                <LoginInfo />
                <div className='login-image'>
                    <img src='/login screen.png' alt='logo' className='holli-logo'/>
                </div>
            </LoginContainerSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const LoginContainerSC = styled.div`
    max-width : 60vw;
    max-height : 80vh;
    min-width : 60vw;
    min-height : 80vh;
    background-color : white;
    border-radius : 10px;
    display : flex;

    .login-image { 
        /* background: linear-gradient(to right, #35B0E5 0%, #3576BD 40%, #371D7F 100%); */
        flex : 1;
        overflow : hidden;
        position: relative;  /* Position relative to adjust children */
        overflow: hidden;  /* Prevent overflow */

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;  /* Makes the image cover the container */        }
    }
`

export default LoginContainer