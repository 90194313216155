import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import { Button } from '@mui/material';

const ChangeInstanceConfirmation = ({ property, changeFunction, closeFunction }) => {

    const rendering = () => {

        return (
            <Modal>
                <ChangeInstanceConfirmationSC>
                    <p>Do you want to change the {property} for this workshop?</p>
                    <div className='buttons'>
                        <Button
                            variant='outlined'
                            onClick={() => changeFunction()}
                        >
                            Yes
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={() => closeFunction()}
                        >
                            No
                        </Button>
                    </div>
                </ChangeInstanceConfirmationSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ChangeInstanceConfirmationSC = styled.div`
    background-color : white;
    padding : 30px;
    border-radius : 5px;
    box-shadow : 0px 0px 5px 0px rgba(0,0,0,0.1);

    .buttons {
        display : flex;
        justify-content : flex-start;
        margin-top : 20px;

        button {
            margin-left : 10px;
        }
    }
`

export default ChangeInstanceConfirmation