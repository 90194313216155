import React from 'react';
import styled from 'styled-components';

const TemplatesGrid = ({ state , setState, searchQuery }) => {
    
    const templatesThatMatchSearch = state.templates.filter((mailTemplate) => {
        return mailTemplate.title.toLowerCase().includes(searchQuery.toLowerCase());
    });
    const rendering = () => {


        return (
            <TemplatesGridSC>
                {templatesThatMatchSearch.map((mailTemplate) => {
                    const isSelected = state.currentMailTemplate && state.currentMailTemplate._id === mailTemplate._id;
                    return (
                        <div 
                            key={mailTemplate._id} 
                            className= {`mail-template-container ${isSelected ? 'selected' : ''}`}
                            onClick={() => {setState({...state , currentMailTemplate : mailTemplate})}}    
                        >
                            <p className='mail-template-title'>{mailTemplate.title}</p>
                            <p className='mail-template-description'>{mailTemplate.description}</p>
                        </div>
                    );
                })}
            </TemplatesGridSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TemplatesGridSC = styled.div`
        display : grid; 
        grid-template-columns : repeat(3 , 1fr);
        grid-gap : 10px;
        padding : 15px;
        flex-grow : 1;
        position : relative;

        .selected {
            border : 2px solid #3f51b5;
        }

        .mail-template-container {
            padding : 20px;
            min-height : 160px;
            max-height : 160px;
            overflow : hidden;
            border-radius : 5px;
            overflow : hidden;
            box-sizing : border-box;
            background-color : white;
            box-shadow : 0 0 10px rgba(0,0,0,0.1);
        }

        .mail-template-title {
            font-size : 1.2rem;
        }

        .mail-template-description {
            font-size : 1rem;
            color : grey;
            overflow: hidden;
            text-overflow: ellipsis;
        }
`

export default TemplatesGrid