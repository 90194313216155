import React from 'react';
import styled from 'styled-components';

const AreaInputField = ({ typedValue, setValueFunction, label, suggestedValue, icon, style }) => {
    const [value, setValue] = React.useState('');

    React.useLayoutEffect(() => {
        if(typedValue) {
            setValue(typedValue);
        } else {
            setValue(suggestedValue);
        }
    }, []);

    React.useEffect(() => {
        setValueFunction(value);
    },[value]);

    const onChangeFunction = (e) => {
        setValue(e.target.value);
    }

    const rendering = () => {
        return (
            <AreaInputFieldSC style={style}>
                {label && <p className='label'>{label}</p>}
                <div className='input-field'>
                    {icon && <div className='icon'>{icon}</div>}
                    <textarea 
                        type='text' 
                        value={value}
                        onChange={(e) => onChangeFunction(e)}
                        onClick={(e) => e.target.select()}
                    />
                </div>


            </AreaInputFieldSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AreaInputFieldSC = styled.div`
    position : relative;
    min-width : 100%;
    display : flex; 

    .input-field {
        display : flex; 
        align-items : center;
        min-height : 35px;
        border : 1px solid #ccc;
        padding : 10px;
        border-radius : 5px;
        width : 100%;
        padding-left : 15px;

        .icon {
            margin-right : 10px;
            display : flex;
            flex-direction : column;
            justify-content : center;
            &>svg {
                color : grey;
            }

        }

        &>input {
            border : none;
            outline : none;
            width : 100%;
            font-size : inherit;
            font-family : inherit;
        }

    }

    .label {
        font-size : 12px;
        color : grey;
        position : absolute;
        top : -8px;
        left : 10px;
        background-color : white;
        padding : 0 5px;
        z-index : 1;
    }
`

export default AreaInputField