import React from 'react';
import styled from 'styled-components';
import { SurveyContext } from './FillSurveyLink';
import MultipleChoice from './MultipleChoice';
import Rating from './Rating';
import FreeText from './FreeText';
import QuestionWrapper from '../SurveyResponses.js/QuestionWrapper';

const SurveyItems = () => {

    const { state, training, instance, answers, setAnswers } = React.useContext(SurveyContext);
    const survey = state.survey; 

    const getCorrectComponent = (item, answers, setAnswers) => {
        switch(item.type) {
            case 'multiple-choice': 
                return <MultipleChoice 
                    item={item} 
                    answers={answers} 
                    setAnswers={setAnswers} 
                />
            case 'rating': 
                return <Rating 
                    item={item}
                    answers={answers}
                    setAnswers={setAnswers}    
                />
            case 'free-text':
                return <FreeText item={item}/>
            default: 
                return <div>default</div>
        }
    }

    const rendering = () => {
        return (
            <SurveyItemsSC>
                <div className='center-container'>
                    {survey.surveyItems.map((item, index) => {
                        return (
                            <>
                                <QuestionWrapper>
                                    {getCorrectComponent(item, answers, setAnswers)}
                                </QuestionWrapper>
                            </>
                        )
                    })}
                </div>
            </SurveyItemsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyItemsSC = styled.div`
    display : flex; 
    justify-content : center;

    .center-container {
        max-width : 900px;
        min-width : 900px;
    }
`

export default SurveyItems