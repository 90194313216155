import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividiualTraining';

const Header = ({ individualTraining }) => {

    const { setEditMode, state, setState } = React.useContext(IndividualTrainingContext);
    const rendering = () => {
        return (
            <HeaderSC>
                <h1 className='title'>Overview</h1>
                <p onClick={() => setState({...state, trainingToEdit : individualTraining})}>edit</p>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    margin : 20px ;

    display : flex;
    justify-content : space-between;
    align-items : center;
`

export default Header