import React from 'react';
import styled from 'styled-components';
import { IndividualTrainingContext } from '../IndividiualTraining';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';

const TrainingOverview = ({ individualTraining }) => {
    const { name, imageUrl, description, difficulty, customProperties, pricePerDay, numberOfDays } = individualTraining;
    const rendering = () => {
        return (
            <TrainingOverviewSC>
                <div >
                    <img src={imageUrl}/>
                    <div style={{ padding : '20px', display : 'flex', flexDirection : 'column'}}>
                        <h3 className='title'>{name}</h3>
                        <p className='description'>{description}</p>   
                    </div>
                    <div className='basic-properties'>
                        <div className='basic-property-item'>
                            <h3 className='property-name'>Difficulty</h3>
                            <h4 className='property-value'>{customProperties.difficulty}</h4>
                        </div>
                        <div className='basic-property-item'>
                            <h3 className='property-name'>Price</h3>
                            <h4 className='property-value'>{pricePerDay}</h4>
                        </div>
                        <div className='basic-property-item'>
                            <h3 className='property-name'>Category</h3>
                            <h4 className='property-value'>{customProperties.category}</h4>
                        </div>
                        <div className='basic-property-item'>
                            <h3 className='property-name'>Days</h3>
                            <h4 className='property-value'>{numberOfDays}</h4>
                        </div>
                    </div>
                    <div className='boolean-properties'>
                        <div className='property-boolean'>
                            {customProperties.free ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                            <h4 className='property-name'>Free</h4>
                        </div>
                        <div className='property-boolean'>
                            {customProperties.onDemand ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                            <h4 className='property-name'>On demand</h4>
                        </div>
                        <div className='property-boolean'>
                            {customProperties.webinar ? <CheckBoxOutlinedIcon /> : <CheckBoxOutlineBlankOutlinedIcon />}
                            <h4 className='property-name'>Webinar</h4>
                        </div>
                    </div>
                    <div className='availabilitie-properties'>
                        <div className='availability-property'>
                            <h3 className='property-name'>Available languages</h3>
                            {customProperties.availableLanguages.map((language, index) => {
                                return <h4 key={index} className='property-value'>{language}</h4>
                            })}
                        </div>
                        <div className='availability-property'>
                            <h3 className='property-name'>Available languages</h3>
                            {customProperties.availableLanguages.map((language, index) => {
                                return <h4 key={index} className='property-value'>{language}</h4>
                            })}
                        </div>
                    </div>
                </div>
            </TrainingOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingOverviewSC = styled.div`
    background-color : white;
    border-radius : 2px;
    padding : 5px;
    margin : 20px;
    margin-top : 0;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    display : flex; 
    flex-direction : column;
    max-width : 380px;
    min-width : 380px;

    img {
        min-width : 100%;
        object-fit : cover;
        max-width : 100%;
        max-height : 200px;
    }

    .title {
        font-size : 20px;
    }

    .description {  
        font-weight : 350;
    }

    .basic-properties {
        display : flex; 
        justify-content : space-between;
        padding : 20px;
        padding-top : 10px;
    }

    .basic-property-item {
        h3 {
            font-size : 16px;
            font-weight : 400;
        }

        h4 {
            font-size : 14px;
            font-weight : 400;
        }
    }

    .boolean-properties {
        padding-left : 10px;

        .property-boolean {
            display : flex;
            align-items : center;
            margin : 5px;

            h4 {
                margin-left : 10px;
                font-size : 16px;
            }

            svg {
                color : black;
                font-size : 20px;
            }
        }

    }

    .availabilitie-properties {
        display : flex; 
        padding : 20px;
        justify-content : space-between;
    }


`

export default TrainingOverview