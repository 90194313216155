import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MailOutline } from '@mui/icons-material';

const Validate_MailSchedule = ({ state, setState, trainingToEditLocal }) => {
    
    const touchPoints = trainingToEditLocal.defaultTouchPointsArray.map(touchpoint => touchpoint.name)

    const rendering = () => {
        return (
            <Validate_MailScheduleSC>
                <p className='header-title'>Mail schedule</p>
                <p className='description-touchpoints'>{`${touchPoints.length} mails`}</p>
                <div className='touchpoints'>
                {touchPoints.length > 0 ? 
                    touchPoints.map((touchpoint, index) => {
                        return (
                            <div 
                                key={index}
                                className='touchpoint-item'    
                            >
                                <MailOutline />
                                <p className='touchpoint-title'>{touchpoint}</p>
                            </div>
                        );
                    })
                
                    
                : 
                
                <p>No touchpoints!</p>}
                </div>

            </Validate_MailScheduleSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const Validate_MailScheduleSC = styled.div`
    padding : 20px;    

    .header-title{  
        font-size : 1.5rem;
        color : #000;
        margin-bottom : 10px;
    }

    .touchpoints {
        margin-top : 10px;
        display : grid;
        grid-template-columns : repeat(auto-fill, minmax(80px, 1fr));
        gap : 10px;
    }

    .touchpoint-item {
        display : flex;
        flex-direction : column;
        align-items : center;
        margin-bottom : 10px;
        min-width : 80px;
        max-width : 80px;
        min-height : 80px;
        justify-content : center;
        border : 1px solid grey;
        border-radius : 10px;
        padding : 10px;
        box-sizing  : border-box;

        & svg {
            color : grey;
        }
    
    
        .touchpoint-title {
            font-size : 0.7rem;
            text-align : center;
        }
    }



`

export default Validate_MailSchedule