import React from 'react';
import styled from 'styled-components';
import TemplatesGrid from './mailTemplatesOverview/TemplatesGrid';
import { Mail } from '@mui/icons-material';
import MailTemplatesOverview from './mailTemplatesOverview/MailTemplatesOverview';
import { backEndAdress } from '../../variables/back-endAdress';
import MailPreview from './mailPreview/MailPreview';
import Axios from 'axios'; 
import AddMailTemplate from './addMailTemplate/AddMailTemplate';
import { AppContext } from '../../App';

export const MailsContext = React.createContext(); 

const MailsOverview = () => {

    const { appState } = React.useContext(AppContext);
    const [state , setState] = React.useState({ 
        mailTemplates : [],
        currentMailTemplate : null,
        addTemplateToggled : false,
        mailTemplatesLoading : true,
        trainings : [],
        trainingInstances : [],
        selectedTraining : '',
        handleBarVariables : null
    });

    React.useEffect(() => {
        const getTemplates = async () => {
            try {
                const response = await Axios.get(`http://localhost:8080/api/mail-templates/find-all/${appState.company}`);
                setState({
                    ...state,
                    mailTemplates : response.data,
                    mailTemplatesLoading : false
                });

            } catch (error) {
                console.log(error);
            }
        }

        getTemplates();
    }, []);

    const rendering = () => {
        return (
            <MailsContext.Provider value={{
                state,
                setState
            }}>
                <MailsOverviewSC>

                    <>
                        {state.addTemplateToggled && <AddMailTemplate /> }
                    </>

                    <TemplatesGrid />
                    <MailPreview />
                </MailsOverviewSC>
            </MailsContext.Provider>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailsOverviewSC = styled.div`
    display: flex;
    flex-grow : 1;
    min-height : 100%;
`

export default MailsOverview