import React from 'react';
import styled from 'styled-components';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { IndividualTouchPointContext } from '../IndividualTouchPoint';

const IndividualTouchPointTimingOptions = ({ }) => {

    const { timingMode, setTimingMode } = React.useContext(IndividualTouchPointContext);

    const rendering = () => {
        return (
            <IndividualTouchPointTimingOptionsSC>
                <p className='timing-mode-title-head'>Timing mode</p>
                <div className='timing-modes'>
                    <div className={`timing-mode-item ${timingMode === 'Trigger' && 'selected'}`} onClick={() => setTimingMode('Trigger')}>
                            <div className='checkbox'>
                                {timingMode === 'Trigger' ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
                            </div>
                            <div className='timing-mode-info'>
                                <p className='timing-mode-title'>Trigger</p>
                                <p className='timing-mode-description'>Send this email when a user performs a specific action</p>
                            </div>
                    </div>
                    <div className={`timing-mode-item ${timingMode === 'Scheduled' && 'selected'}`} onClick={() => setTimingMode('Scheduled')}>
                        <div className='checkbox'>
                            {timingMode === 'Scheduled' ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon /> }
                        </div>
                        <div className='timing-mode-info'>
                            <p className='timing-mode-title'>Scheduled</p>
                            <p className='timing-mode-description'>Scheduled email after x amount of days</p>
                        </div>
                    </div>
                </div>
            </IndividualTouchPointTimingOptionsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTouchPointTimingOptionsSC = styled.div`
    display : flex;
    flex-direction : column;
    min-height : 100%;
    padding : 25px;
    max-width : 35%;
    min-width : 35%;

    .timing-mode-title-head {
        font-size : 1.2rem;
        box-sizing : border-box;
        padding-bottom : 0;
    }

    .selected {
        background-color : #f5f5f5;
    }
    
    .timing-mode-item {
        display : flex;
        align-items : center;
        cursor : pointer;
        padding : 25px;
        margin-bottom : 20px;
        margin-top : 20px;
        border : 1px solid lightgrey;
        box-shadow : 0 2px 4px 0 rgba(0,0,0,0.1);
        border-radius : 5px;
    }

    .checkbox {
        margin-right : 20px;
    }

    .timing-mode-title {
        font-size : 1.2rem;
    }
`

export default IndividualTouchPointTimingOptions