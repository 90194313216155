import React from 'react';
import styled from 'styled-components';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { colors } from '../../variables/Colors';
import moment from 'moment';

const MailBox = ({ selectMailFunction, touchPoint, timeStamp, description}) => {

    const rendering = () => {
        return (
            <MailBoxSC onClick={() => selectMailFunction(touchPoint)}>
                <MailOutlineIcon />
                <div className='text-info'>
                    <p className='touchpoint-title'>{touchPoint.name}</p>
                    <p className='template-description'>{touchPoint.mailTemplate.subjectLine}</p>
                </div>
            </MailBoxSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailBoxSC = styled.div`
    background-color : white;
    border-radius : 5px;
    padding : 19px;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    max-width: 150px;
    min-width: 150px;
    min-height : 100%;
    max-height : 100%;
    margin-right : 20px;
    position : relative;

    &>svg {
        color : ${colors.darkGrey};
        font-size : 3.5em;
        margin-bottom : 15px;

    }

    .touchpoint-title {
        font-size : 1.2em;
        font-weight : 400;
        color : ${colors.darkGrey};
    }

    .template-title {
        font-size : 0.9em;
        font-weight : 400;
        color : ${colors.lightGrey};
    }

    .template-description {
        font-size : 0.9em;
        font-weight : 300;
        color : ${colors.lightGrey};
        display: -webkit-box;        /* Use a flexible box layout */
        -webkit-box-orient: vertical; /* Make the box behave vertically */
        -webkit-line-clamp: 2;        /* Limit to 2 lines */
        overflow: hidden;            /* Hide overflowed text */
        text-overflow: ellipsis;     /* Add ellipsis at the end */

    }
`

export default MailBox