import Avatar from '@mui/material/Avatar';
import React from 'react';
import styled from 'styled-components';
import moment from 'moment'; 
import EmailIcon from '@mui/icons-material/Email';
const RegisteredAttendee = ({ attendee }) => {

    const fullName = `${attendee.firstName} ${attendee.lastName}`;
    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
    }    

    function stringAvatar(name) {
        console.log('name : ', name);
        return {
          sx: {
            bgcolor: stringToColor(name),
          },
          children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }
      
    const rendering = () => {
        return (
            <RegisteredAttendeeSC>
                <div style={{ display : 'flex'}}>
                    <EmailIcon style={{ color : '#68a3de', marginRight : '10px', fontSize : '18px', paddingTop : '8px'}}/>
                    <div className='name-info text-block'>
                        <p className='full-name'>{fullName}</p>
                        <h4 className='e-mail'>{attendee.email}</h4>
                    </div>
                </div>
                <div className='company-info text-block'>
                    <p className='full-name'>{attendee.company}</p>
                    <h4 className='e-mail'>{attendee.role}</h4>
                </div>
                <div className='time-info text-block'>
                    <p className='full-name'>{moment(attendee.createdAt).format('DD/MM/YYYY')}</p>
                    <h4 className='e-mail'>{moment(attendee.createdAt).format('HH:mm')}</h4>
                </div>
                <div className='status'>
                    <p>confirmed</p>
                </div>
            </RegisteredAttendeeSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RegisteredAttendeeSC = styled.div`
    display : flex;
    border-bottom : 1px solid #e0e0e0;
    padding : 10px;

    &:last-child {
        border-bottom : none;
        padding-bottom : 0;
    }

    .name-info {
    }

    .text-block {
        display : flex;
        flex-direction : column;
        padding-left : 10px;

        .full-name {
            font-weight : 400;
        }

        .e-mail {

        }
    }

    .company-info {
        margin-left : 40px;
        min-width : 180px;
        max-width : 180px;
        display: -webkit-box;        /* Use a flexible box layout */
        -webkit-box-orient: vertical; /* Make the box behave vertically */
        -webkit-line-clamp: 2;        /* Limit to 2 lines */
        overflow: hidden;            /* Hide overflowed text */
        text-overflow: ellipsis;     /* Add ellipsis at the end */
    }

    .status {
        margin-left : auto;
        background-color : #A7FFD5;
        color : #297E05;
        align-items : center;
        display : flex;
        flex-direction : column;
        justify-content : center;
        border-radius : 10px;
        font-weight : 200;
        font-size : 12px;
        padding : 0;
        max-height : 25px;
        padding-left : 20px;
        padding-right : 20px;
    }

`

export default RegisteredAttendee