import React from 'react';
import styled from 'styled-components';
import InstanceRecord from './InstanceRecord';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';

const InstancesList = ({ onClickFunction, trainingId, preOpenTraing, selectInstancefunction}) => {

    const trainingIdValue = preOpenTraing ? preOpenTraing._id : trainingId;
    const [instances, setInstances] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {

        const fetchInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/training/${trainingIdValue}`)
                setInstances(response.data);
                setLoading(false);
            } catch (error) {
                console.log(error);
            }
        }

        fetchInstances();

    }, []);

    const rendering = () => {
        if(loading) return (<p>loading...</p>);
        if(instances.length === 0) return (
            <InstancesListSC>
                <div className='no-instances'>
                    <p>There are currently no workshops scheduled, schedule one</p>
                </div>
            </InstancesListSC>
        );

        return (
            <InstancesListSC>
                <div className='headers'>
                    <p>Title</p>
                    <div className='end'>
                        <p>Start date</p>
                        <p>Trainer</p>
                    </div>
                </div>

                {instances.map((instance, index) => {
                    return (
                        <InstanceRecord 
                            instance={instance} 
                            key={index} 
                            preOpenTraing={preOpenTraing}
                            onClickFunction={onClickFunction}
                        />
                    );
                })}

            </InstancesListSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstancesListSC = styled.div`
    flex : 1;
    overflow-y : scroll;

    &::-webkit-scrollbar {
        display: none;
    }

    .headers {
        display : flex;
        justify-content : space-between;
        padding : 13px;
        font-weight : bold;

        .end {
            display : flex;
            justify-content : space-between;
            width : 30%;
        }
    }

    .no-instances {
        padding : 15px;
        box-sizing : border-box;
    }
`

export default InstancesList