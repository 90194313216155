import React from 'react';
import styled from 'styled-components';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const Validate_Feedback = ({ state }) => {


    const rendering = () => {
        return (
            <Validate_FeedbackSC>
                <p className='header-title'>Survey</p>
                <p>{state.selectedSurvey ? state.selectedSurvey.surveyName : 'No survey!'}</p>
                <div 
                    className='thumbnailImage'
                    style={{ backgroundImage : 'url(https://www.eventbrite.co.uk/blog/wp-content/uploads/2022/06/Post-Event-Survey-scaled.jpg)'}}    
                >
                </div>
            </Validate_FeedbackSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const Validate_FeedbackSC = styled.div`
    padding : 20px;

    .header-title{
        font-size : 1.5rem;
        color : #000;
    }

    .thumbnailImage {
        width : 80px;
        height : 80px;
        background-size : cover;
        background-position : center;
        margin-top : 10px;
        border-radius : 10px;
    }
`

export default Validate_Feedback;