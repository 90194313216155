import React from 'react';
import styled from 'styled-components';
import SurveyResponsesOverview from './SurveyResonsesOverview';
import CertificatesShared from './CertificatesShared';

const SurveyAndCertificate = ({ individualSession }) => {

    const rendering = () => {
        return (
            <SurveyAndCertificateSC>
                <div className='title-container'>
                    <p className='title'>User interaction</p>
                </div>
                <SurveyResponsesOverview individualSession={individualSession}/>
                <CertificatesShared individualSession={individualSession}/>
            </SurveyAndCertificateSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyAndCertificateSC = styled.div`
    background-color : white;
    margin : 20px;
    border-radius : 5px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    padding : 20px;
    
    .sub-title-certs-and-surveys {
        font-weight : 300;
        color : #595959;
        font-size : 14px;
    }

    .title {
    }
`

export default SurveyAndCertificate