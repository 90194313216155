import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { SurveyContext } from './FillSurveyLink';

const SurveyButtons = () => {

    const { state, setState } = React.useContext(SurveyContext);

    const rendering = () => {
        return (
            <SurveyButtonsSC>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setState({...state, preSendToggled : true})}
                >
                    Submit
                </Button>
            </SurveyButtonsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SurveyButtonsSC = styled.div`
    margin-top : 20px;
`

export default SurveyButtons