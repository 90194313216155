import React from 'react';
import styled from 'styled-components';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import MainContent from './components/Maincontent/MainContent';
import { HashRouter as Router, Route, Routes, Link, NavLink, BrowserRouter } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import LoginScreen from './components/loginScreen/LoginScreen';
import { useAuth0 } from '@auth0/auth0-react';
import { backEndAdress } from './variables/back-endAdress';
import Axios from 'axios';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { CircularProgress } from '@mui/material';
import StudentPortal from './components/studentPortal/StudentPortal';

export const AppContext = React.createContext();

const App = () => {

    
    const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();

    Axios.interceptors.request.use(async (config) => {
        const excludedUrls = ['/public'];
        const isExcluded = excludedUrls.some((url) => config.url.includes(url));

        if (!isExcluded) {
            const token = await getAccessTokenSilently();
            config.headers.Authorization = `Bearer ${token}`;
        }
        
        return config;
    });
    
    const [appState, setAppState] = React.useState({
       sideBarExpanded : false,
       company : null,
       tenant : ''
    });

    React.useEffect(() => {

        // Check if the user exists in the db already. If not create a 'trainer'-role user for this tenant based on domain from email
        const loginFunction = async () => {
            const domain =  'Credon';

            try {
                const response = await Axios.post(`${backEndAdress}/api/users/login-function/${user.email}` , { tenant : domain });
                setAppState({
                    ...appState,
                    tenant : response.data.tenant,
                    role : response.data.role
                });

            } catch (error) {
                console.log('error');
            }
        }

        if(user) {
            loginFunction();
        } else {
            console.log('no auth')
        }

    }, [user])

    const userRole = 'admin'; 

    const rendering = () => {
        if(isLoading) return (
            <AppSC>
                <div className='loading-screen'>
                    <CircularProgress/>
                </div>
            </AppSC>
        )

        if(userRole !== 'student') {
            return (
                <AppContext.Provider value={{ 
                    appState, 
                    setAppState
                }}>
                    <AppSC>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <MainContent 
                                authenticated={isAuthenticated}
                                appState={appState}
                                setAppState={setAppState}
                            />
                        </LocalizationProvider>
                    </AppSC>
                </AppContext.Provider>
            );
        } else {
            return (
                <AppContext.Provider value={{ 
                    appState, 
                    setAppState
                }}>
                    <AppSC>
                        <StudentPortal />
                    </AppSC>
                </AppContext.Provider>
            );    
        }


    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AppSC = styled.div`
    .loading-screen {
        position : fixed;
        top : 0;
        left : 0;
        bottom : 0;
        right : 0;
        display : flex;
        justify-content : center;
        align-items : center;

    }
`

export default App