import React from 'react';
import styled from 'styled-components';
import SetVariables from './SetVariables';
import MailPreviewerDiv from './../MailPreviewerDiv';
import { MailsContext } from '../Mails';
import TestForInstanceOption from './TestForInstanceOption';

const MailPreview = () => {

    const { state , setState } = React.useContext(MailsContext);
    const { currentMailTemplate } = state;

    const rendering = () => {
        return (
            <MailPreviewSC>
                <TestForInstanceOption />
                {
                    currentMailTemplate && 
                        <MailPreviewerDiv 
                            template={currentMailTemplate}
                            handleBarVariables={state.handleBarVariables}    
                        />
                }
            </MailPreviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailPreviewSC = styled.div`
    display : flex;
    flex-grow : 1;
`

export default MailPreview

