import React from 'react';
import styled from 'styled-components';
import { v4 } from 'uuid';
import TemplatesGrid from './TemplatesGrid';
import MailPreview from '../mailPreview/MailPreview';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress'; 

const MailTemplatesOverview = () => {

    const rendering = () => {
        return (
            <MailTemplatesOverviewSC>
                <TemplatesGrid />
            </MailTemplatesOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MailTemplatesOverviewSC = styled.div`
    flex-grow : 1;
    display : flex;
`

export default MailTemplatesOverview