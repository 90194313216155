import React from 'react';
import styled from 'styled-components';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PaidIcon from '@mui/icons-material/Paid';

const OpenSpotRecord = ({ attendee }) => {

    const rendering = () => {
        return (
            <OpenSpotRecordSC>
                <div className='left'>
                    <div className='profile-pic' style ={{ backgroundImage : 'url(https://thumbs.dreamstime.com/b/default-avatar-profile-icon-vector-unknown-social-media-user-photo-default-avatar-profile-icon-vector-unknown-social-media-user-184816085.jpg)'}}></div>

                    <div className='text-info'>
                        <p>Open spot</p>
 
                    </div> 
                </div> 

                <div className='statusses'>
                    <div className='confirmation status-item'>
                        <AssignmentIcon style={{ color : false ? 'green' : 'grey' }}/>
                    </div>
                    <div className='payment status-item'>
                        <PaidIcon style={{ color : false ? 'green' : 'grey' }}/>
                    </div>

                </div>
            </OpenSpotRecordSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const OpenSpotRecordSC = styled.div`
    background-color : #fafafa;
    display : flex;
    justify-content : space-between;
    padding : 10px;
    border-bottom : 1px solid lightgrey;

    .left {
        display : flex; 
        padding : 10px;
        align-items : center;
    }

    .statusses {
        display : flex; 
        padding : 10px;
        align-items : center;
    }

    .left .profile-pic {
        width : 50px;
        height : 50px;
        border-radius : 50%;
        background-size : cover;
        background-position : center;
        margin-right : 10px;
    }

    .text-info {   
        display : flex;
        flex-direction : column;
        justify-content : center;
    }

    .status-item {
        min-height : 50px;
        min-width : 50px;
        border-radius : 100%;
        border : 1px solid lightgrey;
        background-color : white;
        display : flex;
        justify-content : center;
        align-items : center;
        color : white;

        &:first-child {
            margin-right : -10px;
        };

        &>svg {
            color : grey;
        }
    }
`

export default OpenSpotRecord



