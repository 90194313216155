import React from 'react';
import styled from 'styled-components';
import { backEndAdress } from '../../variables/back-endAdress';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';

const SessionsOverview = () => {

    const navigate = useNavigate(); 

    const [instances, setInstances] = React.useState([]);
    const [loading, setLoading] = React.useState(true);

    React.useLayoutEffect(() => {
        const getInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances`);
                console.log('response :', response);

                setInstances(response.data);
                setLoading(false);
            } catch (err) {
                console.log('error', err);
            }
        }

        getInstances();
    },[]);

    const rendering = () => {
        return (
            !loading && <SessionsOverviewSC>
                {instances.map((instance, index) => {
                    return (
                        <div className='session-tile' key={index} onClick={() => navigate(`/sessions/${instance._id}`)}>
                            <p>{instance.trainingName}</p>
                            <p>{instance.trainer.firstName}</p>
                            <p>{instance.location.title}</p>
                        </div>
                    )
                })}
            </SessionsOverviewSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SessionsOverviewSC = styled.div`
    .session-tile {
        border: 1px solid black;
        margin: 10px;
        padding: 10px;
    }
`

export default SessionsOverview