import React from 'react';
import styled from 'styled-components';
import { EmailScheduleContext } from '../EmailSchedule';

const TemplatesGrid = ({ setIndividualTouchPointState, individualTouchPointState, emailTemplates }) => {

    const setCurrentTemplate = (currentEmailTemplate) => {
        setIndividualTouchPointState({...individualTouchPointState, mailTemplate : currentEmailTemplate})
    }

    const rendering = () => {
        return (
            <TemplatesGridSC>
                {emailTemplates && emailTemplates.map((template, index) => {

                    const isSelected = individualTouchPointState.mailTemplate && individualTouchPointState.mailTemplate._id === template._id;

                    return (
                        <div 
                            style={{backgroundColor : isSelected ? 'orange' : 'inherit'}} 
                            className='template-tile' 
                            key={index} 
                            onClick={() => setCurrentTemplate(template)}
                        >
                            <h1>{template.title}</h1>
                            <p>{template.description}</p>
                        </div>
                    );
                })}
            </TemplatesGridSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TemplatesGridSC = styled.div`
    max-width : 40%;

    background-color : white;
    padding : 20px;
    display : grid; 
    grid-template-columns : repeat(2, 1fr);
    gap : 10px;
    height : 100%;
    overflow-y : hidden;

    .template-tile {
        padding : 20px;
        border : 1px solid #e0e0e0;
        border-radius : 5px;
        background-color : white;

        h1 {
            font-size : 1.2rem;
            font-weight : 600;
        }
    }
`

export default TemplatesGrid