import React from 'react';
import styled from 'styled-components';
import EventBox from './EventBox';

const CancelFlowBar = () => {

    const rendering = () => {
        return (
            <CancelFlowBarSC className='mail-flow-row'>
                <EventBox 
                    index={3}
                    label={'Cancel'}
                    description={'Training gets cancelled by company'}
                />
            </CancelFlowBarSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CancelFlowBarSC = styled.div`
    max-height : 100%;
    overflow : hidden;
`

export default CancelFlowBar