import React from 'react';
import styled from 'styled-components';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const Difficulty = ({ trainingToEditLocal , setTrainingToEditLocal }) => {
    
    const [level, setLevel] = React.useState(trainingToEditLocal.customProperties.difficulty);

    const handleChange = (event) => {
        setLevel(event.target.value);
    };

    React.useEffect(() => {
        if(trainingToEditLocal) {
            setTrainingToEditLocal({
                ...trainingToEditLocal,
                customProperties : {
                    ...trainingToEditLocal.customProperties,
                    difficulty : level
                }
            });
        }
    }, [level]);



    const rendering = () => {
        return (
            trainingToEditLocal && 
                <DifficultySC>
                    <FormControl
                        sx={{
                            width : '100%',
                        }}
                        fullWidth>
                        <InputLabel 
                            id="demo-simple-select-label"
                            sx={{
                                backgroundColor : 'white',
                                paddingRight : '5px',

                            }}    
                        >
                            Difficulty
                        </InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={level}
                            label="Age"
                            onChange={handleChange}
                            sx={{ fontFamily : 'inherit', fontSize : 'inherit', color : 'black'}}
                        >
                        <MenuItem 
                            value={'basic'}
                            sx={{
                                padding : '10px'
                            }}    
                        >Basic</MenuItem>
                        <MenuItem 
                            value={'intermediate'}
                            sx={{
                                padding : '10px'
                            }}    
                        >Intermediate</MenuItem>
                        <MenuItem 
                            value={'advanced'}
                            sx={{
                                padding : '10px'
                            }}    
                        >Advanced</MenuItem>
                        </Select>
                    </FormControl>
                </DifficultySC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DifficultySC = styled.div`
`

export default Difficulty