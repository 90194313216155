import React from 'react';
import styled from 'styled-components';
import RegisteredAttendee from './RegisteredAttendee';

const RegisteredUsers = ({ individualSession }) => {

    const openSlots = individualSession.capacity - individualSession.attendees.length;
    console.log('open slots', openSlots);

    const rendering = () => {
        return (
            <RegisteredUsersSC>
                <p className='title'>Registered Users</p>
               
                <div className='registered-users-container'>
                    {individualSession.attendees.map((attendee, index) => {
                        return (
                            <RegisteredAttendee 
                                attendee={attendee} 
                                key={index}
                            />
                        );
                    })}

                    {Array.from({ length: openSlots }).map((_, index) => (
                        <div className='open-slot' key={index}>
                            <img src='https://cdn3.iconfinder.com/data/icons/account-1/64/Account-06-512.png' alt='avatar' style={{ borderRadius : '50%', width : '30px', height : '30px', marginLeft : '10px'}}/>
                            <p>Open slot</p>
                        </div>
                    ))}
                </div>
            </RegisteredUsersSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RegisteredUsersSC = styled.div`
    background-color : white;
    border-radius : 10px;
    flex-grow : 1;
    padding : 15px;
    margin-right : 20px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);

    .title {
        padding-bottom : 15px;
    }

    .registered-users-container {
        display : flex;
        flex-direction : column;
        border-radius : 10px;
    }

    .open-slot {
        display : flex;
        align-items : center;
        height : 50px;
        border-radius : 5px;
        background-color : #fafafa;
        margin-top : 10px;
        color : grey;
        font-weight : 300;

        img {
            border-radius : 50%;
            width : 30px;
            height : 30px;
            margin-right : 10px;
        }
    }
`

export default RegisteredUsers