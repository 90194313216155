import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'; 
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { Button, CircularProgress } from '@mui/material';
import Modal from '../../../components/GeneralComponents/Modal';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import OutsideClickHandler from 'react-outside-click-handler';
import ClassOutlinedIcon from '@mui/icons-material/ClassOutlined';

const Header = ({ individualSession }) => {

    const navigate = useNavigate();

    const [toggleOptions, setToggleOptions] = React.useState(false);    
    const [loading, setLoading] = React.useState(false);
    const [sessionRemoved, setSessionRemoved] = React.useState(false);

    const removeSession = () => {

        const deleteInstance = async () => {
            
            setLoading(true);
            const response = await Axios.delete(`${backEndAdress}/api/instances/${individualSession._id}`);
            setLoading(false);
            setSessionRemoved(true);
        }

        deleteInstance();

    }


    const rendering = () => {
        return (
            <HeaderSC>
                {loading && 
                    <Modal>
                        <div className='removing-session'>
                            <p>Removing session</p>
                            <CircularProgress />
                        </div>
                    </Modal>
                }

                {sessionRemoved && 
                    <Modal>
                        <div className='removing-session'>
                            <p>Succesfully removed session</p>
                            <Button
                                onClick={() => navigate('/')}
                                variant='outlined'

                            >Close</Button>
                        </div>
                    </Modal>
                }

                <div>
                    <p className='instance-title'>{individualSession.trainingName}</p>
                    <div className='dates'>
                        {individualSession.dates.map((date, index) => {
                            return (
                                <div className='date-tile'>
                                    <CalendarMonthIcon />
                                    <p key={index}>{moment(date).format('DD/MM/YYYY')}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className='options' onClick={() => setToggleOptions(!toggleOptions)}>
                    <p>Options</p>
                    {toggleOptions && 
                        <OutsideClickHandler onOutsideClick={() => setToggleOptions(false)}>
                            <div className='options-dropdown'>
                                <div className='option-item' onClick={() => removeSession()}>
                                    <DeleteOutlineOutlinedIcon />
                                    <p>Remove session</p>
                                </div>
                                <div className='option-item' onClick={() => navigate(`/trainings/${individualSession.trainingId}`)}>
                                    <ClassOutlinedIcon />
                                    <p>Open training</p>
                                </div>
                            </div>
                        </OutsideClickHandler>
                    }
                </div>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    background-color : white;
    padding : 15px;
    border-radius : 5px;
    justify-content : space-between;
    display : flex; 
    margin-bottom : 20px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    margin-right : 20px;

    .instance-title {
        font-size : 20px;
        font-weight : 350;
    }

    .dates {
        display : flex; 
        padding-top : 5px;
        font-weight : 200;

        .date-tile {
            display : flex;
            align-items : center;
            margin-right : 10px;
            font-size : 14px;

            p {
                margin-left : 5px;

            }

            &>svg {
                color : #8a8a8a;
                font-size : 14px;

            }
        }
    }

    .options {
        display : flex; 
        align-items : center;
        position : relative;



        p {
            margin-right : 10px;
            font-size : 16px;
            cursor : pointer;
            font-weight : 300;
        }
    }

    .options-dropdown {
        position : absolute;
        background-color : white;
        top : 64px;
        right : -15px;
        min-width : 250px;
        border-radius : 0px;
        border : 1px solid lightgrey;
        padding : 10px;
        border-top : 0;

        .option-item {
            display : flex;
            align-items : center;
            padding : 10px;
            cursor : pointer;
            font-size : 14px;

            &:hover {
                cursor : pointer;
                background-color : #f9f9f9;
            }
            p {
                margin-left : 10px;
            }
        }
    }
    
    .removing-session {
        background-color : white;
        display : flex; 
        align-items : center;
        justify-content : center;
        padding : 40px;
        flex-direction : column;
        min-width : 300px;

        p {
            font-size : 16px;
            margin-bottom : 20px;
        }
    }
`

export default Header