import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal'; 
import IndividualTouchPointHeader from './IndividualTouchPointHeader';
import AvailableTemplates from './AvailableTemplates';
import { defaultMergeTags } from '../../../variables/defaultMergeTags';
import Preview from '../../emailSchedule/Content/Preview';
import TimingOptions from './TimingOptions';
import { set, useForm } from 'react-hook-form';
import { MailScheduleBothLocationsContext } from '../MailScheduleBothLocations';
import { v4 } from 'uuid';
import moment from 'moment';
import { Button } from '@mui/material';

const IndividualTouchPoint = ({ individualTouchPoint, touchPointsLocal, setTouchPointsLocal }) => {

    const { locationType, setIndividualTouchPoint } = React.useContext(MailScheduleBothLocationsContext);

    const [template , setTemplate] = React.useState(null);
    const [beforeOrAfter, setBeforeOrAfter] = React.useState('before');
    const [time, setTime] = React.useState(null);
    const [numberOfDays, setNumberOfDays] = React.useState(0);
    const [loading, setLoading] = React.useState(true);

    const form = useForm();
    const { register, handleSubmit, watch, trigger, control, formState, setValue  } = form;

    const touchPointFormRef = React.useRef(null);

    const onSubmit = (data) => {
        const location = locationType === 'our-location' ? 'ourLocation' : 'customerLocation';
        const trigger = individualTouchPoint.selectedTrigger ? individualTouchPoint.selectedTrigger : null;
        const subject = document.getElementById('value-of-subject').textContent;

        if(trigger) {
            const touchPointsWithoutCurrentTrigger = touchPointsLocal[location].filter(touchPoint => touchPoint.selectedTrigger !== individualTouchPoint.selectedTrigger);
            const newTouchPoint = {
                ...individualTouchPoint,
                mailTemplate : template,
                timingMode : 'Trigger',
                subject
            }
            const updatedTouchPoints = [...touchPointsWithoutCurrentTrigger, newTouchPoint];
            console.log('updatedTouchPoints', updatedTouchPoints)
            setTouchPointsLocal({
                ...touchPointsLocal,
                [location] : updatedTouchPoints
            })            

        } else {

            const newTouchPoint = {
                touchPointId : individualTouchPoint.touchPointId ? individualTouchPoint.touchPointId : v4(),
                name : `${numberOfDays} day${numberOfDays > 1 ? 's' : ''} ${beforeOrAfter}`,
                timingMode : 'Scheduled',
                selectedTrigger : null,
                time,
                numberOfDays,
                beforeOrAfter,
                subject,
                sendAt : {
                    hour : moment(time, 'HH:mm').hour(),
                    minute : moment(time, 'HH:mm').minute(),
                },
                mailTemplate : template
            }

            const idsExistingTouchPoints = touchPointsLocal[location].map(touchPoint => touchPoint.touchPointId);
            const isTouchPointNew = !idsExistingTouchPoints.includes(individualTouchPoint.touchPointId);
    
            const newArray = isTouchPointNew ? [...touchPointsLocal[location], newTouchPoint] : touchPointsLocal[location].map(touchPointItem => {
                if(touchPointItem.touchPointId === individualTouchPoint.touchPointId){
                    return newTouchPoint;
                }else{
                    return touchPointItem;
                }
            });

            setTouchPointsLocal({
                ...touchPointsLocal,
                [location] : newArray
            })           


        }

        setIndividualTouchPoint(null);

    }

    const removeTouchPoint = (template) => {
        const location = locationType === 'our-location' ? 'ourLocation' : 'customerLocation';
        const touchPointsWithoutCurrentTrigger = touchPointsLocal[location].filter(touchPoint => touchPoint.touchPointId !== individualTouchPoint.touchPointId);
        setTouchPointsLocal({
            ...touchPointsLocal,
            [location] : touchPointsWithoutCurrentTrigger
        })
        setIndividualTouchPoint(null);
    }
    
    React.useLayoutEffect(() => {
        individualTouchPoint.mailTemplate && setTemplate(individualTouchPoint.mailTemplate);
        individualTouchPoint.numberOfDays && setNumberOfDays(individualTouchPoint.numberOfDays);
        individualTouchPoint.beforeOrAfter && setBeforeOrAfter(individualTouchPoint.beforeOrAfter);
        individualTouchPoint.time && setTime(moment(individualTouchPoint.time))
        setLoading(false);
    },[individualTouchPoint])

    const rendering = () => {
        return (
            !loading && 
            <Modal>
                <IndividualTouchPointSC>
                    <IndividualTouchPointHeader 
                        individualTouchPoint={individualTouchPoint}
                        formRef={touchPointFormRef}
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                    />
                    <div className='content-container'>
                        <div className='left-panel'>
                            {individualTouchPoint.timingMode === 'Scheduled' && 
                                <TimingOptions 
                                    setBeforeOrAfter={setBeforeOrAfter}
                                    beforeOrAfter={beforeOrAfter}
                                    time={time}
                                    setTime={setTime}
                                    numberOfDays={numberOfDays}
                                    setNumberOfDays={setNumberOfDays}
                                />
                            }
                                <AvailableTemplates 
                                    setTemplate={setTemplate}
                                    template={template}    
                                />
                        </div>
                        <div className='preview-container'>
                            <Preview 
                                mailTemplate={template}
                                handleBarsObject={defaultMergeTags}
                            />
                        </div>
                    </div>
                </IndividualTouchPointSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const IndividualTouchPointSC = styled.div`
    background-color : white; 
    min-width : 95vw;
    min-height : 95vh;
    max-width : 95vw;
    max-height : 95vh;
    border-radius : 5px;
    box-shadow  : 0 0 10px rgba(0,0,0,0.1);
    background-color : #f9f9f9;
    border-radius : 10px;
    overflow : hidden;
    display : flex; 
    flex-direction : column;

    form {
        display : flex;
        flex-direction : column;
        flex-grow : 1;
    }
    
    .content-container {
        display : flex; 
        flex-grow : 1;
        overflow:  hidden;

        .left-panel {
            display : flex;
            flex-direction : column;
            overflow-y : scroll;
            min-width : 400px;
            max-width : 400px;
        }

        .preview-container {
            flex-grow : 1;
            display : flex;
            border : 1px solid lightgrey;
            border-radius : 5px;
            margin : 15px;
            background-color : white;

            &>div {
                flex-grow : 1;
                display : flex; 
                flex-direction : column; 
            
                >.loading-template {
                    min-height : 100%;
                }

                >.mail-preview {
                    padding-bottom : 35px;
                    max-height: 200px;
                    background-color : white;
                    color : grey;

                    &>.subject-of-template {
                        visibility : visible;
                    }


                    
                }
            }

        }
    }
    
`

export default IndividualTouchPoint