import React from 'react';
import styled from 'styled-components';
import Header from './Header/Header';
import Sidebar from './sidebar/SideBar';
import MainContent from './mainContent/MainContent';

const StudentPortal = () => {

    const rendering = () => {
        return (
            <StudentPortalSC>
                <Header />
                <MainContent />
            </StudentPortalSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const StudentPortalSC = styled.div`
    display : flex; 
    flex-direction : column;
    background-color : #F5F5F5;
    min-height : 100vh;
    min-width : 100vw;



`

export default StudentPortal