import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import BasicInfo from './basicInfo/BasicInfo';
// import TrainingMailSchedule from './TrainingMailSchedule';
// import TrainingFeedBackForm from './feedbackForm/TrainingFeedbackForm';
// import TrainingCertificates from '../certificates/TrainingCertificates';
// import Review from './review/Review';
import { AddOrEditTrainingContext } from '../AddOrEditTraining';
import TrainingMailSchedule from './TrainingMailSchedule';
import { defaultTouchPointsArray } from '../../addTraining/defaultTouchPointsArray';
import TrainingFeedBackForm from '../mainContent/feedbackForm/TrainingFeedbackForm';
import TrainingCertificates from '../mainContent/certificates/TrainingCertificates';
import Review from '../mainContent/review/Review';
import TrainingProperties from './trainingProperties/TrainingProperties';
import TextForWebsite from './textForWebsite/TextForWebsite';
import MailScheduleBothLocations from '../../mailScheduleBothLocations/MailScheduleBothLocations';

const MainContent = ({ 
    register, 
    errors,
    surveys,
    mailTemplates,
    state,
    setState,
    formValues,
    trigger,
    imageURL,
    setImageURL,
    file,
    setFile,
    activeItem,
    trainingToEdit,
    trainingToEditLocal,
    setTrainingToEditLocal,
    certificateTemplates,
    locations,
    editor,
    generalInfoRef,
    propertiesRef,
    websiteRef,
    mailScheduleRef,
    feedbackRef,
    certificateRef,
    reviewRef,
    setValue
}) => {

    const sectionRefs = useRef([]);
    const [activeSection, setActiveSection] = useState(null);

    const rendering = () => {
        return (
            <MainContentSC>
                <BasicInfo 
                    visible={activeItem === 0}
                    register={register} 
                    errors={errors}
                    trigger={trigger}    
                    imageURL={imageURL}
                    setImageURL={setImageURL}
                    file={file}
                    setFile={setFile}
                    trainingToEdit={trainingToEdit}
                    generalInfoRef={generalInfoRef}
                />
 
                <TrainingProperties
                    visible={true}
                    locations={locations}
                    state={state}
                    setState={setState}
                    register={register} 
                    errors={errors}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                    propertiesRef={propertiesRef}
                    setValue={setValue}
                />

                <TextForWebsite
                    state={state}
                    setState={setState}
                    register={register} 
                    errors={errors}
                    trainingToEditLocal={trainingToEditLocal}
                    editor={editor}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                    websiteRef={websiteRef}
                />

                <MailScheduleBothLocations
                    training={trainingToEditLocal}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                    mailScheduleRef={mailScheduleRef}
                />

               {/* <TrainingMailSchedule
                    register={register} 
                    errors={errors}
                    state={state}
                    setState={setState}
                    mailTemplates={mailTemplates}
                    defaultTouchPoints={defaultTouchPointsArray}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                    mailScheduleRef={mailScheduleRef}
                /> */}

                <TrainingFeedBackForm
                    visible={true}
                    surveys={surveys}
                    state={state}
                    setState={setState}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                    feedbackRef={feedbackRef}
                />

                <TrainingCertificates
                    visible={true}
                    state={state}
                    setState={setState}
                    trainingToEditLocal={trainingToEditLocal}
                    setTrainingToEditLocal={setTrainingToEditLocal}
                    certificateTemplates={certificateTemplates}
                    certificateRef={certificateRef}
                />
                   
                <Review
                    state={state}
                    setState={setState}
                    formValues={formValues}
                    trigger={trigger}
                    errors={errors}
                    activeItem={activeItem}
                    imageURL={imageURL}
                    trainingToEditLocal={trainingToEditLocal}
                    reviewRef={reviewRef}

                />
                </MainContentSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const MainContentSC = styled.div`
    display : flex;
    flex-direction : column;
    flex-grow : 1;
    max-height : 100%;
    overflow-y : scroll;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    padding : 10px;
    box-sizing : border-box;
    background-color : #fafafa;

    &::-webkit-scrollbar {
        display: none;  /* Chrome, Safari, and Edge */
    }

    .content-section-title {
        font-size : 1.3rem;
        font-weight : 400;
        font-family : inherit;
        padding-bottom : 10px;
    }

`

export default MainContent