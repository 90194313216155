import React from 'react';
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Delete } from '@mui/icons-material';
import DeleteTemplatePopUp from './DeleteTemplatePopUp';
import { backEndAdress } from '../../../variables/back-endAdress';
import Axios from 'axios' ; 
import { AppContext } from '../../../App';

const TemplatesGrid = ({
    mailTemplates,
    searchQuery,
    setMailTemplateForPreview,
    mailTemplate
}) => {

    const [templates, setTemplates] = React.useState([]);
    const [deletePopUp, setDeletePopUp] = React.useState(false); 

    const { appState } = React.useContext(AppContext);

    React.useEffect(() => {

        let templatesTemp = [];
        
        const allTemplatesWithFolder = mailTemplates.length>0 ? mailTemplates.filter((template) => template.folder) :[] ;
        const generalTemplates = mailTemplates.length>0 ? mailTemplates.filter((template) => !template.folder).map((template) => ({...template, folder : 'general'})) : [] ; 
        templatesTemp = [...allTemplatesWithFolder, ...generalTemplates];

        setTemplates(templatesTemp);

    },[mailTemplates])

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const deleteTemplateFunction = () => {
        const deleteTemplate = async () => {
            try {
                const response = await Axios.delete(`${backEndAdress}/api/mail-templates/${appState.tenant}/${mailTemplate._id}`);

                let templatesTemp = response.data ; 
                const allTemplatesWithFolder = templatesTemp.filter((template) => template.folder);
                const generalTemplates = templatesTemp.filter((template) => !template.folder).map((template) => ({...template, folder : 'general'})); 
                templatesTemp = [...allTemplatesWithFolder, ...generalTemplates];      
                setTemplates(templatesTemp);
                setDeletePopUp(false);

            } catch (error) {
                console.log(error);
            }
        }

        deleteTemplate();
    }

    const rendering = () => {
        
        if(mailTemplates) {
       
            const uniqueFolders = [...new Set(templates.map((template) => template.folder))];

            return (
                <TemplatesGridSC>

                    {deletePopUp && 
                        <DeleteTemplatePopUp 
                            setDeletePopUp={setDeletePopUp} 
                            deleteTemplateFunction={deleteTemplateFunction}
                            handleClose={() => setDeletePopUp(false)}    
                    />}

                    {uniqueFolders.map((folder) =>{

                        const folderTemplates = templates.filter((template) => template.folder === folder).filter((template) => template.title.toLowerCase().includes(searchQuery.toLowerCase()));

                        return (
                            <>
                            <div key={folder}>
                                <p className='folder-title'>{capitalizeFirstLetter(folder)}</p>
                                {folderTemplates.map((template) => {

                                    const isCurrentlySelected = mailTemplate ? mailTemplate._id === template._id ? true : false : false;

                                    return (
                                        <div 
                                            key={template.id} 
                                            className={`template-name ${isCurrentlySelected && 'selected'}`} 
                                            onClick={() => setMailTemplateForPreview(template)}
                                        >
                                            <p>{capitalizeFirstLetter(template.title)}</p>
                                            {
                                            isCurrentlySelected && 
                                                <div className='action-icons'>
                                                    <Delete 
                                                        className='delete-icon'
                                                        onClick={() => setDeletePopUp(true)}    
                                                    />
                                                    <CheckBoxIcon style={{marginLeft : '15px'}}/>
                                                </div>
                                            }
                                            </div>
                                    );
                                })}
                                

                            </div>
                            </>
                        );
                    })}

                </TemplatesGridSC>
            );
        }

    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TemplatesGridSC = styled.div`
    flex-grow : 1;
    overflow-y : scroll;
    
    .folder-title {
        font-size : 1.3rem;
        padding : 10px;
        padding-left : 20px;
        font-weight : 400;
    }

    .template-name {
        padding-bottom : 0;
        color : grey;
        font-weight : 300;
        display : flex;
        padding : 10px;
        padding-left : 40px;
        align-items : center;
        justify-content : space-between;

        &:hover {
            cursor : pointer;
            font-weight : 400;
        }

        &:hover .action-icons .delete-icon {
            display : block;
        }

        .action-icons {
            display : flex;
            align-items : center;
            justify-content : center;
            padding-right : 10px;
        
            .delete-icon {
                display : none;
                color : lightgrey;

                &:hover {
                    color : grey;
                    cursor : pointer;
                }

            }


        }
    }
    .selected {
        background-color : #F9F9F9;
    }

`

export default TemplatesGrid