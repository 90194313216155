import React from 'react';
import styled from 'styled-components';

const ActionItem = ({ children }) => {

    const rendering = () => {
        return (
            <ActionItemSC>
                {children}
            </ActionItemSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ActionItemSC = styled.div`
    display : flex;
    align-items : center;

    .action-title {
        font-size : 20px;
        font-weight : 350;
    }

    .action-description {
        display : flex; 
        font-weight : 200;

        .date-tile {
            display : flex;
            align-items : center;
            margin-right : 10px;
            font-size : 14px;

            p {
                margin-left : 5px;

            }

            &>svg {
                color : #8a8a8a;
                font-size : 14px;
                margin-right : 15px;
            }
        }
    }
`

export default ActionItem