import React from 'react';
import styled from 'styled-components';
import ActionItem from './ActionItem';
import GradingIcon from '@mui/icons-material/Grading';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import SignupLinkModal from './SignupLinkModal';
import { IndividualSessionContext } from '../../IndividualSession';

const Actions = ({ individualSession }) => {

    const { toggleSurveyModal } = React.useContext(IndividualSessionContext);

    const [signupLinkModal, setSignupLinkModal] = React.useState(false);

    const rendering = () => {
        return (
            <ActionsSC>

                {signupLinkModal && <SignupLinkModal 
                    individualSession={individualSession}
                    setSignupLinkModal={setSignupLinkModal}    
                />}
                <div className='title-container'>
                    <p className='title'>Actions</p>    
                </div>

                <div className='action-item' onClick={() => toggleSurveyModal(true)}>
                    <GradingIcon />
                    <div className='action-info' style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='action-title'>Send out survey</div>
                        <div className='action-description'>Send out survey to attendees</div>
                    </div>
                </div>
                <div className='action-item' onClick={() => setSignupLinkModal(true)}>
                    <LinkIcon />
                    <div className='action-info' style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='action-title'>Sign-up link</div>
                        <div className='action-description'>Get the link where attendees can sign up for this instance</div>
                    </div>
                </div>
                <div className='action-item' style={{ borderBottom : 0}}>
                    <EmailIcon />
                    <div className='action-info' style={{display: 'flex', flexDirection: 'column'}}>
                        <div className='action-title'>Mail all users</div>
                        <div className='action-description'>Send out an e-mail to all users that signed up</div>
                    </div>
                </div>
            </ActionsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ActionsSC = styled.div`
    background-color : white;
    border-radius : 5px;
    margin-left : 20px;
    margin-right : 20px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);

    .action-item {
        display : flex; 
        align-items : center;
        border-bottom : 1px solid lightgrey;
        padding : 15px;

        &:hover {
            background-color : #F5F5F5;
            cursor : pointer;
        }
    }

    .action-title {
        font-size : 16px;
        font-weight : 400;
        
    }

    .action-description {
        display : flex; 
        font-weight : 300;
        color : #595959;
        font-size : 14px;

    }

    svg {
        color : #737373;
        margin-right : 15px;
    }
`

export default Actions