import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import { Button } from '@mui/material';
import { TrainingsOverViewContext } from '../TrainingsOverview/TrainingsOverview';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';

const OnCompleteAddOrEditTraining = ({ trainingCreated, openSavedTraining, state, setState }) => {

    const backToOverview = () => {    
        const getTrainings = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings`);
                setState({
                    ...state, 
                    trainings : response.data, 
                    addTrainingToggled : false
                });
            } catch (error) {
                console.error(error);
            }
        }

        getTrainings()
    }

    const openTraining = () => {
        setState({
            ...state, 
            addTrainingToggled : false
        })
        openSavedTraining(state.savedTrainingResponse)
    }

    const rendering = () => {    
        return (
            <Modal>
                <OnCompleteAddOrEditTrainingSC>
                    <p className='title-of-div'>{`Created training : ${trainingCreated}`}</p>
                    <div className='buttons-div'>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => openTraining()}

                        >
                            Open training
                        </Button>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => backToOverview()}
                        >
                            Back to overview
                        </Button>
                    </div>
                </OnCompleteAddOrEditTrainingSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const OnCompleteAddOrEditTrainingSC = styled.div`
    min-height : 250px;
    min-width : 500px;
    display : flex;
    flex-direction : column;
    justify-content : center;
    align-items : center;
    background-color : white;
    border-radius : 10px;

    .title-of-div {
        font-size : 1.5rem;
        margin-bottom : 20px;
    }

    .buttons-div {
        display : flex;
        gap : 10px;
    }
`

export default OnCompleteAddOrEditTraining