import React from 'react';
import styled from 'styled-components';
import TrainingTile from './TrainingTile';

const TrainingTiles = ({ trainings }) => {

    console.log('training',trainings);

    const rendering = () => {
        return (
            <TrainingTilesSC>
                {trainings.map((training, index) => <TrainingTile key={index} training={training} />)}
            </TrainingTilesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingTilesSC = styled.div`
    display : grid;
    grid-template-columns : repeat(5, minmax(200px, 25%));
    gap : 10px;
`

export default TrainingTiles