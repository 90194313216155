import { Button } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { UserManagementContext } from './UserManagement';

const UserManagmentFooter = () => {

    const { setIndividualUser } = React.useContext(UserManagementContext);

    const user = {
        avatar : '', 
        userEmail : '', 
        role : 'Trainer', 
    }

    const rendering = () => {
        return (
            <UserManagmentFooterSC>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setIndividualUser(user)}
                >
                    Add User
                </Button>
            </UserManagmentFooterSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UserManagmentFooterSC = styled.div`
    position : absolute; 
    bottom : 15px; 
    right : 15px;
`

export default UserManagmentFooter