import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { AddTrainingContext } from './AddTraining2';
import { Spinner } from 'react-bootstrap';

const ReviewTraining = ({ addTraining, visible }) => {

    const { state, setState } = React.useContext(AddTrainingContext);

    const rendering = () => {
        return (
            <ReviewTrainingSC style={{ display : visible === 'visible' ? 'flex' : 'none'}}>
                {state.createTrainingLoading ?
                    <div className='loading-spinner-div'>
                        <p>Creating training...</p>
                        <CircularProgress />
                     </div>
                    :
                    <>
                    <p className='sub-content-title'>Review  training</p>
                    <Button 
                        variant='contained'
                        color='primary'
                        style={{ marginRight : '15px' }}
                        onClick={() => addTraining()}
                    >
                            Save
                    </Button>
                    </>    
                } 

            </ReviewTrainingSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ReviewTrainingSC = styled.div`
    flex-grow : 1;
    display : flex;
    flex-direction : column;
    padding : 10px;

    .sub-content-title {
        font-size : 1rem;
        padding : 10px;
    }

    .loading-spinner-div {
        padding-left : 200px;
        display : flex;
        flex-direction : column;
        justify-content : center;
        align-items : center;
        margin-top : 20px;
        background-color : white;

        p {
            margin-bottom : 10px;
        }
    }
`

export default ReviewTraining