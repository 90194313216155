import React from 'react';
import styled from 'styled-components';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import Search from '../../GeneralComponents/Search';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const AvailableTemplates = ({ setTemplate, template }) => {

    const [availableTemplates, setAvailableTemplates] = React.useState(null);
    const [query, setQuery] = React.useState('');

    React.useEffect(() => {
        const getTemplates = async () => {
            try {   
                const response = await Axios.get(`${backEndAdress}/api/mail-templates`);
                setAvailableTemplates(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        getTemplates();
    }, [])

    const rendering = () => {
        const uniqueFolders = availableTemplates ? [...new Set(availableTemplates.map(template => template.folder))] : [];
        const filteredTemplates = availableTemplates && availableTemplates.filter(template => template.title.toLowerCase().includes(query.toLowerCase()));
        return (
            <AvailableTemplatesSC>
                <div className='container-title'>
                    <FolderOpenIcon />
                    <p>Templates</p>
                </div>
                <Search 
                    query={query}
                    setQuery={setQuery}
                />

                {uniqueFolders && availableTemplates && uniqueFolders.map(folder => {
                    return (
                        <div className='folder-item'>
                            <div className='folder-item-title'>
                                <ExpandMoreIcon />
                                <FolderOpenIcon style={{fontSize :'17px', marginRight :'5px'}}/>
                                <p>{folder.charAt(0).toUpperCase() + folder.slice(1)}</p>
                            </div>
                            <div className='templates'>
                                {filteredTemplates && filteredTemplates.map(tempItem => {
                                    if(tempItem.folder === folder) {

                                        const isSelected = template ? template._id === tempItem._id : false;

                                        return (
                                            <div 
                                                key={tempItem.id}
                                                className='template-item'   
                                                onClick={() => setTemplate(tempItem)} 
                                            >
                                                <MailOutlineIcon />
                                                <p style={{ 
                                                    color : isSelected ? 'black' : 'grey'    
                                                }}>{tempItem.title}</p>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                    )
                }

            )}
            </AvailableTemplatesSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AvailableTemplatesSC = styled.div`
    background-color : white; 
    margin : 15px;
    margin-left : 25px;
    padding : 25px;
    border-radius : 5px;
    min-width : 300px;
    max-width : 300px;
    border : 1px solid #C9C9C9;

    .container-title {
        display : flex; 
        align-items : center;
        margin-bottom : 10px;

        &>svg {
            margin-right : 10px;
            color : grey;
            font-size : 30px;
        }

        &>p {
            font-size : 1.2rem;
            font-weight : 350;
        }
    }

    .folder-item-title {
        display : flex; 
        align-items : center;   

        p {
            font-size : 1.2rem;
            font-weight : 350;

        }
    }

    .templates {
        margin-top : 10px;
        margin-left : 25px;
    }

    .template-item {
        display : flex; 
        align-items : center;
        padding : 2px;
        font-weight : 300;

        
        &>svg {
            font-size : 20px;
            margin-right : 10px;
            color : #2D2D2D;
        }

        p {
            font-size : 1rem;
            cursor : pointer;
            font-weight : 300;
            display: -webkit-box;        /* Use a flexible box layout */
            -webkit-box-orient: vertical; /* Make the box behave vertically */
            -webkit-line-clamp: 1;        /* Limit to 2 lines */
            overflow: hidden;            /* Hide overflowed text */
            text-overflow: ellipsis;     /* Add ellipsis at the end */
        }
    }

    .folder-item {
        margin-top : 10px;
        margin-bottom : 20px;
    }

    .search-bar {
        display : flex;
        align-items : center;
        margin-bottom : 15px;

        p {
            font-size : 1.1rem;
            margin-right : 10px;
        }

        input {
            width : 100%;
            padding : 5px;
            border : 1px solid lightgrey;
            border-radius : 5px;
        }
    }
`

export default AvailableTemplates