import React from 'react';
import styled from 'styled-components';

const Certificate = ({ individualTraining }) => {

    const hasCertificate = individualTraining?.certificateTemplateId; 

    const rendering = () => {
        
        return (
            <CertificateSC>
                <p className='title'>Certificate</p>
                <div className='certificate'>
                {hasCertificate ? 
                    <>
                        <img src={individualTraining.certificateTemplateId}/>
                    </>
                    :
                    <>
                        <img src='https://static.vecteezy.com/system/resources/previews/009/745/291/non_2x/certificate-flat-greyscale-free-vector.jpg'/>
                    </>
                }
                </div>
                <p className='cert-subtitle'>No certificate available</p>

            </CertificateSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const CertificateSC = styled.div`
    background-color : white;
    display : flex;
    flex-direction : column;
    border-radius : 2px;
    margin : 20px;
    margin-left : 0;
    margin-top : 0;
    box-shadow : 0 0 10px 0 rgba(0,0,0,0.1);
    min-width : 200px;
    min-height : 200px;
    max-width : 200px;
    max-height : 200px;

    .certificate {
        display : flex;
        flex : 1;
        justify-content : center;
        align-items : center;
        flex-direction : column;
    }

    .cert-subtitle {
        padding : 10px;
        margin : 0;
        font-size : 10px;
        font-weight : 300;
    }

    img {
        max-height : 50%;
        max-width : 50%;
        object-fit : contain;
    }
    
    .title {
        padding : 10px;
    }
`

export default Certificate