import React from 'react';
import styled from 'styled-components';

const TemplatesInFolder = ({ templates, selectedFolder }) => {

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const rendering = () => {

        const selectedFolderTemplates = templates.filter(template => template.folder === selectedFolder);

        return (
            <TemplatesInFolderSC>
                <p className='templates-title'>Templates</p>
                <div className='templates'>
                    {selectedFolderTemplates.map(template => {
                        return (
                            <div key={template._id} className='template-name'>
                                {capitalizeFirstLetter(template.title)}
                            </div>
                        )
                    })}
                </div>
            </TemplatesInFolderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TemplatesInFolderSC = styled.div`
    display : flex; 
    flex-direction : column;

    .templates-title {
        padding : 30px;
        border-bottom : 1px solid lightgrey;
    }

    .template-name {
        padding : 10px;

    }
`

export default TemplatesInFolder