import React from 'react';
import styled from 'styled-components';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { colors } from '../../variables/Colors';

const AddMailButton = ({ addTouchPoint }) => {

    const rendering = () => {
        return (
            <AddMailButtonSC
                onClick={() => addTouchPoint()}
            >
                <AddCircleOutlineIcon />
                <p>Add touchpoint</p>

            </AddMailButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddMailButtonSC = styled.div`
    background-color : white;
    border-radius : 5px;
    padding : 10px;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.2); */
    max-width: 150px;
    min-width: 150px;
    min-height : 100%;
    max-height : 100%;
    margin-right : 20px;
    justify-content : center;
    align-items : center;

    &>svg {
        color : ${colors.lightGrey};
        font-size : 4em;
    }

    &:hover {
        cursor : pointer;
        background-color : #f1f1f1;
    }

    &>p {
        margin : 0;
        font-size : 14px;
        color : ${colors.lightGrey};
    }	
`

export default AddMailButton