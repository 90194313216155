import React from 'react';
import styled from 'styled-components';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const TrainingPropertyNonEdit = ({ property }) => {

    const rendering = () => {
        return (
            <TrainingPropertyNonEditSC>
                <div className='icon-holder'>
                    {property.icon}
                </div>
                <div className='text-information'>
                    <p>{property.name}</p>
                    <p style={{fontSize : '12px'}}>{property.value}</p>
                </div>
            </TrainingPropertyNonEditSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingPropertyNonEditSC = styled.div`
    display : flex;

    .icon-holder {
        min-height : 50px;
        min-width : 50px;
        max-height : 50px;
        max-width : 50px;
        border : 1px solid #e0e0e0;
        border-radius : 100%;
        display : flex;
        justify-content : center;
        align-items : center;
        background-color : grey;
    }
    
    .text-information {
        display: flex; 
        flex-direction : column;
        padding-left : 15px;
        justify-content : center;
        overflow : hidden;
        flex-grow : 1;
        max-height : 50px;
    }
`

export default TrainingPropertyNonEdit