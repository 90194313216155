import React from 'react';
import styled from 'styled-components';
import AddIcon from '@mui/icons-material/Add';

const SidebarFolders = ({ templates, selectedFolder, setSelectedFolder, folders, setAddFolderToggled }) => {


    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const rendering = () => {
        return (
            <SidebarFoldersSC>
                <div className='title-and-add-option'>
                    <p className='title'>Folders</p>
                    <AddIcon onClick={() => setAddFolderToggled(true)} />
                </div>
                <div className='folders-list'>
                    {folders.map(folder => {

                        const isSelected = folder === selectedFolder;

                        return (
                            <div 
                                key={folder} 
                                className={`folder-item ${isSelected && 'selected'}`}
                                onClick={() => setSelectedFolder(folder)}    
                            >
                                {capitalizeFirstLetter(folder)}
                            </div>
                        )
                    })}
                </div>
            </SidebarFoldersSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SidebarFoldersSC = styled.div`

    min-width : 200px;
    background-color : white;
    border-right : 1px solid #f2f2f2;

    .title-and-add-option {
        display : flex;
        justify-content : space-between;
        align-items : center;
        padding : 28px;
        border-bottom : 1px solid lightgrey;
        box-sizing : border-box;
    }

    .folders-list {
        display : flex;
        flex-direction : column;

        .folder-item {
            padding : 15px;

            cursor : pointer;
            &:hover {
                background-color : #f2f2f2;
            }
        }

        .selected { 
            background-color : #f2f2f2;
        }
    }
`

export default SidebarFolders