import React from 'react';
import styled from 'styled-components';
import RegistrationItem from './RegistrationItem';
import Search from '../../GeneralComponents/Search';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';

const RecentRegistrations = () => {

    const [registrations, setRegistrations] = React.useState([]);
    const [query, setQuery] = React.useState('');

    React.useEffect(() => {
        const getRegistrations = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/get-all-attendees-list`)
                setRegistrations(response.data);
            } catch (error) {
                console.error(error);
            }
        }

        getRegistrations();
    }, []);


    const filteredRegistrations = registrations ? registrations.filter(registration => `${registration.firstName} ${registration}`.toLowerCase().includes(query.toLowerCase())) : [];	


    const rendering = () => {
        return (
            <RecentRegistrationsSC>
                <h1 className='title'>Recent registrations</h1>
                <Search 
                    placeholder='Search registrations...'
                    style={{ marginBottom : '20px' }}
                    query={query}
                    setQuery={setQuery}
                />
                <div className='registrations-list'>
                    {filteredRegistrations.map(registration => {
                        return (
                            <RegistrationItem registration={registration} />
                        );
                    })}
                </div>
            </RecentRegistrationsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const RecentRegistrationsSC = styled.div`
    background-color : white;
    border-radius : 10px;
    padding : 20px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    flex-grow : 1;
    margin-left : 20px;
    min-height : 500px;
    max-height : 700px;
`

export default RecentRegistrations