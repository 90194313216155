import React from 'react';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';
import AreYouSure from '../../GeneralComponents/AreYouSure';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress';
import { IndividualSessionContext } from '../IndividualSession';
import ChangeLocationPopUp from '../../IndividualTraining/TrainingProperties/InstanceLocation/ChangeLocationPopUp';
import ChangeTrainerPopUp from '../../IndividualTraining/TrainingProperties/InstanceTrainer/ChangeTrainerPopUp';
import { set } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const TrainingInfo = ({ individualSession }) => {

    const [functionOjbect, setFunctionObject] = React.useState(null);
    const [pickNewTrainer, setPickNewTrainer] = React.useState(false);
    const [pickNewLocation, setPickNewLocation] = React.useState(false);

    const { setIndividualSession } = React.useContext(IndividualSessionContext);

    const updateTrainer = (selectedTrainer) => {
            
        const updateInstance = async () => {

            const newInstance =  {
                ...individualSession,
                trainer : selectedTrainer
            }


            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/update-instance/${individualSession._id}`, newInstance);
                setPickNewTrainer(false);
                setFunctionObject(null);
                setIndividualSession(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        updateInstance();

    }

    const updateLocation = (selectedLocation) => {
            
        const updateInstance = async () => {

            const newInstance =  {
                ...individualSession,
                location : selectedLocation
            }

            try {
                const response = await Axios.post(`${backEndAdress}/api/instances/update-instance/${individualSession._id}`, newInstance);
                setPickNewLocation(false);
                setFunctionObject(null);
                setIndividualSession(response.data);
            } catch (error) {
                console.log(error);
            }
        }

        updateInstance();

    }        

    const rendering = () => {
        return (
            <TrainingInfoSC className='dasboard-container'>

                { functionOjbect && <AreYouSure 
                    title={functionOjbect.title}
                    textOption1={functionOjbect.textOption1}
                    textOption2={functionOjbect.textOption2}
                    function1={functionOjbect.function1}
                    function2={functionOjbect.function2}
                />}

                {pickNewTrainer && <ChangeTrainerPopUp  
                    closeFunction={() => setPickNewTrainer(false)}    
                    clickFunction={updateTrainer}
                />}

                {pickNewLocation && <ChangeLocationPopUp
                    closeFunction={() => setPickNewLocation(false)}
                    clickFunction={updateLocation}
                />}
                        

                <div className='title-container'>
                    <p className='title'>Workshop info</p>
                </div>

                <div className='avatar-container'>
                    <div className='left'>
                        <img src={individualSession.trainer.imageUrl} alt='avatar' />
                        <div>
                            <h1 className='name'>Trainer</h1>
                            <h3 className='role'>{`${individualSession.trainer.firstName} ${individualSession.trainer.lastName}`}</h3>
                        </div>
                    </div>
                    <EditIcon 
                        onClick={() => setFunctionObject({
                            title : 'Do you want to change the trainer for this session?',
                            textOption1 : 'Yes', 
                            textOption2 : 'No', 
                            function1 : () => setPickNewTrainer(true),
                            function2 : () => setFunctionObject(null)
                        })}
                    />
                </div>
                <div className='avatar-container' style={{borderBottom : 0}}>
                    <div className='left'>
                        <img src={individualSession.location.thumbnailImageUrl} alt='avatar' />
                        <div>
                            <h1 className='name'>{individualSession.location.title}</h1>
                            <h3 className='role'>Credon Tessenderlo</h3>
                        </div>
                    </div>
                    <EditIcon
                        onClick={() => setFunctionObject({
                            title : 'Do you want to change the location for this session?',
                            textOption1 : 'Yes', 
                            textOption2 : 'No', 
                            function1 : () => setPickNewLocation(true),
                            function2 : () => setFunctionObject(null)
                        })}
                    />
                </div>
            </TrainingInfoSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingInfoSC = styled.div`
    background-color : white; 
    border-radius : 5px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);

    .avatar-container {
        display : flex; 
        justify-content : space-between;
        align-items : center;
        border-bottom : 1px solid lightgrey;
        padding : 10px;
        .left {
            display : flex;
            align-items : center;
            padding : 10px;

            &>img {
                width : 50px;
                height : 50px;
                border-radius : 50%;
            }

            &>div {
                margin-left : 10px;

            }
        }

        &>svg {
            color : lightgrey;
            border : 2px solid lightgrey;
            border-radius : 5px;
            padding : 5px;
            font-size : 15px;
            margin-right : 10px;
            margin-left : 10px;

            &:hover {
                cursor : pointer;
                color : grey;
            }
        }

    }



`

export default TrainingInfo