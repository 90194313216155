import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { IndividualTrainingContext } from './IndividualTraining';

const EditTrainingButton = () => {

    const { state, setState, training } = React.useContext(IndividualTrainingContext);


    const rendering = () => {
        return (
            <EditTrainingButtonSC>
                <button 
                    className='button' 
                    onClick={() => setState({...state, trainingToEdit : training})}
                >
                    Edit training
                </button>
            </EditTrainingButtonSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const EditTrainingButtonSC = styled.div`
    position : relative; 
    padding : 10px;
    padding-left : 0;



`


export default EditTrainingButton