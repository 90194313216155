import React from 'react';
import styled from 'styled-components';

const Header = () => {

    const rendering = () => {
        return (
            <HeaderSC>
                <p>this the header</p>
            </HeaderSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const HeaderSC = styled.div`
    min-height : 50px; 
    background-color : white;
`

export default Header