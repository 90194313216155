import React from 'react';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';

const Search = ({ placeholder, style, query, setQuery }) => {

    const onChange = (e) => {   
        setQuery(e.target.value);
    }

    const rendering = () => {
        return (
            <SearchSC style={{...style}}>
                <SearchIcon />
                <input 
                    type='text' 
                    placeholder={placeholder}
                    value={query}
                    onChange={onChange}
                />
            </SearchSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const SearchSC = styled.div`
    display : flex;
    padding : 8px;
    border : 1px solid #EBEBEB;
    border-radius : 5px;
    background-color : #F9F9F9;

    &>svg {
        width : 25px;
        height : 25px;
        color : #9B9B9B;
    }

    input {
        min-width : 1px;
        flex-grow : 1;
        outline : none;
        border : 0;
        font-size : inherit;
        font-family : inherit;
        background-color : inherit;
        padding-left : 10px;
        font-weight : 300;
    }
`

export default Search