import React from 'react';
import styled from 'styled-components';
import MailSchedule from '../MailScheduleComponent/MailSchedule';

const TrainingMailSchedule = ({ visible, props, state, setState, emailTemplates, defaultTouchPoints}) => {

    const rendering = () => {
        return (
            <TrainingMailScheduleSC style={{ display : visible === 'visible' ? 'flex' : 'none'}}>
                <MailSchedule 
                    state={state}
                    setState={setState}
                    emailTemplates={emailTemplates}
                    defaultTouchPoints={defaultTouchPoints.touchPoints}
                />
            </TrainingMailScheduleSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TrainingMailScheduleSC = styled.div`
    flex-grow :1;
    overflow-x : scroll;

`

export default TrainingMailSchedule