import React from 'react';
import styled from 'styled-components';
import PanoramaIcon from '@mui/icons-material/Panorama';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const ImageUpload = ({ imageURL, setImageURL, file, setFile, id, description}) => {

    const handleImageUpload = (e) => {
        setFile(e.target.files[0]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            console.log('reader result : ', reader.result);
            setImageURL(reader.result);
        }
    }

    const rendering = () => {
        return (
            <ImageUploadSC
                style={{
                    border: !imageURL && '2px #828282',
                    borderStyle: !imageURL && 'dashed'
                }}
            >
                {imageURL && 
                    <label 
                        htmlFor={id}
                        className="custom-file-upload"
                    >
                        <div className='picture-container' 
                            style={{backgroundImage : `url(${imageURL})`
                        }}>
                            <div className='overlay'>
                                <p className='overlay-subtext'>{description}</p>
                                <p className='button-for-upload'>
                                    UPLOAD
                                </p>
                                <IconButton
                                    onClick={() => {
                                        setImageURL(null);
                                        setFile(null);
                                    }}
                                    style={{marginTop : '10px'}}
                                >
                                    <DeleteForeverIcon />
                                </IconButton>
                            </div>
                        </div>
                    </label>
                }

                <label 
                    htmlFor={id}
                    className="custom-file-upload"
                >
                    <div className='custom-upload-box'>
                        <PanoramaIcon />
                        <p className='description-of-image'>{description}</p>
                        <p className='button-for-upload'>
                            UPLOAD
                        </p>
                    </div>
                </label>

                <input 
                    id={id} 
                    type="file" 
                    style={{display: 'none'}}
                    accept='.png, .jpg, .jpeg'
                    onChange={(e) => handleImageUpload(e)}    
                />
            </ImageUploadSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const ImageUploadSC = styled.div`
    min-height : 100%;
    max-height : 100%;
    min-width : 100%;
    max-width : 100%;
    background-color : #FFFDFD;
    border-radius : 10px;
    display : flex;
    flex-direction : column;
    align-items : center;
    position : relative; 
    overflow: hidden;
    
    .picture-container {
        position : absolute; 
        top : 0;
        left : 0;
        right : 0;
        bottom : 0;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;  /* Scales the image to fit the div without cropping */
        background-color : white;
        
        &>.overlay {
            opacity : 0;
            position : absolute;
            top : 0;
            left : 0;
            right : 0;
            bottom : 0;
            background-color : rgba(255, 255, 255, 0.8);
            display : flex; 
            flex-direction : column;
            justify-content : center;
            align-items : center;

            .overlay-subtext {
                font-size : 12px;
                font-weight : 300;
                max-width : 50%;
                text-align : center;
            }

            .button-for-upload {
                background-color : #2076DB;
                color : white;
                border : none;
                padding : 15px;
                text-align : center;
                cursor : pointer;
                border-radius : 5px;
                width : 80%;
                margin-top : 10px;
                font-size : 15px;
                font-weight : 200;
            }

            &:hover {
                cursor : pointer;
                opacity : 1;
                transition: opacity 200ms ease-in-out
            }
        }
    }



    .custom-file-upload {

        flex-grow : 1;
        display : flex;
        flex-direction : column;
    }

    label {
        min-width : 100%;
    }
    .custom-upload-box {
        flex-grow : 1;
        display : flex;
        flex-direction : column;
        color : #8E8E8E;
        align-items : center;
        justify-content : center;
        min-width : 100%;

        &:hover {
            cursor : pointer;
        }

        &>svg {
            font-size : 50px;
        }

        .description-of-image {
            font-size : 12px;
            font-weight : 300;
            max-width : 50%;
            text-align : center;
        }

        .button-for-upload {
            background-color : #2076DB;
            color : white;
            border : none;
            padding : 15px;
            text-align : center;
            cursor : pointer;
            border-radius : 5px;
            width : 80%;
            margin-top : 10px;
            font-size : 15px;
            font-weight : 200;
        }
    }
`

export default ImageUpload