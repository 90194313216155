import React from 'react';
import styled from 'styled-components';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';

const AddImage = ({ handleImageUpload, file , setFile, imageURL, setImageURL }) => {

    // const handleImageUpload = (e) => {
    //     const fileItem = e.target.files[0];

    //     setFile(fileItem);
    //     const reader = new FileReader();
    //     reader.readAsDataURL(fileItem);
    //     reader.onloadend = () => {
    //         setImageURL(reader.result);
    //     }
    // }

    const rendering = () => {
        const image = imageURL ? imageURL : 'https://previews.123rf.com/images/sodis/sodis1804/sodis180400047/100084305-business-training-flat-design-vector-illustration.jpg';
        
        return (
            <AddImageSC>
                <div className='picture-container' style={{backgroundImage : `url(${image})`}}>
                </div>

                <label 
                    htmlFor="file-upload"
                    className="custom-file-upload"
                >
                    <p className='custom-button-file-upload'>Upload image</p>
                </label>

                <input 
                    id="file-upload" 
                    type="file" 
                    style={{display: 'none'}}
                    accept='.png, .jpg, .jpeg'
                    onChange={(e) => handleImageUpload(e)}    
                />
            </AddImageSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddImageSC = styled.div`
    margin-top : 20px;
    min-width : 350px;
    max-width : 350px;
    display : flex;
    flex-direction : column;
    align-items : center;

    .picture-container {
        background-color : lightgrey;
        min-height : 220px;
        min-width : 300px;
        max-width : 300px;
        background-size : cover;
        background-position : center;
        border-radius : 15px;
    }

    .custom-button-file-upload {
        background-color : #1976D2;
        color : white;
        padding : 15px;
        min-width : 300px;
        margin-top : 30px;
        text-align : center;
        cursor : pointer;
        border-radius : 15px;
    }
`

export default AddImage