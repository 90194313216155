import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../../variables/back-endAdress'; 
import UpcomingTrainingTile from './UpcomingTrainingTile';
import Search from '../../GeneralComponents/Search';

const UpcomingTrainings = () => {

    const [instances, setInstances] = React.useState([]);
    const [query, setQuery] = React.useState('');

    React.useEffect(() => {
        const getInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/`)
                const filteredInstances = response.data.filter(instance => new Date(instance.startDate) > new Date());
                setInstances(filteredInstances);
            } catch (error) {
                console.error(error);
            }
        }

        getInstances();

    }, []);

    const filteredInstances = instances.filter(instance => instance.trainingName.toLowerCase().includes(query.toLowerCase()));

    const rendering = () => {

        return (
            <UpcomingTrainingsSC>
                <h1 className='title'>Upcoming trainings</h1>
                <Search
                    placeholder='Search registrations...'
                    style={{ marginBottom : '20px' }}
                    query={query}
                    setQuery={setQuery}
                />
                <div className='trainings-list'>
                {filteredInstances.map(instance => {
                    return (
                        <UpcomingTrainingTile instance={instance}/>
                    );
                })}
                </div>
            </UpcomingTrainingsSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const UpcomingTrainingsSC = styled.div`
    background-color : white;
    border-radius : 10px;
    padding : 20px;
    box-shadow : 0 0 10px rgba(0,0,0,0.1);
    min-width: 350px;
    max-width : 350px;
    min-height : 700px;
    max-height : 500px;
    display : flex;
    flex-direction : column;
    overflow-y: hidden;


    .trainings-list {
        display : flex;
        flex-direction : column;
        overflow-y : scroll;

        &::-webkit-scrollbar {
            display: none;
        }

    }

`

export default UpcomingTrainings