import React from 'react';
import styled from 'styled-components';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import DropdownSelect from '../../../GeneralComponents/InputFields/DropDownSelect';
import { IndividualTouchPointContext } from '../IndividualTouchPoint';
import TextInputField from '../../../GeneralComponents/InputFields/TextInputField';

const TimingModeScheduled = ({ }) => {

    const { beforeOrAfter, setBeforeOrAfter, numberOfDays , setNumberOfDays, time, setTime, mailScheduleState } = React.useContext(IndividualTouchPointContext);
    const { individualTouchPoint } = mailScheduleState;

    const beforeOrAfterOptions = {
        object : [{
            name : 'before'
        },{
            name : 'after'
        }],
        key : 'name'
    }

    const rendering = () => {

        return (
            <TimingModeScheduledSC>
                <p className='page-title'>Scheduled mail</p>
                <div className='inputs-row'>
                    <TextInputField
                        label='Number of days'
                        typedValue={numberOfDays}
                        setValueFunction={setNumberOfDays}
                    />
                    <DropdownSelect 
                        options={beforeOrAfterOptions}
                        setValueFunction={setBeforeOrAfter}
                        selectedValue={beforeOrAfter}
                        className='dropdown-select'
                        style={{ marginLeft : '10px', minWidth : '240px' }}
                    />
                </div>
                <TimePicker
                    onChange={(time) => setTime(time)}
                    value={time}
                    className='time-picker-custom'

                />
            </TimingModeScheduledSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const TimingModeScheduledSC = styled.div`
    flex-grow : 1;

    .page-title {
        font-size : 1.2rem;
        box-sizing : border-box;
        padding-bottom : 0;
    }

    .inputs-row {
        margin-top : 20px;
        display : grid;
        grid-template-columns : 1fr 1fr;
    }

    select {
        width : 100%;
        padding : 10px;
        border : 1px solid #e0e0e0;
        border-radius : 5px;

        &:focus {
            outline : none;
        }
    }

    .time-picker-custom {
        margin-top : 10px;
    }
`

export default TimingModeScheduled