import React from 'react';
import styled from 'styled-components';
import Axios from 'axios'; 
import { backEndAdress } from '../../variables/back-endAdress';
import { FormControl } from 'react-bootstrap';
import { InputLabel, MenuItem, Select } from '@mui/material';

const InstanceSelector = () => {

    const [training, setTraining] = React.useState('');
    const [allTrainings, setAllTrainings] = React.useState([]);
    const [instance, setInstance] = React.useState('');
    const [allInstances, setAllInstances] = React.useState([]);

    const selectTraining = (event) => {
        const trainingName = event.target.value;
        const training = allTrainings.find((training) => training.name === trainingName);
        setTraining(training);
    }

    React.useEffect(() => {

        const getTrainings = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/trainings`);
                setAllTrainings(response.data)
    
            } catch (error) {
                console.log(error);
            }
        }
    
        getTrainings();
        
    }, []);
    
    React.useEffect(() => {
        const getInstances = async () => {
            try {
                const response = await Axios.get(`${backEndAdress}/api/instances/training/${training._id}`);
                setAllInstances(response.data)
    
            } catch (error) {
                console.log(error);
            }
        }
    
        if(training) {
            getInstances();
        }
    
    
    }, [training]);

    const rendering = () => {
        return (
            <InstanceSelectorSC>

                <FormControl fullWidth style={{marginBottom : '15px'}}>
                    <InputLabel id="demo-simple-select-label">Training</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={training.name ? training.name : ''}
                        label="Training"
                        onChange={selectTraining}
                    >
                        {allTrainings.map((training) => {
                            return (
                                <MenuItem 
                                    value={training.name}
                                    key={training._id}    
                                >{training.name}</MenuItem>
                            );
                        })}

                    </Select>
                </FormControl>

            </InstanceSelectorSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const InstanceSelectorSC = styled.div`
    
`

export default InstanceSelector



