import React from 'react';
import styled from 'styled-components';
import { colors } from '../../variables/Colors';

const EventBox = ({ index, label, description }) => {

    const rendering = () => {
        return (
            <EventBoxSC>
                <p className='index'>{index}</p>
                <p className='label'>{label}</p>
                <p className='description'>{description}</p>
            </EventBoxSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const EventBoxSC = styled.div`
    background-color : white;
    border-radius : 5px;
    padding : 10px;
    box-sizing : border-box;
    display : flex;
    flex-direction : column;
    box-shadow: 0 0 10px rgba(0,0,0,0.2);
    max-width: 150px;
    min-width: 150px;
    min-height : 100%;
    max-height : 100%;
    margin-right : 20px;

    .index {
        font-size : 4em;
        font-weight : 400;
        padding-left : 20px;
        color : ${colors.darkGrey};
    }

    .label {
        font-size : 1.2em;
        font-weight : 400;
        padding-left : 20px;
        color : ${colors.darkGrey};
    }

    .description {
        font-size : 0.9em;
        font-weight : 400;
        padding-left : 20px;
        color : ${colors.lightGrey};
    }

`

export default EventBox