import React from 'react';
import styled from 'styled-components';
import Switch from '@mui/material/Switch';

const OnDemand = ({ setTrainingToEditLocal, trainingToEditLocal }) => {

    const handleChange = (event) => {
        setTrainingToEditLocal({
            ...trainingToEditLocal,
            customProperties : {
                ...trainingToEditLocal.customProperties,
                onDemand : event.target.checked
            }
        });
    }

    const rendering = () => {
        return (
            <OnDemandSC>
                <p>On demand</p>
                <Switch onChange={(e) => handleChange(e)} size="small" defaultChecked={trainingToEditLocal.customProperties.onDemand} />
            </OnDemandSC>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const OnDemandSC = styled.div`

`

export default OnDemand