import React from 'react';
import styled from 'styled-components';
import Modal from '../GeneralComponents/Modal';
import BasicInput from '../GeneralComponents/BasicInput';
import BasicTextArea from '../GeneralComponents/BasicTextArea';
import AddTemplatePicture from './AddTemplatePicture';
import AddTemplateHeader from './AddTemplateHeader';
import Axios from 'axios';
import { backEndAdress } from '../../variables/back-endAdress';
import { AppContext } from '../../App';

const AddTemplateModal = ({ certificateTextRef, certificateNameRef, state, setState }) => {

    const { appState } = React.useContext(AppContext);
    const { tenant } = appState;

    const [file, setFile] = React.useState(null);
    const [imageURL, setImageURL] = React.useState(null);

    const addTemplate = () => {

        setState({
            ...state,
            loading : true
        });

        const newTemplate = {
            certificateName : certificateNameRef.current.value,
            certificateText : certificateTextRef.current.value,
            tenant
        }

        const formData = new FormData();
        formData.append('templateImage', file);
        formData.append('templateInfo', JSON.stringify(newTemplate));

        const saveTemplateFunction = async () => {
            console.log('running');
            try {
                const response = await Axios.post(`${backEndAdress}/api/certificate-template/${tenant}`, formData , { headers : { 'Content-Type' : 'multipart/form-data' } });
                setState({
                    ...state,
                    individualTemplate : null,
                    loading : false,
                    templates : response.data,
                    tenant : tenant
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        saveTemplateFunction();

    }

    const rendering = () => {
        return (
            <Modal>
                <AddTemplateModalSC>
                    <AddTemplateHeader 
                        addTemplate={addTemplate}
                        state={state}
                        setState={setState}    
                    />
                    <div className="form-group">
                        <div className='left-panel'>
                            <BasicInput
                                label='Certificate name'
                                type='text'
                                reference={certificateNameRef}
                            />
                            <BasicTextArea
                                label='Text on certificate'
                                type='text'
                                reference={certificateTextRef}
                            />
                        </div>
                        <div className='right-panel'>
                            <AddTemplatePicture
                                file={file}
                                setFile={setFile}
                                imageURL={imageURL}
                                setImageURL={setImageURL}
                            />
                        </div>
                    </div>
                </AddTemplateModalSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const AddTemplateModalSC = styled.div`
    min-width : 500px;
    min-height : 300px;
    background-color : white;
    border-radius : 10px;
    display : flex;
    flex-direction : column;
    padding : 20px;


    .form-group {
        display : flex;
        flex-direction : row;
        width : 100%;
        flex-grow : 1;

    }


    .left-panel {
        padding-top : 25px;
        display : flex;
        flex-direction : column;
        width : 50%;
        max-width : 50%;

        &>div {
            margin : 10px;
        }
    }

    .right-panel {
        display : flex;
        flex-direction : column;
        width : 50%;
        max-width : 50%;
        justify-content : center;
        box-sizing : border-box;
    }
`

export default AddTemplateModal