import React from 'react';
import styled from 'styled-components';
import Modal from '../../GeneralComponents/Modal';
import { Button } from '@mui/material';
import { UserManagementContext } from '../UserManagement';
import Axios from 'axios';
import { backEndAdress } from '../../../variables/back-endAdress';

const DeleteUserPopUp = () => {

    const { setDeleteUserPopup, userToDelete, setUsers } = React.useContext(UserManagementContext);

    const deleteUser = async ()  => {
        try {
            const response = await Axios.delete(`${backEndAdress}/api/users/${userToDelete}`); 
            setDeleteUserPopup(false);
            setUsers(response.data); 
        } catch (error) {
            console.log(error);
        }
    }

    const rendering = () => {
        return (
            <Modal>
                <DeleteUserPopUpSC>
                    <h1>Are you sure you want to delete this user?</h1>
                    <div>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={deleteUser}
                        >
                            Delete
                        </Button>
                        <Button
                            variant='outlined'
                            color='primary'
                            onClick={() => setDeleteUserPopup(false)}
                        >
                            Cancel
                        </Button>
                    </div>
                </DeleteUserPopUpSC>
            </Modal>
        );
    }

    return(
        <React.Fragment>
            {rendering()}
        </React.Fragment>
    );
}

const DeleteUserPopUpSC = styled.div`
    background-color : white;
    padding : 25px;
    border-radius : 10px;
    box-shadow : 0 0 10px rgba(0, 0, 0, 0.1);
`

export default DeleteUserPopUp